import React from 'react';
import _ from 'lodash';

import {
  Row,
  Col,
  Badge,
  Form,
  InputGroup,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Divider } from 'antd';

// Auth
// import { useAuthContext } from '../../../../auth/use-auth-context.js';
import { useRouter } from '../../../../auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector, Tables } from '../../../../utils/store.tsx';
import slice from './slice.tsx';
import manageJobTypesSlice, { fields, JobTemplateType } from '../../../manage/jobs/jobTypes/slice.tsx';

// enums
import { escapeChars, escapeCharsInStringOnPaste, isNumeric, getIds } from '../../../../utils/utils.tsx';
import { UserCookieSettingType } from '../../../../utils/enums.tsx';

import MaterialDataTable from '../../../../components/table/withSlice/MaterialDataTable.jsx';
import DataTableVisibility from '../../../../components/table/withSlice/DataTableVisibility.tsx';
import createGenericSlice, { BackOptions } from '../../../../components/table/withSlice/tableSlice.tsx';
import DefaultPopup from '../../../../components/popover/DefaultPopup.tsx';
import NoResults from '../../../../components/noResults/NoResults.tsx';

import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings.svg";

// ----------------------------------------------------------------------

const table = createGenericSlice<any>(Tables.JobTypes);


const ListComponent = React.forwardRef((props: any, ref: any) => {
  const router = useRouter();

  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  const { tableIsLoading, tableIsFirstLoad, tableData, tableTotal, searchQuery, isIncludeInactive, isInactive, sortColumn, sortDir, columnVisibility, columnSizing, columnOrder, tableStatus, isSavedCookie, isLoadingSaveCookie } = useTypedSelector((state: RootState) => state.tableJobTypes);
  const { show, id } = useTypedSelector((state: RootState) => state.jobFormJobTypeHistorySlice);
  const dispatch = useDispatch<AppDispatch>();
  
  const pageName = 'job-form-job-type-history-1-v5';
  const settingsType = UserCookieSettingType.Manage;
  const sliceName = Tables.JobTypes;
  const method = 'get';
  const rowId = 'jobTemplateId';
  const listPath = 'JobTemplate';
  const sortColumnInit = 'jobTemplateType';
  const sortDirInit = 'asc';
  const searchQueryInit = null;
  const tableStatusInit = 2;
  const isIncludeInactiveInit = false;
  const isInactiveInit = null;
  const customSortParams = {};
  const initialParams = {
    currentPage: 1,
    pageSize: 10,
    sortColumn: sortColumnInit,
    sortDir: sortDirInit,
    searchQuery: searchQueryInit,
    isIncludeInactive: isIncludeInactiveInit,
    isInactive: isInactiveInit,
  };

  const refTableActions = React.useRef<any>(null);
  const [dataTable, setDataTable] = React.useState<any>(null);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const columnsRender = [
    {
      id: 'isActive',
      Cell: ({ renderedCellValue, row }: any) => {
        return (row  && row.original && row.original.isActive) ? 'Active' : 'Inactive';
      },
      PDF: (row: any) => {
        return (row  && row.isActive) ? 'Active' : 'Inactive';
      }
    },

    {
      id: 'jobTemplateType',
      Cell: ({ renderedCellValue, row }: any) => {
        let item = (row && row.original && isNumeric(row.original.jobTemplateType)) ? row.original.jobTemplateType : null;
        
        return <div>
          {item === JobTemplateType.Default && <div>Default</div>}
          {item === JobTemplateType.Custom && <div>Custom</div>}
        </div>
      },
      PDF: (row: any) => {
        let item = (row && isNumeric(row.jobTemplateType)) ? row.jobTemplateType : null;
        return item === JobTemplateType.Custom ? "Custom" : "Default";
      }
    },
  ];
  const columns = [
    {
      id: 'jobTemplateName',
      accessorKey: 'jobTemplateName',
      columnDefType: 'data',
      header: fields.jobTemplateName.label,
      enableHiding: false,
      size: 300,
    },
    {
      id: 'jobTemplateDescription',
      accessorKey: 'jobTemplateDescription',
      columnDefType: 'data',
      header: fields.jobTemplateDescription.label,
      size: 600,
    },
    {
      id: 'isActive',
      accessorKey: 'isActive',
      columnDefType: 'data',
      header: fields.isActive.label,
      size: 200,
      enableSorting: false,
    },
    {
      id: 'jobTemplateType',
      accessorKey: 'jobTemplateType',
      columnDefType: 'data',
      header: fields.jobTemplateType.label,
      size: 300,
    },
  ];
  const columnVisibilityInit = {
    jobTemplateName: true,
    jobTemplateDescription: true,
    isActive: true,
    jobTemplateType: true,
  };
  const columnOrderInit = [
    "jobTemplateName",
    "jobTemplateDescription",
    "isActive",
    "jobTemplateType",
  ];

  
	React.useImperativeHandle(
    ref,
    () => ({
      clearRowSelection() {
        clearRowSelection();
      },
      getSelectedRow() {
        return getSelectedRow();
      },
      getSelectedRowItem() {
        return getSelectedRowItem();
      },
      reloadTable() {
        reloadTable();
      },
    }),
  );
  

  React.useEffect(() => {
    let args: any = router.getArgs();
    let location = router.getLocation();
    if(args && (args.backOptions === BackOptions.Create || args.backOptions === BackOptions.Update)){
      router.removeArgs(location.pathname);
    } else {
      dispatch(table.callReadUserCookiesApi({
        pageName: pageName, 
        settingsType: settingsType, 
        searchQuery: searchQueryInit, 
        sortColumn: sortColumnInit, 
        sortDir: sortDirInit,  
        columnVisibility: columnVisibilityInit, 
        columnSizing: columnSizing, 
        columnOrder: columnOrderInit, 
        tableStatus: tableStatusInit, 
        isIncludeInactive: isIncludeInactiveInit,
        isInactive: isInactiveInit,
        isSavedCookie: false, 
      }, 
      async (state: boolean, options: any) => {
        if(state && options){
          await dispatch(table.callSetOptions(options, () => {
            firstLoadTable();
          }));
        }
      }));
    }
  }, []);
  
  React.useEffect(() => {
    if(show && id){
      setSelectedRow()
    }
  }, [show, id, dataTable]);
  
  React.useEffect(() => {
    if(props.onChangeSelected){
      let selectedId = getSelectedRow();
      props.onChangeSelected(((selectedRows && selectedRows.length > 0) && (selectedId && selectedId != '' && selectedId != id?.toString())))
    }
  }, [selectedRows]);


  const clearRowSelection = () => {
    setSelectedRows([]);
    dataTable?.table?.setRowSelection({});
  }
  const setSelectedRow = () => {
    if(id){
      const rowSelection = { [id]: true };
      setSelectedRows([rowSelection]);
      dataTable?.table?.setRowSelection(rowSelection);
    }
  }
  const getSelectedRow = () => {
    return getIds(selectedRows);
  }
  const getSelectedRowItem = () => {
    let selectedItems = '';

    if(selectedRows && selectedRows.length > 0){
      selectedItems = selectedRows.map((x: any) => x.original)
    }

    return selectedItems;
  }

  const firstLoadTable = () => {
    dispatch(table.callReadApi(listPath, null, false, true, () => {}));
  }

  const loadTable = (apiParams: any) => {
    dispatch(table.callReadApi(listPath, apiParams, true, false, () => {}));
  }

  const reloadTable = (params: any = null) => {
    clearRowSelection();
    dispatch(table.callReadApi(listPath, params, false, false, () => {}));
  }


  const topSection = () => {
    return <div className=''>
      <Row className='align-items-center g-12'>
        <Col xs={12} md={4} lg={3} xl={2}>
          <InputGroup>
            <InputGroup.Text className='p-search'>
              <SearchIcon />
            </InputGroup.Text>
            <Form.Control
              type={'text'}
              autoComplete='off'
              placeholder={'Search'}
              size={'sm'}
              isInvalid={false}
              value={(searchQuery && searchQuery != '') ? searchQuery : ''}
              onChange={(e: any) => {
                escapeChars(e);
                dispatch(table.callSetOptions({ 
                  searchQuery: e.target.value,
                  isIncludeInactive: isIncludeInactive,
                  isInactive: isInactive,
                  currentPage: 1,
                }));
              }}
              onKeyDown={(e: any) => {
                escapeChars(e);

                if (e.key === 'Enter') {
                  reloadTable();
                  
                  dispatch(table.callSaveUserCookiesApi({
                    pageName: pageName, 
                    settingsType: settingsType, 
                    searchQuery: searchQuery,
                    isIncludeInactive: isIncludeInactive,
                    isInactive: isInactive,
                    sortColumn: sortColumn,
                    sortDir: sortDir, 
                    columnVisibility: columnVisibility, 
                    columnSizing: columnSizing, 
                    columnOrder: columnOrder, 
                    tableStatus: tableStatus, 
                    isSavedCookie: true,
                  }, 
                  async (state: boolean, options: any, apiParams: any) => {
                    if(state && options){
                      await dispatch(table.callSetOptions(options));
                    }
                  }));
                }
              }}
              onPaste={(e: any) => {
                const pastedText = e.clipboardData.getData('text');
                const cleanedText = escapeCharsInStringOnPaste(pastedText);
                dispatch(table.callSetOptions({ 
                  searchQuery: cleanedText,
                  isIncludeInactive: isIncludeInactive,
                  isInactive: isInactive,
                  currentPage: 1,
                }));
                e.preventDefault();
              }}
            />
          </InputGroup>
        </Col>
        <Col xs={12} md={true} className='d-none d-md-block'></Col>

        {
          (selectedRows && selectedRows.length > 0)
          ?
          <>
            <Col xs={'auto'}>
              <a href={"/"} 
                className={'medium hover text-decoration-underline fs-12 gray-800'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  
                  clearRowSelection();
                }}
              >Deselect</a>
            </Col>
          </>
          :
          <>
            <Col xs={'auto'} className='text-end'>
              <DefaultPopup
                icon={<SettingsIcon />}
                text={'Edit columns'}
                disabled={false}
              >
                <DataTableVisibility
                  dataTable={dataTable}
                  isSavedCookie={isSavedCookie}
                  isLoadingSaveCookie={isLoadingSaveCookie}
                  onResetColumns={() => {
                    dispatch(table.callSaveUserCookiesApi({
                      pageName: pageName, 
                      settingsType: settingsType, 
                      sortColumn: sortColumnInit,
                      sortDir: sortDirInit, 
                      columnVisibility: columnVisibilityInit, 
                      columnSizing: {}, 
                      columnOrder: columnOrderInit, 
                      tableStatus: tableStatusInit, 
                      searchQuery: searchQueryInit,
                      isIncludeInactive: isIncludeInactiveInit,
                      isInactive: isInactiveInit,
                      isSavedCookie: false,
                    }, 
                    async (state: boolean, options: any, apiParams: any) => {
                      if(state && options){
                        await dispatch(table.callSetOptions(options));
                        loadTable(apiParams);
                      }
                    }));
                  }}
                />
              </DefaultPopup>
            </Col>
          </>
        }
      </Row>
    </div>
  }

  const tableSection = () => {
    return <MaterialDataTable
      onInit={(t: any) => setDataTable(t)}
      sliceName={sliceName}

      endpoint={listPath}
      method={method}
      firstTimeApiCall={false}
      rowId={rowId}
      initialParams={initialParams}
      initialExtraParams={null}
      customSortParams={customSortParams}

      columns={columns}
      columnsRender={columnsRender}
      showPagination={true}

      enableRowSelection={true}
      enableMultiRowSelection={false}
      enableSorting={true}
      enableColumnResizing={true}
      enableColumnOrdering={true}

      renderDetailPanel={null}

      onRowSelection={setSelectedRows}

      onChangeSort={(sortColumn: any, sortDir: any) => {
        dispatch(table.callSaveUserCookiesApi({
          pageName: pageName, 
          settingsType: settingsType, 
          sortColumn: sortColumn, 
          sortDir: sortDir, 
          isIncludeInactive: isIncludeInactive,
          isInactive: isInactive, 
          columnVisibility: columnVisibility, 
          columnSizing: columnSizing, 
          columnOrder: columnOrder, 
          tableStatus: tableStatus, 
          searchQuery: searchQuery,
          isSavedCookie: true,
        }, 
        async (state: boolean, options: any, apiParams: any) => {
          if(state && options){
            await dispatch(table.callSetOptions(options));
          }
        }));
      }}
      onChangeColumnVisibility={(columnVisibility: any) => {
        dispatch(table.callSaveUserCookiesApi({
          pageName: pageName, 
          settingsType: settingsType, 
          columnVisibility: columnVisibility, 
          isIncludeInactive: isIncludeInactive,
          isInactive: isInactive,
          sortColumn: sortColumn,
          sortDir: sortDir, 
          columnSizing: columnSizing, 
          columnOrder: columnOrder, 
          tableStatus: tableStatus, 
          searchQuery: searchQuery,
          isSavedCookie: true,
        }, 
        async (state: boolean, options: any, apiParams: any) => {
          if(state && options){
            await dispatch(table.callSetOptions(options));
          }
        }));
      }}
      onChangeColumnSizing={(columnSizing: any) => {
        dispatch(table.callSaveUserCookiesApi({
          pageName: pageName, 
          settingsType: settingsType, 
          columnSizing: columnSizing, 
          isIncludeInactive: isIncludeInactive,
          isInactive: isInactive,
          sortColumn: sortColumn,
          sortDir: sortDir, 
          columnVisibility: columnVisibility, 
          columnOrder: columnOrder, 
          tableStatus: tableStatus, 
          searchQuery: searchQuery,
          isSavedCookie: true,
        }, 
        async (state: boolean, options: any, apiParams: any) => {
          if(state && options){
            await dispatch(table.callSetOptions(options));
          }
        }));
      }}
      onChangeColumnOrder={(columnOrder: any) => {
        dispatch(table.callSaveUserCookiesApi({
          pageName: pageName, 
          settingsType: settingsType, 
          columnOrder: columnOrder, 
          isIncludeInactive: isIncludeInactive,
          isInactive: isInactive,
          sortColumn: sortColumn,
          sortDir: sortDir, 
          columnVisibility: columnVisibility, 
          columnSizing: columnSizing, 
          tableStatus: tableStatus, 
          searchQuery: searchQuery,
          isSavedCookie: true,
        }, 
        async (state: boolean, options: any, apiParams: any) => {
          if(state && options){
            await dispatch(table.callSetOptions(options));
          }
        }));
      }}
    />
  }

  
  return <>
    <NoResults
      isLoading={tableIsLoading}
      isFirstLoad={tableIsFirstLoad}
      total={tableTotal}
      searchQuery={searchQuery}
      title={'No results found'}
      desc={'There are no results'}
      button={null}
    >
      <Row className='g-24'>
        <Col xs={12}>
          {topSection()}
        </Col>
        <Col xs={12} className='mb-100'>
          {tableSection()}
        </Col>
      </Row>
    </NoResults>
  </>
});

export default ListComponent;
