/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import moment from 'moment';
import type { UploadFile } from 'antd';

import { axios } from '../../../v5/utils/axios';
import { ReadAPIParams, success, successAPI, errorAPI, placeholderRows, getMaxPageSize, isNumeric, dateTimeMeridianFormat } from '../../../v5/utils/utils';
import Yup from '../../../v5/utils/yup';
// import {  } from '../../../v5/utils/enums';

import { getBearing } from '../../../v5/components/googleMap/GoogleMap.jsx';


export const fields = {
  customerId: {
    id: 'customerId',
    label: 'Company name',
    placeholder: 'Select',
  },
  remarks: {
    id: 'remarks',
    label: 'Job Note',
    placeholder: placeholderRows(5, 'Write a note here', ''),
    rows: 5,
  },
  customerNote: {
    id: 'customerNote',
    label: 'Customer Note',
    placeholder: 'Default',
  },
  accountBillingProfileId: {
    id: 'accountBillingProfileId',
    label: 'Billing by',
    placeholder: 'Default',
  },
  paymentTermsId: {
    id: 'paymentTermsId',
    label: 'Payment terms',
    placeholder: 'Default',
  },
  xeroPaymentTerm: {
    id: 'xeroPaymentTerm',
    label: 'Payment terms',
    placeholder: 'Default',
  },
  
  jobTemplateId: {
    id: 'jobTemplateId',
    label: 'Tamplate name',
    placeholder: '',
  },
  
  customerSiteId: {
    id: 'customerSiteId',
    label: 'Customer site',
    placeholder: 'Select',
  },
  siteRemarks: {
    id: 'siteRemarks',
    label: 'Site instruction',
    placeholder: placeholderRows(1, 'Write a note here', ''),
    rows: 1,
  },
  contactPersonOne: {
    id: 'contactPersonOne',
    label: 'Contact person 1',
    placeholder: 'Name',
  },
  contactNumberOne: {
    id: 'contactNumberOne',
    label: 'Contact number 1',
    placeholder: 'Contact number',
  },
  contactPersonTwo: {
    id: 'contactPersonTwo',
    label: 'Contact person 2',
    placeholder: 'Name',
  },
  contactNumberTwo: {
    id: 'contactNumberTwo',
    label: 'Contact number 2',
    placeholder: 'Contact number',
  },

  sameAsBinOut: {
    id: 'sameAsBinOut',
    label: 'Same as BIN OUT',
    placeholder: '',
  },
  isTrackingBin: {
    id: 'isTrackingBin',
    label: 'Don’t track this Bin',
    placeholder: '',
  },
  binTypeId: {
    id: 'binTypeId',
    label: 'Bin type',
    placeholder: 'Select',
  },
  wasteTypeId: {
    id: 'binTypeId',
    label: 'Waste type',
    placeholder: 'Select',
  },
  binNumberId: {
    id: 'binNumberId',
    label: 'Bin ID',
    placeholder: 'Enter',
  },
  serviceTag: {
    id: 'serviceTag',
    label: 'Service name',
    placeholder: 'Filled automatically',
  },
  binweight: {
    id: 'binweight',
    label: 'Bin weight (gross)',
    placeholder: '',
  },
  
  jobDate: {
    id: 'jobDate',
    label: 'Job date',
    placeholder: 'Enter',
  },
  jobTimeSpecific: {
    id: 'jobTimeSpecific',
    label: 'Job time',
    placeholder: 'Enter',
  },
  driverId: {
    id: 'driverId',
    label: 'Driver',
    placeholder: 'Select',
  },
  vehicleId: {
    id: 'vehicleId',
    label: 'Vehicle',
    placeholder: 'Select',
  },
  jobStatus: {
    id: 'jobStatus',
    label: 'Job status',
    placeholder: '',
  },
  
  multipleTrips: {
    id: 'multipleTrips',
    label: 'Multiple Trips',
    placeholder: ' ',
  },
  numberOfTrip: {
    id: 'numberOfTrip',
    label: 'Number of trips',
    placeholder: ' ',
    info: 'Create multiple-trips per job instead of creating same job multiple times.',
  },
  assignToSameDriverVehicle: {
    id: 'assignToSameDriverVehicle',
    label: 'Assign to the same driver & vehicle',
    placeholder: '',
  },

  collectBin: {
    id: 'collectBin',
    label: 'Collect Bin',
    placeholder: ' ',
  },
  followUpDays: {
    id: 'followUpDays',
    label: 'Collect in (days)',
    placeholder: ' ',
    info: 'Follow up with a Collect Bin job with scheduled date and time.',
  },
  followUpDate: {
    id: 'followUpDate',
    label: 'Collection date',
    placeholder: '',
  },
  followUpTimeSpecific: {
    id: 'followUpTimeSpecific',
    label: 'Collection time',
    placeholder: '',
  },
  followUpAssignToSameDriverVehicle: {
    id: 'followUpAssignToSameDriverVehicle',
    label: 'Assign to the same driver & vehicle',
    placeholder: '',
  },

  amountToCollect: {
    id: 'amountToCollect',
    label: 'Amount to collect',
    placeholder: '',
  },
  collectedAmount: {
    id: 'collectedAmount',
    label: 'Collected by driver',
    placeholder: '',
  },
  jobPaymentType: {
    id: 'jobPaymentType',
    label: 'Payment mode',
    placeholder: ' ',
  },

  jobSignedUserName: {
    id: 'jobSignedUserName',
    label: 'Signed username',
    placeholder: ' ',
  },
  jobSignedUserContact: {
    id: 'jobSignedUserContact',
    label: 'Signed contact',
    placeholder: ' ',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    isActive: Yup.bool().oneOf([true, false]),

    customerId: Yup.number().nullable().required().label(fields.customerId.label),
    remarks: Yup.string().label(fields.remarks.label),

    jobTemplateId: Yup.number().nullable().required().label(fields.jobTemplateId.label),

    customerSiteId: Yup.number().nullable().required().label(fields.customerSiteId.label),
    siteRemarks: Yup.string().label(fields.siteRemarks.label),
    contactPersonOne: Yup.string().label(fields.contactPersonOne.label),
    contactNumberOne: Yup.string().label(fields.contactNumberOne.label),
    contactPersonTwo: Yup.string().label(fields.contactPersonTwo.label),
    contactNumberTwo: Yup.string().label(fields.contactNumberTwo.label),
    
    jobDate: Yup.string().nullable().label(fields.jobDate.label),
    jobTimeSpecific: Yup.string().nullable().label(fields.jobTimeSpecific.label),
    driverId: Yup.number().nullable().required().label(fields.driverId.label),
    vehicleId: Yup.number().nullable().required().label(fields.vehicleId.label),
    
    jobSignedUserName: Yup.string().label(fields.jobSignedUserName.label),
    jobSignedUserContact: Yup.string().label(fields.jobSignedUserContact.label),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null, defaultLocations: Array<any> = []) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let isActive = ((form.isActive == false) || (form.isActive == true)) ? form.isActive : false;
    
    let jobTemplateName = (form.jobTemplateName && form.jobTemplateName !== '') ? form.jobTemplateName : '';
    let jobTemplateDescription = (form.jobTemplateDescription && form.jobTemplateDescription !== '') ? form.jobTemplateDescription : '';
    

    data['isActive'] = isActive;

    data['jobTemplateName'] = jobTemplateName;
    data['jobTemplateDescription'] = jobTemplateDescription;
  }
  
  return data;
};
export const prepareData = (values: any = null, id: any = null, isCopy: boolean = false) => {
  let data: any = {};

  let form = _.cloneDeep(values);

  if(form){
    if(id){
      data['jobId'] = id;
    }

    if(isCopy){
      delete data['jobId'];
    }
    

    data['isActive'] = form.isActive;

    data['jobTemplateName'] = form.jobTemplateName;
    data['jobTemplateDescription'] = form.jobTemplateDescription;
  }

  return data;
};

export const weekDayNumb = (jobDate: any) => {
  return (jobDate) ? moment(jobDate).isoWeekday() : 7;
};
export const isWeekend = (weekDayNumb: any) => {
  return ((weekDayNumb === 6) || (weekDayNumb === 7)) ? true : false;
};

export const findStatus = (arr: Array<any> = [], statusName: string = '', field: string = 'jobStatusName') => {
  let status = null;

  if(arr && arr.length > 0){
    let statusItem = arr.filter(x => x?.templateStatus[field].toLowerCase().trim() === statusName.toLowerCase().trim());

    if(statusItem && statusItem.length > 0){
      status = statusItem[0]?.templateStatus;
    }
  }

  return status;
};
export const isVisibleAutoSuggestDriver = (status: string = '') => {
  if (status.toString().toLowerCase().trim() === '') {
    return true;
  } else if (status.toString().toLowerCase().trim() === 'unassigned') {
    return true;
  } else if (status.toString().toLowerCase().trim() === 'assigned') {
    return true;
  } else if (status.toString().toLowerCase().trim() === 'dispatched') {
    return true;
  } else {
    return false;
  }
}
export const formatCustomerSiteAddress = (item: any = null) => {
  let address = '';

  if (item) {
    let siteName = (item.siteName && item.siteName !== '') ? item.siteName : '';
    let siteNameDisplay = (item.siteNameDisplay && item.siteNameDisplay !== '') ? item.siteNameDisplay : '';
    let siteN = (siteNameDisplay !== '') ? siteNameDisplay : siteName;

    let blockNo = (item.blockNo && item.blockNo !== '') ? item.blockNo : '';
    let street = (item.street && item.street !== '') ? item.street : '';
    let unitNo = (item.unitNo && item.unitNo !== '') ? item.unitNo : '';
    let postalCode = (item.postalCode && item.postalCode !== '') ? item.postalCode : '';

    let addressParts = [];
    if (blockNo !== '') {
      addressParts.push('Block ' + blockNo);
    }
    if (street !== '') {
      addressParts.push(street);
    }
    if (unitNo !== '') {
      addressParts.push('Unit No. ' + unitNo);
    }
    if (postalCode !== '') {
      addressParts.push('Post Code ' + postalCode);
    }

    address = siteN;
    if (addressParts && addressParts.length > 0) {
      address += ' (' + addressParts.join(', ') + ')';
    }
  }

  return address;
}
export const concatStringRemarks = (mainText = '', newText = '', prevText = '', shouldChange = true) => {
  mainText = mainText.replace(prevText, '');
  if (shouldChange) {
    mainText = newText + '\n' + mainText;
  }
  mainText = mainText.replace(/^\s*[\r\n]/gm, '');
  return mainText;
}

export const markerLabelColor = (hexColor  = '#43936C') => {
  try {
    const dynamicClassName = `marker-label-${hexColor.replace("#", "")}`;
    const styleElement = document.createElement("style");
    styleElement.textContent = `
      .${dynamicClassName} {
        background-color: ${hexColor};
        color: white !important;
        font-weight: bold !important;
        font-size: 11px !important;
        padding: 3px 9px !important;
        border-radius: 20px !important;
      }
    `;
    document.head.appendChild(styleElement);
  }catch(e){}
}
export const defaultMarkerIcon = (text = '', color = '#7F818D', cls = '') => {
  try {
    let icon = encodeURIComponent(`<svg class="${cls}" width="44" height="54" viewBox="0 0 44 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill="white"/>
      <rect x="3" y="3" width="38" height="38" rx="19" fill="white"/>
      <rect x="3" y="3" width="38" height="38" rx="19" stroke="${color}" stroke-width="2"/>
      <rect x="6" y="6" width="32" height="32" rx="16" fill="${color}"/>
      <rect x="21" y="40" width="3" height="14" rx="1.5" fill="${color}"/>
    </svg>`)

    return {
      icon: {
        url: 'data:image/svg+xml;utf-8,' + icon,
        scaledSize: new window.google.maps.Size(44, 44),
        labelOrigin: new window.google.maps.Point(22, 18),
      },
      label: {
        text: text,
        color: "white",
        fontSize: "14px",
        fontWeight: "500"
      }
    }
  } catch(e){
    return {}
  }
}
export const driverMarkerIcon = (text = '', col = '', cls = '') => {
  try {
    let color = (col && col != '') ? col : '#43936C';

    let icon = encodeURIComponent(`<svg class="${cls}"  width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.5 14.0909C27.5 24.2727 14.5 33 14.5 33C14.5 33 1.5 24.2727 1.5 14.0909C1.5 6.861 7.3203 1 14.5 1C21.6797 1 27.5 6.861 27.5 14.0909Z" fill="${color}" stroke="${color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <rect x="5.5" y="5" width="18" height="18" rx="9" fill="white"/>
      <g clip-path="url(#clip0_1160_3948)">
        <path d="M16.1667 15.6667V10.25H9.91675V15.6667H16.1667ZM16.1667 15.6667H19.0834V13.5833L17.8334 12.3333H16.1667V15.6667ZM12.8334 16.7083C12.8334 17.2836 12.367 17.75 11.7917 17.75C11.2165 17.75 10.7501 17.2836 10.7501 16.7083C10.7501 16.133 11.2165 15.6667 11.7917 15.6667C12.367 15.6667 12.8334 16.133 12.8334 16.7083ZM18.2501 16.7083C18.2501 17.2836 17.7837 17.75 17.2084 17.75C16.6331 17.75 16.1667 17.2836 16.1667 16.7083C16.1667 16.133 16.6331 15.6667 17.2084 15.6667C17.7837 15.6667 18.2501 16.133 18.2501 16.7083Z" stroke="${color}" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1160_3948">
          <rect width="10" height="10" fill="white" transform="translate(9.5 9)"/>
        </clipPath>
      </defs>
    </svg>`)

    markerLabelColor(color);

    return {
      icon: {
        url: 'data:image/svg+xml;utf-8,' + icon,
        scaledSize: new window.google.maps.Size(26, 32),
        labelOrigin: new window.google.maps.Point(13, 41),
      },
      label: {
        text: text,
        color: "white",
        className: "marker-label-" + color.replace("#", ""),
      }
    }
  } catch(e){
    return {}
  }
}
export const arrowMarkerIcon = (data: any, item: any, i: number) => {
  try {
    let toPosition = data[i + 1] ? data[i + 1]?.position : null;

    return {
      icon: {
        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 2.5,
        fillColor: "#FFFFFF",
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: "#000000",
        rotation: i < data.length - 1 ? getBearing(item.position, toPosition) : 0,
      }
    }
  } catch(e){
    return {}
  }
}


export interface initialValuesStruct {
  isActive: boolean,

  customerId: number|null,
  customerName: string,
  customer: any,
  remarks: string,

  jobTemplateId: number|null,
  steps: Array<any>,

  jobDate: string,
  jobTimeSpecific: string,
  accountJobWorkingTimeId: number|null,
  accountJobWorkingTimeName: string,
  driverId: number|null,
  driverName: string,
  vehicleId: number|null,
  vehicleName: string,

  statusId: number|null,
  statusName: string,
  currentStatusId: number|null,
  currentStatusName: string,

  multipleTrip: boolean,
  numberOfTrip: number|null,
  assignToSameDriverVehicle: boolean,
  
  haveFollowUp: boolean,
  followUpDate: string,
  followUpTimeSpecific: string,
  followUpDays: number|null,
  followUpAssignToSameDriverVehicle: boolean,
  
  isRequirePaymentCollection: boolean,
  amountToCollect: number|null,
  collectedAmount: number|null,
  jobPaymentType: string|null,
  
  photoAttachmentsUrl1: UploadFile[],
  photoAttachmentsUrl2: UploadFile[],
  photoAttachmentsUrl3: UploadFile[],
  photoAttachmentsUrl4: UploadFile[],

  jobSignatureUrl: UploadFile[],
  jobSignedUserName: string,
  jobSignedUserContact: string,
};
export const initialValues: initialValuesStruct = {
  isActive: true,

  customerId: null,
  customerName: '',
  customer: null,
  remarks: '',

  jobTemplateId: null,
  steps: [],

  jobDate: '',
  jobTimeSpecific: '',
  accountJobWorkingTimeId: null,
  accountJobWorkingTimeName: '',
  driverId: null,
  driverName: '',
  vehicleId: null,
  vehicleName: '',

  statusId: null,
  statusName: '',
  currentStatusId: null,
  currentStatusName: '',

  multipleTrip: false,
  numberOfTrip: null,
  assignToSameDriverVehicle: false,
  
  haveFollowUp: false,
  followUpDate: '',
  followUpTimeSpecific: '',
  followUpDays: 0,
  followUpAssignToSameDriverVehicle: false,
  
  isRequirePaymentCollection: false,
  amountToCollect: null,
  collectedAmount: null,
  jobPaymentType: null,
  
  photoAttachmentsUrl1: [],
  photoAttachmentsUrl2: [],
  photoAttachmentsUrl3: [],
  photoAttachmentsUrl4: [],

  jobSignatureUrl: [],
  jobSignedUserName: '',
  jobSignedUserContact: '',
};


interface InitState {
  isLoading: boolean,
  id: any|null,
  details: any,

  isLoadingCreateUpdateDelete: boolean,

  isLoadingCreditLimit: boolean,
  isCreditLimit: boolean,
  msgCreditLimit: string,

  isLoadingJobTemplates: boolean,
  jobTemplates: Array<any>,
  jobTemplate: any,

  isLoadingCheckAllowPullJob: boolean,
  checkAllowPullJob: boolean,

  isLoadingAutoSuggestDriver: boolean,
  autoSuggestDriver: any,

  isLoadingDefaultLocations: boolean,
  defaultLocations: Array<any>,
  
  isLoadingLatestLocationDrivers: boolean,
  latestLocationDrivers: Array<any>,

  isLoadingDistance: boolean,
  distance: number,
}

function NewReducer() {
  const name = 'jobFormSlice';


  const initialState: InitState = {
    isLoading: false,
    id: null,
    details: initialValues,

    isLoadingCreateUpdateDelete: false,

    isLoadingCreditLimit: false,
    isCreditLimit: false,
    msgCreditLimit: '',
    
    isLoadingJobTemplates: false,
    jobTemplates: [],
    jobTemplate: null,
    
    isLoadingCheckAllowPullJob: false,
    checkAllowPullJob: false,

    isLoadingAutoSuggestDriver: false,
    autoSuggestDriver: null,

    isLoadingDefaultLocations: false,
    defaultLocations: [],

    isLoadingLatestLocationDrivers: false,
    latestLocationDrivers: [],
    
    isLoadingDistance: true,
    distance: 0,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.list = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.list = data;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startActivate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishActivate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startReadCheckCreditLimit: (state: InitState) => {
      state.isLoadingCreditLimit = true;
      state.isCreditLimit = false;
      state.msgCreditLimit = '';
    },
    finishReadCheckCreditLimit: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload != '') ? action.payload : null;
      
      if (data) {
        state.isCreditLimit = true;
        state.msgCreditLimit = data;
      } else {
        state.isCreditLimit = false;
        state.msgCreditLimit = '';
      }

      state.isLoadingCreditLimit = false;
    },
    resetCheckCreditLimit: (state: InitState) => {
      state.isLoadingCreditLimit = false;
      state.isCreditLimit = false;
      state.msgCreditLimit = '';
    },

    startReadJobTemplate: (state: InitState) => {
      state.isLoadingJobTemplates = true;
      state.jobTemplates = [];
    },
    finishReadJobTemplate: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.jobTemplates = data;
      state.isLoadingJobTemplates = false;
    },

    startDetailsJobTemplate: (state: InitState) => {
      state.isLoadingJobTemplates = true;
      state.jobTemplate = null;
    },
    finishDetailsJobTemplate: (state: InitState, action: PayloadAction<any>) => {
      state.jobTemplate = action.payload;
      state.isLoadingJobTemplates = false;
    },
    clearJobTemplate: (state: InitState) => {
      state.jobTemplate = null;
    },

    startCheckAllowPullJob: (state: InitState) => {
      state.isLoadingCheckAllowPullJob = true;
      state.checkAllowPullJob = false;
    },
    finishCheckAllowPullJob: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data) ? action.payload.data : false;
      state.checkAllowPullJob = data;
      state.isLoadingCheckAllowPullJob = false;
    },

    startAutoSuggestDriver: (state: InitState) => {
      state.isLoadingAutoSuggestDriver = true;
      state.autoSuggestDriver = null;
    },
    finishAutoSuggestDriver: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data) ? action.payload.data : null;
      state.autoSuggestDriver = data;
      state.isLoadingAutoSuggestDriver = false;
    },

    startReadDefaultLocation: (state: InitState) => {
      state.isLoadingDefaultLocations = true;
    },
    finishReadDefaultLocation: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];

      let defaultLocations: any = [];
      if(data && data.length > 0){
        defaultLocations = data.map((item: any, i: number) => {
          item['addressField'] = item.address;
          return item;
        });
      }

      state.defaultLocations = defaultLocations;
      state.isLoadingDefaultLocations = false;
    },

    startLatestLocationDrivers: (state: InitState) => {
      state.isLoadingLatestLocationDrivers = true;
      state.latestLocationDrivers = [];
    },
    finishLatestLocationDrivers: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.length > 0) ? action.payload : [];
      
      let arr: any = [];
      if(data && data.length > 0){
        arr = data.map((item: any, i: number) => {
          let coordinate = {
            lat: item.latitude,
            lng: item.longitude,
          };

          let driver = (item && item.driver) ? item.driver : null;
          let driverName = (driver && driver.driverName) ? driver.driverName : '';
      
          return {
            driverId: item.driverId,
            driverName: driverName,
            title: 'Updated: ' + moment(item.updated).format(dateTimeMeridianFormat()),
            item: item,
            position: coordinate,
          }
        });
      }
      
      state.latestLocationDrivers = arr;
      state.isLoadingLatestLocationDrivers = false;
    },

    startGetDistance: (state: InitState) => {
      state.isLoadingDistance = true;
      state.distance = 0;
    },
    finishGetDistance: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload) ? action.payload : 0;
      
      state.distance = data;
      state.isLoadingDistance = false;
    },
    cancelGetDistance: (state: InitState) => {
      state.isLoadingDistance = false;
      state.distance = 0;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('job', { params: params }).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('job/' + id).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('job', params).then((result: any) => {
            let data = result.data;
            
            // successAPI(data);
            success('Saved successfully', 'The data you entered has been successfully saved.');

            callback(true, data);
            dispatch(actions.finishCreate(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('job', params).then((result: any) => {
            let data = result.data;
            
            // successAPI(data);
            success('Updated successfully', 'Your data has been successfully updated.');

            callback(true, data);
            dispatch(actions.finishUpdate(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('job', { data: params }).then((result: any) => {
            let data = result.data;
                
            // successAPI(data);
            success('Deleted successfully', 'Job Type has been successfully deleted.');

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },

    callActivateApi: (isActive: boolean, ids: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startActivate());

        let params = {
          isActive: isActive,
          ids: ids,
        };

        await axios.put('job/deactivate', params).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishActivate(obj));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishActivate(null));
        });
    },

    callReadCheckCreditLimitApi: (customerId: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      if(customerId && isNumeric(customerId) && customerId > 0){
        dispatch(actions.startReadCheckCreditLimit());

        await axios.get('Customer/' + customerId + '/check-credit-limit').then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishReadCheckCreditLimit(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishReadCheckCreditLimit(null));
        });
      }
    },

    callReadJobTemplateApi: (params: ReadAPIParams|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadJobTemplate());

      let param = (params) ? params : {
        currentPage: 1,
        pageSize: getMaxPageSize(),
        searchQuery: '',
        sortColumn: 'common',
        sortDir: 'desc',
      };
      
      await axios.get('jobtemplate', { params: param }).then((result: any) => {
          let data = result.data;
          
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishReadJobTemplate(data));
      }).catch((error: any) => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishReadJobTemplate(null));
      });
    },

    callDetailsJobTemplateApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetailsJobTemplate());
  
        await axios.get('jobtemplate/' + id).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetailsJobTemplate(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetailsJobTemplate(null));
        });
    },

    callCheckAllowPullJobApi: (callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startCheckAllowPullJob());

      await axios.get('job/check-allow-pull-job', { params: null }).then((result: any) => {
          let data = result.data;
          
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishCheckAllowPullJob(data));
      }).catch((error: any) => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishCheckAllowPullJob(null));
      });
    },

    callAutoSuggestDriverApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startAutoSuggestDriver());

      await axios.post('job/auto-suggest-driver', params).then((result: any) => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishAutoSuggestDriver(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishAutoSuggestDriver(null));
      });
    },

    callReadDefaultLocationApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadDefaultLocation());

      await axios.get('default-location', { params: params }).then((result: any) => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishReadDefaultLocation(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishReadDefaultLocation(null));
      });
    },
    
    callLatestLocationDriversApi: (ids: Array<any>, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startLatestLocationDrivers());

      await axios.post('driver/latest-location', { driverIds: ids }).then((result: any) => {
          let data = result.data;
          
          // successAPI(data);

          callback(true, data);
          dispatch(actions.finishLatestLocationDrivers(data));
      }).catch((error: any) => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishLatestLocationDrivers(null));
      });
    },
    
    callGetDistanceApi: (coordinates: Array<any>, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      if(coordinates && coordinates.length > 0){
        dispatch(actions.startGetDistance());

        await axios.post('route/get-distance', coordinates).then((result: any) => {
          let data = result.data;
          
          // successAPI(data);

          callback(true, data);
          dispatch(actions.finishGetDistance(data));
        }).catch((error: any) => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishGetDistance(null));
        });
      } else {
        dispatch(actions.cancelGetDistance());
      }
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();