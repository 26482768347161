import React, { ReactNode } from 'react';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Offcanvas,
  Modal,
} from 'react-bootstrap';
// import styled from 'styled-components';

// import { Divider } from 'antd';

// Auth
// import { useAuthContext } from '../../../auth/use-auth-context.js';
// import { useRouter } from '../../../auth/auth-guard';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../utils/store.tsx';
import slice, { fields } from './slice.tsx';

// import {  } from '../../../../utils/utils.tsx';
// import {  } from '../../../../utils/enums.tsx';

// import { ReactComponent as XIcon } from "../../../../../v5/assets/icons/x.svg";

// ----------------------------------------------------------------------

const PageComponent = (props: any) => {
  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  // const router = useRouter();

  const { show } = useTypedSelector((state: RootState) => state.jobFormWeightChitSlice);
  const dispatch = useDispatch<AppDispatch>();


  // React.useEffect(() => {
  //   if(show){
      
  //   }
  // }, [show])


  const onSave = () => {
    dispatch(slice.setShow(false));
  }
  const onCancel = () => {
    dispatch(slice.setShow(false));
  }
  

  const footerElements = () => {
    return <Row className='w-100 gx-12'>
      <Col xs={true}></Col>
      <Col xs={'auto'} className='text-end'>
        <Button
          variant={'custom-text'}
          size={'sm'}
          className='me-12'
          onClick={(e) => {
            onCancel();
          }}
        >Cancel</Button>
        <Button
          variant={'custom-primary'}
          size={'sm'}
          onClick={(e) => {
            onSave();
          }}
        >Save</Button>
      </Col>
    </Row>
  }

  const formElements = () => {
    return <Row className='g-24 align-items-center'>
      <Col xs={12}>
        Form
      </Col>
    </Row>
  }


  return <Offcanvas
    backdrop={'static'}
    scroll={false}
    placement={'end'}
    show={show}
    onHide={() => {
      onCancel();
    }}
    style={{ width: '60%' }}
  >
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>Billing</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>{formElements()}</Offcanvas.Body>
    <Modal.Footer className='border-top'>{footerElements()}</Modal.Footer>
  </Offcanvas>
}

export default PageComponent;
