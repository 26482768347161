import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
  Button,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Divider, Radio, Spin } from "antd";

// Auth
// import { useAuthContext } from '../../../../../auth/use-auth-context.js';
// import { useRouter } from '../../../../../auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../../utils/store.tsx';
import slice, { useFormikContext, fields, JobTemplateType, LocationEnum, BinActivityEnum, getVehicleBinStatus, disableLocations, disableBinActivityes, initStep, initStepSite } from '../slice.tsx';
import confirmSlice from '../../../../../components/modal/confirmSlice.tsx';

// enums
import { success, warning, autoFoucus, scrollTo, getAddressPlace } from '../../../../../utils/utils.tsx';
// import {  } from '../../../../../utils/enums.tsx';

// components
// import MainContainer from '../../../../../components/container/MainContainer.jsx';
// import SmartAutocomplete from '../../../../../components/autocomplete/SmartAutocomplete.tsx';
import GoogleSearchAddress from '../../../../../components/googleMap/GoogleSearchAddress.tsx';

import { ReactComponent as PlusIcon } from "../../../../../../v5/assets/icons/plus.svg";
import { ReactComponent as TrashIcon } from "../../../../../../v5/assets/icons/trash-2.svg";
import { ReactComponent as InfoIcon } from "../../../../../../v5/assets/icons/i.svg";
import { ReactComponent as SlidersIcon } from "../../../../../../v5/assets/icons/sliders.svg";

// ----------------------------------------------------------------------

const StyledStep = styled(Col)`
  border-radius: 6px;
  background-color: var(--bs-blue-focus1);

  > div {
    padding: 16px 24px;

    > div {
      padding-left: 0px;
      padding-right: 0px;

      .ant-divider {
        margin-top: 4px;
        margin-bottom: 4px;
        border-color: var(--bs-gray-100);
      }
    }
  }
`;
const StyledStepStatus = styled(Col)`
  border-radius: 6px;
  padding: 16px 24px;
  background-color: var(--bs-green-focus2);

  
  .ant-divider {
    margin-left: 0px;
    margin-right: 0px;
    border-color: var(--bs-gray-100);
  }
`;
const StyledInfoIcon = styled(InfoIcon)`
  position: relative;
  top: -1px;
  width: 18px;
  height: 19px;
  margin-right: 5px;

  path {
    stroke: var(--bs-gray-600);
  }
`;
const StyledVehicleBinStatus = styled.span`
  color: var(--bs-gray-600);

  &.Loaded {
    color: var(--bs-yellow-500);
  }
  
  &.Empty {
    color: var(--bs-green-500);
  }
`;
const StyledStepLocation = styled(Col)`
  border-radius: 6px;
  padding: 12px 16px;
  background-color: white;
`;


const Tab2 = ({ formikRef }: any) => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { isLoadingDefaultLocations, defaultLocations } = useTypedSelector((state: RootState) => state.manageJobTypesSlice);
  const dispatch = useDispatch<AppDispatch>();


  const setFormElements = () => {
    return <Row className='g-16'>

      <Col xs={12}>
        <Row className='g-16'>
          {values.templateSteps.map((item: any, i: number) => {
            let vehicleBinStatus: any = getVehicleBinStatus(i, item, values.templateSteps);
            let disableLocation: any = disableLocations(i, item, values.templateSteps);
            let disableBinActivity: any = disableBinActivityes(i, item, values.templateSteps);

            return <React.Fragment key={'step_tab2_' + i} >
              <StyledStep xs={12} className={'step-' + i}>
                <Row className='g-16'>
                  <Col xs={12} className='semibold gray-900'>Step {(i+1)}</Col>

                  <Col xs={12}>
                    <Row className='g-12'>
                      <Col xs={12} className='gray-800'>Choose a location</Col>
                      <Col xs={'auto'}>
                        <Radio
                          disabled={disableLocation?.CustomerSite || (values.jobTemplateType === JobTemplateType.Default)}
                          checked={(item.locationType === LocationEnum.CustomerSite)}
                          onChange={async (e) => {
                            await setFieldValue('templateSteps.' + i + '.locationType', LocationEnum.CustomerSite);
                            await setFieldValue('templateSteps.' + i + '.binActivity', null);

                            setTimeout(() => {
                              validateForm()
                            }, 100);
                          }}
                        >Customer Site</Radio>
                      </Col>
                      <Col xs={'auto'}>
                        <Radio
                          disabled={disableLocation?.Yard || (values.jobTemplateType === JobTemplateType.Default)}
                          checked={(item.locationType === LocationEnum.Yard)}
                          onChange={async (e) => {
                            await setFieldValue('templateSteps.' + i + '.locationType', LocationEnum.Yard);
                            await setFieldValue('templateSteps.' + i + '.binActivity', null);

                            setTimeout(() => {
                              validateForm()
                            }, 100);
                          }}
                        >Yard</Radio>
                      </Col>
                      <Col xs={'auto'}>
                        <Radio
                          disabled={disableLocation?.Landfill || (values.jobTemplateType === JobTemplateType.Default)}
                          checked={(item.locationType === LocationEnum.Landfill)}
                          onChange={async (e) => {
                            await setFieldValue('templateSteps.' + i + '.locationType', LocationEnum.Landfill);
                            await setFieldValue('templateSteps.' + i + '.binActivity', null);

                            setTimeout(() => {
                              validateForm()
                            }, 100);
                          }}
                        >Landfill</Radio>
                      </Col>
                    </Row>
                  </Col>

                  {
                    (item.locationType === LocationEnum.CustomerSite)
                    ?
                    <Col xs={12} className='gry-600'><i>Specific customer location will be entered when creating a job.</i></Col>
                    :
                    <StyledStepLocation xs={12} className='shadow1 px-16'>
                      <Row className='g-8'>
                        <Col xs={12}>
                          <DropdownButton
                            variant={'custom-outlined'}
                            size={'sm'}
                            title={item.hasDefaultSite ? 'Default Location' : 'No Default location. I will enter address when creating job'}
                          >
                            <Dropdown.Item
                              active={(item.hasDefaultSite === true)}
                              onClick={() => {
                                setFieldValue('templateSteps.' + i + '.hasDefaultSite', true);
                              }}
                            >Default Location</Dropdown.Item>
                            <Dropdown.Item
                              active={(item.hasDefaultSite === false)}
                              onClick={() => {
                                setFieldValue('templateSteps.' + i + '.hasDefaultSite', false);
                              }}
                            >No Default location. I will enter address when creating job</Dropdown.Item>
                          </DropdownButton>
                        </Col>
                        
                        {item.hasDefaultSite && <>
                          <Col xs={12}>
                            <Row className='g-8'>
                              <Col xs={'auto'} md={2} lg={1}><small className="semibold gray-600">Set as default</small></Col>
                              <Col xs={'auto'} md={3} lg={4} xl={3} xxl={2}><small className="semibold gray-600">Location Name</small></Col>
                              <Col xs={true} md={true}><small className="semibold gray-600">Address</small></Col>
                              <Col xs={'auto'} md={'auto'}><small className="semibold gray-600">&nbsp;</small></Col>
                            </Row>
                          </Col>

                          <Col xs={12}><Divider /></Col>

                          {
                            (item.templateStepSites.length > 0)
                            ?
                            <>
                              <Col xs={12}>
                                <Spin spinning={isLoadingDefaultLocations} wrapperClassName={'no-height'}>
                                  <Row className='g-16'>
                                    {item.templateStepSites.map((site: any, j: number) => {
                                      return <Col key={'step_' + i + '_site_templateStepSites_' + j} xs={12}>
                                        <Row className='align-items-center g-8'>
                                          <Col xs={12} md={2} lg={1} className='text-center'>
                                            <Radio
                                              disabled={site.defaultLocationId === null}
                                              checked={(item.defaultLocationId === site.defaultLocationId)}
                                              onChange={async (e) => {
                                                await setFieldValue('templateSteps.' + i + '.defaultLocationId', site.defaultLocationId);

                                                // setTimeout(() => {
                                                //   validateForm()
                                                // }, 100);
                                              }}
                                            />
                                          </Col>
                                          <Col xs={12} md={3} lg={4} xl={3} xxl={2}>
                                            <Form.Group>
                                              <Form.Control
                                                className={'location-name-' + i + '-site-' + j}
                                                type={'text'}
                                                autoComplete='off'
                                                placeholder={'Enter site name'}
                                                disabled={false}
                                                value={site.locationName}
                                                onChange={async (e) => {
                                                  await setFieldValue('templateSteps.' + i + '.templateStepSites.' + j + '.locationName', e.target.value);
                                                }}
                                                onBlur={(e) => {
                                                  validateForm().then((err: any) => {
                                                    const errors = err?.templateSteps?.[i]?.templateStepSites?.[j];
    
                                                    if (errors && Object.entries(errors).length > 0) {
                                                      // warning('Warning', 'You must fill in the required fields');
                                                    } else {
                                                      let params: any = {
                                                        isDefault: false,
                                                        locationName: site.locationName,
                                                        addressField: site.address,
                                                        address: site.address,
                                                        latitude: site.latitude,
                                                        longitude: site.longitude,
                                                      };
                                                      if(site.defaultLocationId){
                                                        params['defaultLocationId'] = site.defaultLocationId;
                                                        dispatch(slice.callUpdateDefaultLocationApi(params, (state: boolean, data: any) => {
                                                          if(state){
                                                            dispatch(slice.editDefaultLocation(data));
                                                          }
                                                        }));
                                                      } else {
                                                        dispatch(slice.callCreateDefaultLocationApi(params, async (state: boolean, data: any) => {
                                                          if(state){
                                                            await setFieldValue('templateSteps.' + i + '.templateStepSites.' + j + '.defaultLocationId', data?.defaultLocationId);
                                                            dispatch(slice.addDefaultLocation(data));
                                                          }
                                                        }));
                                                      }
                                                    }
                                                  })
                                                }}
                                                isInvalid={!!(errors && errors.templateSteps && errors.templateSteps.length > 0 && errors.templateSteps[i] && errors.templateSteps[i].templateStepSites && errors.templateSteps[i].templateStepSites.length > 0 && errors.templateSteps[i].templateStepSites[j] && errors.templateSteps[i].templateStepSites[j].locationName)}
                                              />
                                            </Form.Group>
                                          </Col>
                                          <Col xs={12} md={true}>
                                            <Form.Group>
                                              <Form.Control
                                                as={GoogleSearchAddress}
                                                autoComplete='off'
                                                placeholder={'Insert address or coordinate'}
                                                isInvalid={!!(errors && errors.templateSteps && errors.templateSteps.length > 0 && errors.templateSteps[i] && errors.templateSteps[i].templateStepSites && errors.templateSteps[i].templateStepSites.length > 0 && errors.templateSteps[i].templateStepSites[j] && errors.templateSteps[i].templateStepSites[j].address)}
                                                value={site.addressField}
                                                onPlaceSelected={async (place: any) => {
                                                  let obj = getAddressPlace(place);

                                                  await setFieldValue('templateSteps.' + i + '.templateStepSites.' + j + '.addressField', obj?.address);
                                                  await setFieldValue('templateSteps.' + i + '.templateStepSites.' + j + '.address', obj?.address);
                                                  await setFieldValue('templateSteps.' + i + '.templateStepSites.' + j + '.latitude', obj?.lat);
                                                  await setFieldValue('templateSteps.' + i + '.templateStepSites.' + j + '.longitude', obj?.lng);

                                                  await validateForm().then((err: any) => {
                                                    const errors = err?.templateSteps?.[i]?.templateStepSites?.[j];
    
                                                    if (errors && Object.entries(errors).length > 0) {
                                                      // warning('Warning', 'You must fill in the required fields');
                                                    } else {
                                                      const currentStep = formikRef.current?.values?.templateSteps[i];
                                                      const currentSite = formikRef.current?.values?.templateSteps[i].templateStepSites[j];

                                                      let params: any = {
                                                        isDefault: false,
                                                        locationName: currentSite.locationName,
                                                        addressField: currentSite.address,
                                                        address: currentSite.address,
                                                        latitude: currentSite.latitude,
                                                        longitude: currentSite.longitude,
                                                      };
                                                      if(currentSite.defaultLocationId){
                                                        params['defaultLocationId'] = currentSite.defaultLocationId;
                                                        dispatch(slice.callUpdateDefaultLocationApi(params, (state: boolean, data: any) => {
                                                          if(state){
                                                            dispatch(slice.editDefaultLocation(data));
                                                          }
                                                        }));
                                                      } else {
                                                        dispatch(slice.callCreateDefaultLocationApi(params, async (state: boolean, data: any) => {
                                                          if(state){
                                                            await setFieldValue('templateSteps.' + i + '.templateStepSites.' + j + '.defaultLocationId', data?.defaultLocationId);
                                                            dispatch(slice.addDefaultLocation(data));
                                                          }
                                                        }));
                                                      }
                                                    }
                                                  })
                                                }}
                                                onChange={async (e) => {
                                                  await setFieldValue('templateSteps.' + i + '.templateStepSites.' + j + '.addressField', e.target.value);
                                                }}
                                                onBlur={async (e) => {
                                                  if(e.target.value != site.address){
                                                    await setFieldValue('templateSteps.' + i + '.templateStepSites.' + j + '.addressField', '');
                                                    await setFieldValue('templateSteps.' + i + '.templateStepSites.' + j + '.address', '');
                                                    await setFieldValue('templateSteps.' + i + '.templateStepSites.' + j + '.latitude', null);
                                                    await setFieldValue('templateSteps.' + i + '.templateStepSites.' + j + '.longitude', null);
                                                  }
                                                }}
                                              />
                                            </Form.Group>
                                          </Col>
    
                                          <Col xs={12} md={'auto'} className='text-center'>
                                            <Button
                                              className={'px-10 py-8'}
                                              variant={'custom-secondary-danger'}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                  
                                                if(site.defaultLocationId){
                                                  dispatch(confirmSlice.show(() => {
                                                    let items = _.cloneDeep(item.templateStepSites);
                                                    items.splice(j, 1);
                                                    setFieldValue('templateSteps.' + i + '.templateStepSites', items);
                                                    
                                                    dispatch(slice.callDeleteDefaultLocationApi({ defaultLocationId : site.defaultLocationId }, (state: boolean, data: any) => {
                                                      if(state){
                                                        if(item.defaultLocationId === site.defaultLocationId){
                                                          setFieldValue('templateSteps.' + i + '.defaultLocationId', null);
                                                        }

                                                        dispatch(slice.removeDefaultLocation(site));
                                                      }
    
                                                      dispatch(confirmSlice.setHide());
                                                    }));
                                                  }, {
                                                    title: 'Delete Location',
                                                    body: 'Are you sure to delete this location? This cannot be undone',
                                                    size: 'sm',
                                                    cancelText: 'Cancel',
                                                    cancelVariant: 'custom-outline',
                                                    confirmText: 'Delete',
                                                    confirmVariant: 'custom-danger',
                                                  }));
                                                } else {
                                                  let items = _.cloneDeep(item.templateStepSites);
                                                  items.splice(j, 1);
                                                  setFieldValue('templateSteps.' + i + '.templateStepSites', items);
                                                }
                                              }}
                                            >
                                              <TrashIcon />
                                            </Button>
                                          </Col>
    
                                        </Row>
                                      </Col>
                                    })}
                                  </Row>
                                </Spin>
                              </Col>
                                    
                              {(errors && errors.templateSteps && errors.templateSteps[i] && errors.templateSteps[i].defaultLocationId) && <Col xs={12}>
                                <Form.Control.Feedback className={'d-block'}  type="invalid">{errors.templateSteps[i].defaultLocationId}</Form.Control.Feedback>
                              </Col>}
                            </>
                            :
                            <Col xs={12}>No locations</Col>
                          }

                          <Col xs={12}><Divider /></Col>

                          <Col xs={12}>
                            <Button
                              className={'px-0'}
                              size={'sm'}
                              variant={'custom-text-primary'}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                validateForm().then((err: any) => {
                                  if(!!err?.templateSteps?.[i]?.templateStepSites){
                                    warning('Warning', 'You must fill in the required fields');
                                  } else {
                                    let items = _.cloneDeep(item.templateStepSites);
                                    let newItem = _.cloneDeep(initStepSite);
                                    items.push(newItem);
                                    setFieldValue('templateSteps.' + i + '.templateStepSites', items);
                                    autoFoucus('.location-name-' + i + '-site-' + (items.length-1));
                                  }
                                })
                              }}
                            >
                              Add More Location
                            </Button>
                          </Col>
                          
                        </>}
                      </Row>
                    </StyledStepLocation>
                  }

                  <Col xs={12}><Divider /></Col>

                  <Col xs={12}>
                    <Row className='g-12'>
                      <Col xs={12} className='gray-800'>What the driver do in this location?</Col>
                      <Col xs={12}>
                        <Row className='g-8 mb-8'>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.binActivity === BinActivityEnum.PickEmptyBin)}
                              disabled={(disableBinActivity?.PickEmptyBin) || (values.jobTemplateType === JobTemplateType.Default)}
                              variant={'custom-label-outlined'}
                              onClick={async () => {
                                await setFieldValue('templateSteps.' + i + '.binActivity', (item.binActivity !== BinActivityEnum.PickEmptyBin) ? BinActivityEnum.PickEmptyBin : null);

                                if(i === (values.templateSteps.length-1) && (item.binActivity === null)){
                                  scrollTo('.add-step');
                                }

                                // setTimeout(() => {
                                //   validateForm()
                                // }, 100);
                              }}
                            >
                              Pick Empty Bin
                            </Button>
                          </Col>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.binActivity === BinActivityEnum.PickLoadedBin)}
                              disabled={(disableBinActivity?.PickLoadedBin) || (values.jobTemplateType === JobTemplateType.Default)}
                              variant={'custom-label-outlined'}
                              onClick={async () => {
                                await setFieldValue('templateSteps.' + i + '.binActivity', (item.binActivity !== BinActivityEnum.PickLoadedBin) ? BinActivityEnum.PickLoadedBin : null);
                                
                                if(i === (values.templateSteps.length-1) && (item.binActivity === null)){
                                  scrollTo('.add-step');
                                }

                                // setTimeout(() => {
                                //   validateForm()
                                // }, 100);
                              }}
                            >
                              Pick Loaded Bin
                            </Button>
                          </Col>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.binActivity === BinActivityEnum.LoadWaste)}
                              disabled={(disableBinActivity?.LoadWaste) || (values.jobTemplateType === JobTemplateType.Default)}
                              variant={'custom-label-outlined'}
                              onClick={async () => {
                                await setFieldValue('templateSteps.' + i + '.binActivity', (item.binActivity !== BinActivityEnum.LoadWaste) ? BinActivityEnum.LoadWaste : null);

                                if(i === (values.templateSteps.length-1) && (item.binActivity === null)){
                                  scrollTo('.add-step');
                                }

                                // setTimeout(() => {
                                //   validateForm()
                                // }, 100);
                              }}
                            >
                              Load Waste
                            </Button>
                          </Col>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.binActivity === BinActivityEnum.WasteDisposal)}
                              disabled={(disableBinActivity?.WasteDisposal) || (values.jobTemplateType === JobTemplateType.Default)}
                              variant={'custom-label-outlined'}
                              onClick={async () => {
                                await setFieldValue('templateSteps.' + i + '.binActivity', (item.binActivity !== BinActivityEnum.WasteDisposal) ? BinActivityEnum.WasteDisposal : null);

                                if(i === (values.templateSteps.length-1) && (item.binActivity === null)){
                                  scrollTo('.add-step');
                                }

                                // setTimeout(() => {
                                //   validateForm()
                                // }, 100);
                              }}
                            >
                              Unload Waste
                            </Button>
                          </Col>
                        </Row>
                        <Row className='g-8'>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.binActivity === BinActivityEnum.DropEmptyBin)}
                              disabled={(disableBinActivity?.DropEmptyBin) || (values.jobTemplateType === JobTemplateType.Default)}
                              variant={'custom-label-outlined'}
                              onClick={async () => {
                                await setFieldValue('templateSteps.' + i + '.binActivity', (item.binActivity !== BinActivityEnum.DropEmptyBin) ? BinActivityEnum.DropEmptyBin : null);

                                if(i === (values.templateSteps.length-1) && (item.binActivity === null)){
                                  scrollTo('.add-step');
                                }

                                // setTimeout(() => {
                                //   validateForm()
                                // }, 100);
                              }}
                            >
                              Drop Empty Bin
                            </Button>
                          </Col>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.binActivity === BinActivityEnum.DropLoadedBin)}
                              disabled={(disableBinActivity?.DropLoadedBin) || (values.jobTemplateType === JobTemplateType.Default)}
                              variant={'custom-label-outlined'}
                              onClick={async () => {
                                await setFieldValue('templateSteps.' + i + '.binActivity', (item.binActivity !== BinActivityEnum.DropLoadedBin) ? BinActivityEnum.DropLoadedBin : null);

                                if(i === (values.templateSteps.length-1) && (item.binActivity === null)){
                                  scrollTo('.add-step');
                                }

                                // setTimeout(() => {
                                //   validateForm()
                                // }, 100);
                              }}
                            >
                              Drop Loaded Bin
                            </Button>
                          </Col>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.binActivity === BinActivityEnum.ExchangeBin)}
                              disabled={(disableBinActivity?.ExchangeBin) || (values.jobTemplateType === JobTemplateType.Default)}
                              variant={'custom-label-outlined'}
                              onClick={async () => {
                                await setFieldValue('templateSteps.' + i + '.binActivity', (item.binActivity !== BinActivityEnum.ExchangeBin) ? BinActivityEnum.ExchangeBin : null);

                                if(i === (values.templateSteps.length-1) && (item.binActivity === null)){
                                  scrollTo('.add-step');
                                }

                                // setTimeout(() => {
                                //   validateForm()
                                // }, 100);
                              }}
                            >
                              Exchange
                            </Button>
                          </Col>
                          <Col xs={true}>
                            <Button
                              className={'w-100'}
                              size={'sm'}
                              active={(item.binActivity === BinActivityEnum.NoActivity)}
                              disabled={(disableBinActivity?.NoActivity) || (values.jobTemplateType === JobTemplateType.Default)}
                              variant={'custom-label-outlined'}
                              onClick={async () => {
                                await setFieldValue('templateSteps.' + i + '.binActivity', (item.binActivity !== BinActivityEnum.NoActivity) ? BinActivityEnum.NoActivity : null);

                                if(i === (values.templateSteps.length-1) && (item.binActivity === null)){
                                  scrollTo('.add-step');
                                }

                                // setTimeout(() => {
                                //   validateForm()
                                // }, 100);
                              }}
                            >
                              Other
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      {(errors && errors.templateSteps && errors.templateSteps[i] && errors.templateSteps[i].binActivity) && <Col xs={12}>
                        <Form.Control.Feedback className={'d-block'}  type="invalid">{errors.templateSteps[i].binActivity}</Form.Control.Feedback>
                      </Col>}
                    </Row>
                  </Col>
                </Row>

                <Row className='g-16 pt-0 px-0'>
                  <Col xs={12}><Divider /></Col>
                  <Col xs={12} className='px-24'>
                    <Button
                      className={'me-8'}
                      size={'sm'}
                      disabled={(item.binActivity === null)}
                      variant={'custom-text-primary'}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        dispatch(slice.setShow({ show: true, index: i }));
                      }}
                    >
                      <SlidersIcon /> Driver App Options
                    </Button>

                    {(values.templateSteps.length > 1) && <Button
                      size={'sm'}
                      variant={'custom-secondary-danger'}
                      disabled={(values.jobTemplateType === JobTemplateType.Default)}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        dispatch(confirmSlice.show(() => {
                          let items = _.cloneDeep(values.templateSteps);
                          items.forEach((itm: any, ind: number) => {
                            if(ind > i){
                              itm.binActivity = null;
                            }
                          });
                          items.splice(i, 1);
                          setFieldValue('templateSteps', items);
                          
                          dispatch(confirmSlice.setHide());
                          success('Deleted successfully', 'Step has been successfully deleted.');
                        }, {
                          title: 'Delete Step',
                          body: 'Are you sure to delete this step? This cannot be undone',
                          size: 'sm',
                          cancelText: 'Cancel',
                          cancelVariant: 'custom-outline',
                          confirmText: 'Delete',
                          confirmVariant: 'custom-danger',
                        }));
                      }}
                    >
                      <TrashIcon /> Delete Step
                    </Button>}

                  </Col>
                </Row>
              </StyledStep>

              {vehicleBinStatus && <StyledStepStatus xs={12}>
                <Row className='g-16'>
                  {vehicleBinStatus?.vehicleStatus && <Col xs={12} md={'auto'} className='gray-900'>Vehicle status: <StyledVehicleBinStatus className={vehicleBinStatus?.vehicleStatus || ''}>{vehicleBinStatus?.vehicleStatus}</StyledVehicleBinStatus></Col>}
                  {(vehicleBinStatus?.vehicleStatus && vehicleBinStatus?.binStatus) && <Col xs={12} md={'auto'}><Divider type="vertical" /></Col>}
                  {vehicleBinStatus?.binStatus && <Col xs={12} md={'auto'} className='gray-900'>Bin status: <StyledVehicleBinStatus className={vehicleBinStatus?.binStatus || ''}>{vehicleBinStatus?.binStatus}</StyledVehicleBinStatus></Col>}
                  {vehicleBinStatus?.info && <Col xs={12} md={true} className='gray-600 text-end'><StyledInfoIcon /><span>{vehicleBinStatus?.info}</span></Col>}
                </Row>
              </StyledStepStatus>}
            </React.Fragment>
          })}
        </Row>
      </Col>

      <Col xs={12}>
        <Button
          className={'add-step'}
          size={'sm'}
          disabled={values.templateSteps.some((x: any) => x.binActivity === null) || (values.jobTemplateType === JobTemplateType.Default)}
          variant={'custom-outlined'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            validateForm().then((err: any) => {
              if(!!err.steps){
                warning('Warning', 'You must fill in the required fields');
              } else {
                let items = _.cloneDeep(values.templateSteps);
                let newItem = _.cloneDeep(initStep);
                newItem.templateStepSites = defaultLocations;
                items.push(newItem);
                
                setFieldValue('templateSteps', items);
                scrollTo('.step-' + (items.length-1));
              }
            })
          }}
        >
          <PlusIcon /> Add Step
        </Button>
      </Col>

    </Row>
  }


  return <Row className='g-24 mb-100'>
    <Col xs={12}>
      <Row className='g-8'>
        <Col xs={12}>
          <span className="large medium gray-900">Job Steps</span>
        </Col>
        <Col xs={12}>
          <span className="gray-800">Determine how many steps for this job type, specific location on each step, and what the driver do in each location.</span>
        </Col>
      </Row>
    </Col>

    <Col xs={12} md={12} lg={10}>
      {setFormElements()}
    </Col>
  </Row>
}

export default Tab2;
