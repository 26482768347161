import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Card,
} from 'react-bootstrap';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import { Steps } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields } from '../slice.tsx';

// enums
// import {  } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';

import Map from './Map.tsx';

import { LocationEnum, getStepDescription } from '../../manage/jobs/jobTypes/slice.tsx';

// import { ReactComponent as XIcon } from "../../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------

const StyledCard: React.FC<React.ComponentProps<typeof Card>> = styled(Card)`
  padding: 8px 16px 16px 16px;
`;

const StyledList: React.FC<React.ComponentProps<typeof Col>> = styled(Col)`
  overflow: auto;
  min-height: 70px;

  ${up('lg')} {
    max-height: calc(100vh - var(--header-height, 64px) - var(--job-form-top-height, 72px) - var(--job-form-footer-height, 72px) - 375px);
  }

  ${down('lg')} {
    max-height: 100%;
  }


  .ant-steps {
    .ant-steps-item {
      .ant-steps-item-container {
        display: flex;
        align-items: center;

        .ant-steps-item-tail {
          top: 40%;
          padding: unset;

          &::after {
            background-color: transparent;
            background: transparent;
            border-left: 1px dashed var(--bs-gray-400);
            border-radius: 0px;
            left: -2px;
            top: 2px;
            position: relative;
          }
        }

        .ant-steps-item-icon {
          position: relative;
          margin-inline-end: 6px;

          .ant-steps-icon {
            position: relative;
            display: flex;
            width: 20px;
            height: 20px;

            span {
              position: absolute;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: var(--bs-gray-600);
              color: white;
              font-size: 12px;
              font-weight: 600;
              line-height: 18px;
              text-align: center;
            }
          }
        }
        
        .ant-steps-item-content {
          width: 100%;
          padding: 6px 12px 6px 12px;
          margin-bottom: 8px;
          border-radius: 5px;
          background-color: var(--bs-blue-focus1);

          .ant-steps-item-title {
            color: var(--bs-gray-700);;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;

            .location {
              color: var(--bs-gray-800);;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
`;


const JobRoutes = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { defaultLocations } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();


  return <Col xs={12}>
    <Row className='align-items-center'>
      <Col xs={12}>
        <StyledCard className='no-bg'>
          <Row className='g-10 align-items-center'>
            <Col xs={12}>
              <span className="fs-16 semibold">Job routes summary</span>
            </Col>
            
            <Col xs={12}>
              <Map />
            </Col>

            {(values.steps && values.steps.length > 0) && <StyledList xs={12}>
              <Steps
                current={0}
                status={'wait'}
                direction="vertical"
                size='small'
                items={(values.steps && values.steps.length > 0)
                  ?
                  values.steps.map((item: any, i: number) => {
                    let location = '';
                    if(item.locationType == LocationEnum.CustomerSite){
                      location = (item.customerSiteName && item.customerSiteName != '') ? item.customerSiteName : '';
                    } else {
                      if(item.hasDefaultSite){
                        let defLocation = (defaultLocations && defaultLocations.length > 0) ? defaultLocations.find((x: any) => x.defaultLocationId == item.defaultLocationId) : null;
                        location = (defLocation && defLocation.locationName && defLocation.locationName != '') ? defLocation.locationName : '';
                      } else {
                        location = '';
                      }
                    }

                    let activity: any = getStepDescription(i, item);

                    return {
                      title: <Row>
                        {
                          (location && location != '')
                          ?
                          <Col xs={12} className='location'>{location}</Col>
                          :
                          <Col xs={12} className='location text-danger'>Choose location</Col>
                        }
                        <Col xs={12}>{activity}</Col>
                        <Col xs={12}>BIN OUT: <span className='text-danger'>Fill Bin detail</span></Col>
                      </Row>,
                      icon: <span>{(i+1)}</span>
                    }
                  })
                  :
                  []
                }
              />
            </StyledList>}

          </Row>
        </StyledCard>
      </Col>
    </Row>
  </Col>
}

export default JobRoutes;
