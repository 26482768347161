import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Badge,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Divider, Tooltip } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields } from '../slice.tsx';

// enums
import { limitRows } from '../../../../v5/utils/utils.tsx';
import { XeroPaymentTermTypeItems } from '../../../../v5/utils/enums.tsx';

// components
import SmartSelect from '../../../../v5/components/select/SmartSelect.tsx';

import { ReactComponent as PlusIcon } from "../../../../v5/assets/icons/plus.svg";
import { ReactComponent as ClockIcon } from "../../../../v5/assets/icons/clock.svg";
import { ReactComponent as PrinterIcon } from "../../../../v5/assets/icons/printer.svg";
import { ReactComponent as RefreshIcon } from "../../../../v5/assets/icons/refresh-new.svg";

// ----------------------------------------------------------------------

const CustomerDetails = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { id } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();

  const getElements = () => {
    return <Row className='g-32'>
      <Col xs={12} md={6}>
        <Row className='g-16 align-items-center'>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>{fields.customerId.label}</Form.Label>
              <Form.Control
                as={SmartSelect}
                isInvalid={!!(errors && errors.customerId)}
                value={values.customerId}
                placeholder={fields.customerId.placeholder}

                addText={'Add new customer'}
                apiPath={'customer'}
                customParams={{
                  recentJob: true,
                }}
                fieldNames={{ value: 'customerId', label: 'customerName' }}
                selected={{ customerId: values.customerId, customerName: values.customerName }}

                optionRender={(option: any, ref: any) => {
                  let isNew = (option && option.data && ((option.data.isNew === false) || (option.data.isNew === true))) ? option.data.isNew : false;
                  let haveJobs = (option && option.data && ((option.data.haveJobs === false) || (option.data.haveJobs === true))) ? option.data.haveJobs : false;
                  
                  return <Row className={'align-items-center gx-2'}>
                    <Col xs={'auto'}>
                      {haveJobs && <ClockIcon />}
                      {isNew && <Badge style={{ lineHeight: '12px' }} pill bg={'primary'}>NEW</Badge>}
                    </Col>
                    <Col xs={'auto'}>{option?.label}</Col>
                  </Row>
                }}
                footerRender={(isLoading: boolean, ref: any) => {
                  return <Button
                    variant={'custom-text'}
                    size={'sm'}
                    disabled={isLoading}
                    onClick={(e) => { 
                      e.preventDefault();
                      e.stopPropagation();

                    }}
                  ><PlusIcon /> Add new customer</Button>
                }}

                hasOptionRender={true}
                hasFooterRender={true}
                searchable={true}
                allowClear={true}
                selectedIcon={false}
                disabled={false}
                virtual={true}

                onChangeValue={async (value: any, option: any, ref: any) => {
                  if(option){
                    await setFieldValue('customerId', value);
                    await setFieldValue('customerName', option.customerName);
                    await setFieldValue('customer', option);

                    dispatch(slice.callReadCheckCreditLimitApi(value, (state: boolean, data: any) => {}));
                  } else {
                    await setFieldValue('customerId', null);
                    await setFieldValue('customerName', '');
                    await setFieldValue('customer', null);
                    
                    dispatch(slice.resetCheckCreditLimit());
                  }
                }}
              />
              <Form.Control.Feedback type="invalid">{errors && errors.customerId as ReactNode}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Row className='g-8'>
                <Col xs={'auto'}>
                  <Form.Label>{fields.remarks.label}</Form.Label>
                </Col>

                {(id > 0) && <>
                  <Col xs={'auto'}>
                    <Button
                      variant={'custom-none-primary'}
                      onClick={(e) => { 
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <Tooltip title='Internal note'>
                        <PrinterIcon />
                      </Tooltip>
                    </Button>
                  </Col>
                  <Col xs={'auto'}>
                    <Button
                      variant={'custom-none-primary'}
                      onClick={(e) => { 
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <Tooltip title='Refresh job note'>
                        <RefreshIcon />
                      </Tooltip>
                    </Button>
                  </Col>
                </>}
                
              </Row>
              <Form.Control
                as="textarea"
                rows={fields.remarks.rows}
                autoComplete='off'
                placeholder={fields.remarks.placeholder}
                disabled={false}
                value={values.remarks}
                onChange={(e) => {
                  limitRows(e.target.value, fields.remarks.rows, async (value: any) => {
                    await setFieldValue('remarks', value);
                  });
                }}
                isInvalid={!!(errors && errors.remarks)}
              />
              <Form.Control.Feedback type="invalid">{errors && errors.remarks as ReactNode}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Col>

      <Col xs={12} md={6}>
        <Row className='g-16 align-items-center'>
          <Col xs={12}>
            <Form.Group>
              <Form.Label disabled={true}>{fields.customerNote.label}</Form.Label>
              <Form.Control
                type={'text'}
                autoComplete='off'
                placeholder={fields.customerNote.placeholder}
                disabled={true}
                value={values.customer?.note || ''}
                onChange={async (e) => {
                  
                }}
                isInvalid={false}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label disabled={true}>{fields.accountBillingProfileId.label}</Form.Label>
              <Form.Control
                type={'text'}
                autoComplete='off'
                placeholder={fields.accountBillingProfileId.placeholder}
                disabled={true}
                value={values.customer?.billingProfileName || ''}
                onChange={async (e) => {
                  
                }}
                isInvalid={false}
              />
            </Form.Group>
          </Col>
          
          {values.customer?.billingProfile?.isConnectQuickbook && <Col xs={12}>
            <Form.Group>
              <Form.Label disabled={true}>{fields.paymentTermsId.label}</Form.Label>
              <Form.Control
                type={'text'}
                autoComplete='off'
                placeholder={fields.paymentTermsId.placeholder}
                disabled={true}
                value={values.customer?.quickBookDefaultTermId || ''}
                onChange={async (e) => {
                  
                }}
                isInvalid={false}
              />
            </Form.Group>
          </Col>}

          {values.customer?.billingProfile?.isConnectXero && <Col xs={12}>
            <Form.Group>
              <Form.Label disabled={true}>{fields.xeroPaymentTerm.label}</Form.Label>
              <InputGroup>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  placeholder={fields.xeroPaymentTerm.placeholder}
                  disabled={true}
                  value={values.customer?.xeroPaymentTerm || ''}
                  onChange={async (e) => {
                    
                  }}
                  isInvalid={false}
                />
                <InputGroup.Text>{XeroPaymentTermTypeItems.find((x: any) => x.value == (values.customer?.xeroPaymentTermType || ''))?.title || ''}</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>}

        </Row>
      </Col>
    </Row>
  }

  return <Col xs={12} xxl={10}>
    <Row className='gy-16 align-items-center'>
      <Col xs={12}>
        <span className="large semibold gray-900">1. Customer Detail</span>
      </Col>

      <Col xs={12}>{getElements()}</Col>

      <Col xs={12}>
        <Divider />
      </Col>
    </Row>
  </Col>
}

export default CustomerDetails;
