import _ from 'lodash';
import moment from "moment";

import { dateTimeViewFormat, dateTimeFormat, dateTimeMeridianFormat, dateViewFormat, dateFormat, apiDateFormat, timeFormat, timeMeridianFormat, getTimeZone, isNumeric } from '../utils';

// Date ----------------------------------------------------------------------

export const createDateAsUTC = (date: any) => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}
export const convertDateToUTC = (date: any) => { 
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
}
export const getTimeZoneFromSettings = (date: any = null, offset: any = null) => {
  let timezoneUtcOffset = offset ? offset : getTimeZone();
  let newDate = convertDateToUTC(date ? date : new Date())
  if(isNumeric(timezoneUtcOffset)){
    return moment(newDate).add(timezoneUtcOffset, "minutes");
  } else {
    return moment(newDate).add(0, "minutes");
  }
}
export const getTodayDate = () => {
  try {
    return getTimeZoneFromSettings(new Date()).toDate();
  }catch(e){
    return undefined;
  }
}

export const getDateRanges = (exclude: any = ['Yesterday', 'Last 6 Months', 'This Year', 'Last Year']) => {
  let arr = [
    {
      label: 'Today',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().toDate(),
          endDate: moment().toDate(),
        }
      },
    },
    {
      label: 'Tomorrow',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().add(1, 'days').toDate(),
          endDate: moment().add(1, 'days').toDate(),
        }
      },
    },
    {
      label: 'Yesterday',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().subtract(1, 'days').toDate(),
          endDate: moment().subtract(1, 'days').toDate(),
        }
      },
    },
    {
      label: 'This Week',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().startOf('week').toDate(),
          endDate: moment().endOf('week').toDate(),
        }
      },
    },
    {
      label: 'Last Week',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().subtract(1, 'week').startOf('week').toDate(),
          endDate: moment().subtract(1, 'week').endOf('week').toDate(),
        }
      },
    },
    {
      label: 'This Month',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().startOf('month').toDate(),
          endDate: moment().endOf('month').toDate(),
        }
      },
    },
    {
      label: 'Last Month',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().subtract(1, 'month').startOf('month').toDate(),
          endDate: moment().subtract(1, 'month').endOf('month').toDate(),
        }
      },
    },
    {
      label: 'Last 6 Months',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().subtract(5, 'month').startOf('month').toDate(),
          endDate: moment().endOf('month').toDate(),
        }
      },
    },
    {
      label: 'This Year',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().startOf('year').toDate(),
          endDate: moment().endOf('year').toDate(),
        }
      },
    },
    {
      label: 'Last Year',
      hasCustomRendering: true,
      isSelected() {
        return false;
      },
      range: () => {
        return {
          startDate: moment().subtract(1, 'year').startOf('year').toDate(),
          endDate: moment().subtract(1, 'year').endOf('year').toDate(),
        }
      },
    },
  ]

  if (exclude && exclude.length > 0) {
    arr = arr.filter(x => !exclude.includes(x.label))
  }

  return arr;
}
export const getDefaultRangeDate = () => {
  let item: any = {
    startDate: null,
    endDate: null,
    key: 'selection'
  };

  try {
    item.startDate = moment().startOf('week').toDate();
    item.endDate = moment().endOf('week').toDate();
  }catch(e){
    item.startDate = null;
    item.endDate = null;
  }

  return item;
}
export const getSelectedRangeDate = (selected: any) => {
  if(selected && selected.length > 0){
    let selectedRange = selected[0];

    return { 
      fromDate: (selectedRange && selectedRange.startDate) ? moment(selectedRange.startDate).format(apiDateFormat()) : null,
      toDate: (selectedRange && selectedRange.endDate) ? moment(selectedRange.endDate).format(apiDateFormat()) : null,
    }
  }

  return null;
}

export const getTimeDiff = (firstDate: any, secondDate: any) => {
  try {
    return secondDate.diff(firstDate);
  }catch(e){
    return null;
  }
}

export const saveDate = (date: any) => {
  try {
    let value = (date && date !== '') ? date : '';
    let dateValue = createDateAsUTC(moment(value, dateFormat()).toDate());
    return (value !== '') ? dateValue : null;
  }catch(e){
    return null;
  }
}
export const prepareDate = (date: any) => {
  try {
    let value = (date && date !== '') ? date : '';
    let dateValue = moment(date).format(dateFormat());
    return (value !== '') ? dateValue : '';
  }catch(e){
    return '';
  }
}
export const viewDate = (date: any) => {
  try {
    let value = (date && date !== '') ? date : '';
    let dateValue = moment(date).format(dateViewFormat());
    return (value !== '') ? dateValue : '';
  }catch(e){
    return '';
  }
}
export const saveDateTime = (date: any) => {
  try {
    let value = (date && date !== '') ? date : '';
    let dateValue = createDateAsUTC(moment(value, dateTimeFormat()).toDate());
    return (value !== '') ? dateValue : null;
  }catch(e){
    return null;
  }
}
export const prepareDateTime = (date: any) => {
  try {
    let value = (date && date !== '') ? date : '';
    let dateValue = moment(date).format(dateTimeFormat());
    return (value !== '') ? dateValue : '';
  }catch(e){
    return '';
  }
}
export const viewDateTime = (date: any) => {
  try {
    let value = (date && date !== '') ? date : '';
    let dateValue = moment(date).format(dateTimeViewFormat());
    return (value !== '') ? dateValue : '';
  }catch(e){
    return '';
  }
}

export const concatDateTime = (dateVal: any, timeVal: any, isMeridian: boolean = true) => {
  try {
    let dateValue = (dateVal && dateVal != '') ? dateVal : moment().format(apiDateFormat());
    let timeValue = (timeVal && timeVal != '') ? timeVal : moment().format(isMeridian ? timeMeridianFormat() : timeFormat());
    let dateTimeObj = moment(dateValue + ' ' + timeValue, isMeridian ? dateTimeMeridianFormat() : dateTimeFormat());
    let dateTimeValue = createDateAsUTC(dateTimeObj.toDate())
    
    return dateTimeValue
  }catch(e){
    return null;
  }
}

export const isValidDate = (dateString: any, format: any = dateFormat()) => {
  try {
    return moment(dateString, format, true).isValid();
  }catch(e){
    return false;
  }
}
export const getDaysBetween = (startDate: any = null, endDate: any = null, period: any = 'days') => {
  let days = 0;

  try {
    var a = moment(startDate).startOf('day');
    var b = moment(endDate).startOf('day');
    days = a.diff(b, period);
  } catch (e) { }

  return days;
}
export const addDate = (days: any = 0, date: any = null, period: any = 'days') => {
  try {
    days = parseInt(days);
  } catch (e) { }

  if (date) {
    return moment(date).add(days, period);
  } else {
    return date;
  }
}
export const subtractDate = (days: any = 0, date: any = null, period: any = 'days') => {
  try {
    days = parseInt(days);
  } catch (e) { }

  if (date) {
    return moment(date).subtract(days, period);
  } else {
    return date;
  }
}