// Regex ----------------------------------------------------------------------

export const maxInputNumber012 = () => {
  return /^[012]$/g;
}

export const maxInputNumber12 = () => {
  return /^[12]$/g;
}

export const minInputNumber = (minNumb = 2) => {
  return new RegExp(`^$|^(${minNumb}-9|[1-9]\\d+)$`, 'g');
}

export const timeRegex = () => {
  return "^(00:|0[0-9]:|1[0-2]:|[1-9]:)([0-5][0-9]) ([a][m]|[A][M]|[p][m]|[P][M])$";
}