import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

// import { Divider } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
// import { useDispatch } from 'react-redux';
// import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
// import slice, { useFormikContext, fields, weekDayNumb, isWeekend } from '../slice.tsx';

// enums
// import {  } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';

// import { ReactComponent as XIcon } from "../../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------

const AuditTrail = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  // const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  // const { isLoading } = useTypedSelector((state: RootState) => state.jobFormSlice);
  // const dispatch = useDispatch<AppDispatch>();


  const getElements = () => {
    return <Row className='g-32 align-items-center'>
      <Col xs={12}>
        List
      </Col>
    </Row>
  }

  return <Col xs={12} xxl={10}>
    <Row className='gy-16 align-items-center'>
    <Col xs={12}>
        <span className="large semibold gray-900">9. Audit Trail</span>
      </Col>

      <Col xs={12}>{getElements()}</Col>

      {/* <Col xs={12}>
        <Divider />
      </Col> */}
    </Row>
  </Col>
}

export default AuditTrail;
