import React, { useState } from 'react';

import { Image, Upload } from 'antd';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';

import { ReactComponent as PhotoIcon } from "../../assets/icons/photo.svg";

import { warning, downloadImage } from '../../utils/utils';

// ----------------------------------------------------------------------


type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

function Wrap(props: any) {
  if(props.canCrop){
    return <ImgCrop
      rotationSlider
      aspectSlider
      showReset
    >{props.children}</ImgCrop>
  } else {
    return props.children;
  }
}

// ----------------------------------------------------------------------


export default function Dropzone(props: any) {
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);


  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <div><PhotoIcon /></div>
      {props.showAlowedExtensions && <small style={{ marginTop: 8 }}>Allowed file extensions are: <b>{props.acceptText}</b></small>}
    </button>
  );


  return <Wrap canCrop={props.canCrop}>
    <Upload
      listType="picture-card"
      onPreview={async (file: UploadFile) => {
        if (!file.url && !file.preview && !file.response) {
          file.preview = await getBase64(file.originFileObj as FileType);
        }

        let index = currentIndex;
        if(props.previewFileList){
          index = props.previewFileList.filter((x: any) => (x.url && x.url != '') || (x.preview && x.preview != '') || (x.response && x.response != '')).findIndex((x: any) => x.uid === file.uid)
        } else {
          index = props.files.filter((x: any) => (x.url && x.url != '') || (x.preview && x.preview != '') || (x.response && x.response != '')).findIndex((x: any) => x.uid === file.uid)
        }
        
        setCurrentIndex(index);
        setPreviewOpen(true);
      }}
      onDownload={downloadImage}
      showUploadList={{
        showDownloadIcon: props.showDownload,
      }}
      beforeUpload={(file: FileType) => {
        let maxSize = (props && props.maxSize) ? props.maxSize : null;
        if(maxSize){
          const isSizeOk = file.size / 1024 / 1024 < maxSize;

          if (!isSizeOk) {
            warning('Warning', 'File must smaller than ' + maxSize + 'MB!');

            if(props.onError){
              props.onError(file);
            }
            return Upload.LIST_IGNORE;
          }
        }

        return true;
      }}
      onRemove={(file) => {
        setIsLoadingRemove(true);

        const updatedFileList = props.files.filter((item: UploadFile) => item.uid !== file.uid);

        if (props.onRemoveFile) {
          props.onRemoveFile({ fileList: updatedFileList });
        }

        setTimeout(() => {
          setIsLoadingRemove(false);
        }, 500);

        return false;
      }}
      fileList={props.files || []}
      {...props}
    >
      {
        isLoadingRemove
        ?
        null
        :
        (props.files && props.files != undefined)
        ?
          (props.maxCount === null)
          ?
            uploadButton
          :
            (props.maxCount > 1)
            ?
              (props.files.length < props.maxCount)
              ?
              uploadButton
              :
              null
            :
              (props.files.length === 0)
              ?
              uploadButton
              :
              null
        :
        null
      }
    </Upload>
    
    <Image.PreviewGroup
      items={props.previewFileList
        ?
        props.previewFileList.filter((x: any) => (x.url && x.url != '') || (x.preview && x.preview != '') || (x.response && x.response != '')).map((x: any) => {
          if(x.url){
            return x.url;
          } else if((x.preview as string)){
            return x.preview;
          } else if((x.response as string)){
            return x.response;
          }
        })
        :
        props.files.filter((x: any) => (x.url && x.url != '') || (x.preview && x.preview != '') || (x.response && x.response != '')).map((x: any) => {
          if(x.url){
            return x.url;
          } else if((x.preview as string)){
            return x.preview;
          } else if((x.response as string)){
            return x.response;
          }
        })
      }
      preview={{
        toolbarRender: (originalNode: any, info: any) => null,
        visible: previewOpen,
        current: currentIndex,
        onVisibleChange: (visible) => setPreviewOpen(visible),
        onChange: (current, prev) => setCurrentIndex(current),
      }}
    />
  </Wrap>
}
