import _ from 'lodash';
import moment from "moment";
import { parseISO, getUnixTime, format } from 'date-fns'

import { axios, axiosAuth } from '../axios.jsx';

import { LOGIN_PATH, STORAGE_KEY, STORAGE_EXPIRES_AT, STORAGE_USER, STORAGE_USER_ACCOUNT, STORAGE_USER_COOKIES, AccountSettingType } from '../enums';
import { DEFAULT_ZOOM, DEFAULT_CENTER } from '../../components/googleMap/GoogleMap.jsx';

import { isNumeric } from '../utils';

const { REACT_APP_IS_PRODUCTION } = process.env;

// Auth ----------------------------------------------------------------------

export const isProduction = () => {
  return (REACT_APP_IS_PRODUCTION == 'true') ? true : false;
};

export const isValidToken = (accessToken: any, token_expires_at: any) => {
  if (!accessToken) {
    return false;
  }

  const exp = getUnixTimestamp(token_expires_at);
  const currentTime = getUnixTimestamp(moment().toISOString());
  const isValid = (exp > currentTime);
  
  return isValid;
};

export const tokenExpired = (exp: any) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    console.info('Token expired');

    localStorage.removeItem(STORAGE_KEY);

    window.location.href = LOGIN_PATH;
  }, timeLeft);
};

export const setSession = (accessToken: any, token_expires_at: any, user: any, shouldSetUserData: boolean) => {
  // if (accessToken) {
  //   localStorage.setItem(STORAGE_KEY, accessToken);
  //   localStorage.setItem(STORAGE_EXPIRES_AT, token_expires_at);

  //   const exp = getUnixTimestamp(token_expires_at);

  //   tokenExpired(exp);

  //   if(shouldSetUserData){
  //     let userAccount = (user && user.userAccount) ? user.userAccount : null;
  //     localStorage.setItem(STORAGE_USER, JSON.stringify(user));
  //     localStorage.setItem(STORAGE_USER_ACCOUNT, JSON.stringify(userAccount));
  //   }
  // } else {
  //   localStorage.removeItem(STORAGE_KEY);
  //   localStorage.removeItem(STORAGE_EXPIRES_AT);
  //   localStorage.removeItem(STORAGE_USER);
  //   localStorage.removeItem(STORAGE_USER_ACCOUNT);
  //   localStorage.removeItem(STORAGE_USER_COOKIES);
  //   localStorage.clear();

  //   delete axiosAuth.defaults.headers.common['Accept-Language'];
  //   delete axiosAuth.defaults.headers.common.Authorization;

  //   delete axios.defaults.headers.common['Accept-Language'];
  //   delete axios.defaults.headers.common.Authorization;
  // }
};

export const getUnixTimestamp = (token_expires_at: any) => {
  const parsedDatetime = parseISO(token_expires_at);
  return getUnixTime(parsedDatetime);
};

export const getDateFromTimestamp = (ts: any) => {
  const date = new Date(ts * 1000);
  return format(date, 'yyyy-MM-dd HH:mm:ss');
};



export const getAuth = () => {
  let obj = null;

  let storage = localStorage.getItem("persist:auth");
  if (storage && storage !== '') {
    try {
      obj = JSON.parse(storage);
    } catch (e) { }
  }

  return obj;
}
export const getAccessToken = () => {
  let token = null;

  let auth = getAuth();
  if (auth && auth !== '') {
    try {
      token = (auth && auth.token && auth.token != '') ? auth.token.replaceAll('\"', '') : null;
    } catch (e) { }
  }

  return token;
};
export const getDispatchUser = () => {
  let dispatchUser = null;

  let auth = getAuth();
  if (auth && auth.dispatchUser && auth.dispatchUser !== '') {
    try {
      dispatchUser = JSON.parse(auth.dispatchUser);
    } catch (e) {
      dispatchUser = null;
    }
  }

  return dispatchUser;
};
export const getUser = () => {
  let user = null;

  let auth = getAuth();
  if (auth && auth.user && auth.user !== '') {
    try {
      user = JSON.parse(auth.user);
    } catch (e) {
      user = null;
    }
  }

  return user;
};
export const getUserAccount = () => {
  let userAccount = null;

  let user = getUser();
  if (user && user.userAccount) {
    userAccount = user.userAccount;
  }

  return userAccount;
};
export const getUserCookies = () => {
  try {
    const userCookieStr: any = localStorage.getItem(STORAGE_USER_COOKIES);
    const userCookie = JSON.parse(userCookieStr);
    return userCookie;
  } catch(e){
    return null;
  }
};
export const getTimeZone = () => {
  try {
    let userAccount: any = getUserAccount();
    let timezoneUtcOffset = (userAccount && isNumeric(userAccount.timezoneUtcOffset)) ? userAccount.timezoneUtcOffset : 0;
    return timezoneUtcOffset;
  } catch(e){
    return 0;
  }
};
export const getCurrency = () => {
  let currencyCode = '';

  try {
    let userAccount: any = getUserAccount();
    let accountSettings = (userAccount && userAccount.accountSettings && userAccount.accountSettings.length > 0) ? userAccount.accountSettings : [];

    if (accountSettings && accountSettings.length > 0) {
      let currencyCodeIndex = accountSettings.findIndex((x: any) => x.type == AccountSettingType.CurrencyCode);
      if (currencyCodeIndex > -1) {
        currencyCode = accountSettings[currencyCodeIndex].value;
      }
    }
  } catch(e){}

  return currencyCode;
};

export const getPermissions = () => {
  try {
    let user: any = getUser();
    return (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];
  } catch(e){
    return [];
  }
};
export const hasPermission = (permission: any = null) => {
  try {
    if(permission === null){
      return true;
    }
    
    const permissions = getPermissions();
    return (permissions.findIndex((x: any) => permission.includes(x.permissionId)) > -1);
  } catch(e){
    return false;
  }
};

export const getAccountSettings = () => {
  try {
    const userAccountStr: any = localStorage.getItem(STORAGE_USER_ACCOUNT);
    const userAccount = JSON.parse(userAccountStr);

    return (userAccount && userAccount.accountSettings && userAccount.accountSettings.length > 0) ? userAccount.accountSettings : [];
  } catch(e){
    return [];
  }
};
export const getDefLatLngZoom = () => {
  try {
    const accountSettings = getAccountSettings();
    
    let default_latitude = DEFAULT_CENTER.lat;
    if(accountSettings && accountSettings.length > 0){
      let item = accountSettings.find((x: any) => x.type === AccountSettingType.DefaultMapPositionLatitude);
      if(item && item.value && item.value != ''){
        default_latitude = parseFloat(item.value);
      }
    }

    let default_longitude = DEFAULT_CENTER.lng;
    if(accountSettings && accountSettings.length > 0){
      let item = accountSettings.find((x: any) => x.type === AccountSettingType.DefaultMapPositionLongitude);
      if(item && item.value && item.value != ''){
        default_longitude = parseFloat(item.value);
      }
    }

    let default_zoom = DEFAULT_ZOOM;
    if(accountSettings && accountSettings.length > 0){
      let item = accountSettings.find((x: any) => x.type === AccountSettingType.DefaultMapPositionZoom);
      if(item && item.value && item.value != ''){
        default_zoom = parseFloat(item.value);
      }
    }

    let default_center = { lat: default_latitude, lng: default_longitude };

    return {
      lat: default_latitude,
      lng: default_longitude,
      coord: default_center,
      zoom: default_zoom,
    };
  } catch(e){
    return {
      lat: DEFAULT_CENTER.lng,
      lng: DEFAULT_CENTER.lng,
      coord: DEFAULT_CENTER,
      zoom: DEFAULT_ZOOM,
    };
  }
};

export const getAccountSettingsV4 = () => {
  let accountSettings = [];

  let storage = localStorage.getItem("persist:auth");
  if (storage && storage !== '') {
    try {
      let obj = JSON.parse(storage);
      let dispatchUser = (obj && obj.dispatchUser) ? obj.dispatchUser : null;
      let settings = JSON.parse(dispatchUser);
      accountSettings = (settings && settings.accountSettings && settings.accountSettings.length > 0) ? settings.accountSettings : [];
    } catch (e) { }
  }

  return accountSettings;
};
export const getDefLatLngZoomV4 = () => {
  try {
    const accountSettings = getAccountSettingsV4();
    
    let default_latitude = DEFAULT_CENTER.lat;
    if(accountSettings && accountSettings.length > 0){
      let item = accountSettings.find((x: any) => x.type === AccountSettingType.DefaultMapPositionLatitude);
      if(item && item.value && item.value != ''){
        default_latitude = parseFloat(item.value);
      }
    }

    let default_longitude = DEFAULT_CENTER.lng;
    if(accountSettings && accountSettings.length > 0){
      let item = accountSettings.find((x: any) => x.type === AccountSettingType.DefaultMapPositionLongitude);
      if(item && item.value && item.value != ''){
        default_longitude = parseFloat(item.value);
      }
    }

    let default_zoom = DEFAULT_ZOOM;
    if(accountSettings && accountSettings.length > 0){
      let item = accountSettings.find((x: any) => x.type === AccountSettingType.DefaultMapPositionZoom);
      if(item && item.value && item.value != ''){
        default_zoom = parseFloat(item.value);
      }
    }

    let default_center = { lat: default_latitude, lng: default_longitude };

    return {
      lat: default_latitude,
      lng: default_longitude,
      coord: default_center,
      zoom: default_zoom,
    };
  } catch(e){
    return {
      lat: DEFAULT_CENTER.lng,
      lng: DEFAULT_CENTER.lng,
      coord: DEFAULT_CENTER,
      zoom: DEFAULT_ZOOM,
    };
  }
};