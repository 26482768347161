import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Switch, Divider } from "antd";
import CurrencyInput from 'react-currency-input-field';

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields } from '../slice.tsx';

// enums
// import {  } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

// components
import Select from '../../../../v5/components/select/Select.tsx';

// import { ReactComponent as XIcon } from "../../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------

const Payment = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { jobTemplate } = useTypedSelector((state: RootState) => state.jobFormSlice);
  // const dispatch = useDispatch<AppDispatch>();


  const getElements = () => {
    return <Row className='g-32 align-items-center'>
      <Col xs={12} md={6}>
        <Form.Group as={Row} className='checkbox-field align-items-center w-100 g-0'>
          <Form.Label column xs={true} className='m-0'>Collect Payment</Form.Label>
          <Col xs={'auto'}>
            <Switch
              checked={values.isRequirePaymentCollection}
              onChange={(checked) => {
                setFieldValue('isRequirePaymentCollection', checked);
              }}
            />
          </Col>
        </Form.Group>
        
        {values.isRequirePaymentCollection && <Row className='align-items-center w-100 g-0 py-16'>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>{fields.amountToCollect.label}</Form.Label>
              <Form.Control
                as={CurrencyInput}
                allowDecimals={true}
                allowNegativeValue={false}
                prefix={'$'}
                decimalsLimit={2}

                autoComplete='off'
                placeholder={fields.amountToCollect.placeholder}
                value={values.creditLimit}
                onValueChange={(value: any) => {
                  setFieldValue('amountToCollect', value);
                }}
                isInvalid={!!(errors && errors.amountToCollect)}
              />
              <Form.Control.Feedback type="invalid">{errors && errors.amountToCollect as ReactNode}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} className='mt-10'>
            <Form.Group>
              <Form.Label>{fields.collectedAmount.label}</Form.Label>
              <Form.Control
                as={CurrencyInput}
                allowDecimals={true}
                allowNegativeValue={false}
                prefix={'$'}
                decimalsLimit={2}

                autoComplete='off'
                placeholder={fields.collectedAmount.placeholder}
                value={values.creditLimit}
                onValueChange={(value: any) => {
                  setFieldValue('collectedAmount', value);
                }}
                isInvalid={!!(errors && errors.collectedAmount)}
              />
              <Form.Control.Feedback type="invalid">{errors && errors.collectedAmount as ReactNode}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} className='mt-10'>
            <Form.Group>
              <Form.Label>{fields.jobPaymentType.label}</Form.Label>
              <Form.Control
                as={Select}
                value={values.jobPaymentType}
                placeholder={'Select'}
                searchable={false}
                allowClear={true}
                selectedIcon={true}
                loading={false}
                onChangeValue={(value: any, option: any, ref: any) => {
                  setFieldValue('jobPaymentType', value);
                }}
                options={jobTemplate?.jobPaymentTypeList.map((item: any) => {
                  return { value: item, label: item }
                })}
                isInvalid={!!(errors && errors.jobPaymentType)}
              />
              <Form.Control.Feedback type="invalid">{errors && errors.jobPaymentType as ReactNode}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>}
      </Col>
    </Row>
  }


  return <Col xs={12} xxl={10}>
    <Row className='gy-16 align-items-center'>
      <Col xs={12}>
        <span className="large semibold gray-900">6. Payment</span>
      </Col>

      <Col xs={12}>{getElements()}</Col>
      
      <Col xs={12}>
        <Divider />
      </Col>
    </Row>
  </Col>
}

export default Payment;
