import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Checkbox, Divider } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields } from '../slice.tsx';
import confirmSlice from '../../../components/modal/confirmSlice.tsx';
import printPreviewSlice from './printPreview/slice.tsx';
import recurringJobSlice from './recurringJob/slice.tsx';
import billingJobSlice from './billingJob/slice.tsx';
import weightChitSlice from './weightChit/slice.tsx';

// enums
// import {  } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';

// import { ReactComponent as XIcon } from "../../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------

const StyledDivider: React.FC<React.ComponentProps<typeof Divider>> = styled(Divider)`
  margin: 0px 0px 0px 7px;
  background-color: var(--bs-gray-800);
`;


const Footer = () => {
  // const location = useLocation();
  const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  // const { isLoading } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const confirmSliceState = useTypedSelector((state: RootState) => state.confirmSlice);
  const dispatch = useDispatch<AppDispatch>();

  const inputRef = React.useRef<any>(null);


  return <>
    <Row className='align-items-center justify-content-center g-8'>
      <Col xs={true} className='d-none d-md-block'></Col>
      <Col xs={12} md={'auto'} className='text-center text-sm-end'>
        <Checkbox
          checked={false}
          onChange={(e: any) => {
            
          }}
        >Do you want to resend DO to suctomer?</Checkbox>
      </Col>

      <Col xs={true} className='d-none d-sm-block d-md-none'></Col>
      <Col xs={'auto'}>
        <DropdownButton
          variant={'custom-outlined'}
          size={'sm'}
          title={<>Options <StyledDivider type='vertical' /></>}
        >
          <Dropdown.Item
            active={false}
            disabled={false}
            onClick={() => {
              dispatch(printPreviewSlice.setShow(true));
            }}
          >Print Preview</Dropdown.Item>
          <Dropdown.Item
            active={false}
            disabled={false}
            onClick={() => {
              dispatch(recurringJobSlice.setShow(true));
            }}
          >Make Recurring</Dropdown.Item>
          <Dropdown.Item
            active={false}
            disabled={false}
            onClick={() => {
              dispatch(billingJobSlice.setShow(true));
            }}
          >Bill Job</Dropdown.Item>
          <Dropdown.Item
            active={false}
            disabled={false}
            onClick={() => {
              dispatch(weightChitSlice.setShow(true));
            }}
          >Weight Chit</Dropdown.Item>
          <Dropdown.Item
            active={false}
            disabled={false}
            onClick={() => {
              router.push('/v5/job-form-new?id=0&isCopy=true');
            }}
          >Copy Job</Dropdown.Item>
          <Dropdown.Item
            className={'text-danger'}
            active={false}
            disabled={false}
            onClick={() => {
              dispatch(confirmSlice.show(() => {
                dispatch(confirmSlice.setHide());
              }, {
                title: 'Delete job?',
                body: 'This will permanently remove job. This action cannot be undone. Are you sure want to delete this job?',
                size: 'md',
                cancelText: 'Cancel',
                cancelVariant: 'custom-outlined',
                confirmText: 'Yes, please remove',
                confirmVariant: 'custom-danger',
              }));
            }}
          >Delete Job</Dropdown.Item>
        </DropdownButton>
      </Col>

      <Col xs={'auto'}>
        <Dropdown as={ButtonGroup} align="end">
          <Button 
            variant={'custom-primary'}
            size={'sm'}
            onClick={() => {
              dispatch(confirmSlice.show(() => {
                dispatch(confirmSlice.setHide());

                dispatch(confirmSlice.show(null, {
                  title: 'Save as New Template',
                  body: <>
                    <Form.Group>
                      <Form.Control
                        ref={inputRef}
                        type={'text'}
                        autoComplete='off'
                        autoFocus
                        placeholder={'Enter the template name'}
                        disabled={false}
                      />
                      <Form.Control.Feedback type="invalid">You must enter a template name</Form.Control.Feedback>
                    </Form.Group>
                  </>,
                  size: 'md',
                  cancelText: 'Cancel',
                  cancelVariant: 'custom-outlined',
                  cancelCallback: () => {
                    console.log("Cancel BUT OK");
                  },
                  confirmText: 'Confirm Save',
                  confirmVariant: 'custom-primary',
                  confirmCallback: () => {
                    if(inputRef && inputRef.current){
                      if(inputRef.current.value !== ''){
                        inputRef.current.classList.remove("is-invalid");
                        console.log("Confirm BUT OK", inputRef.current.value);
                        dispatch(confirmSlice.setHide());
                      } else {
                        inputRef.current.classList.add("is-invalid");
                      }
                    }
                  },
                }));
              }, {
                title: 'Custom Job Detected',
                body: 'Do you want save it as a new Template?',
                size: 'md',
                cancelText: 'No, thanks',
                cancelVariant: 'custom-outlined',
                cancelCallback: () => {
                  console.log("Cancel BUT OK");
                },
                confirmText: 'Save Template',
                confirmVariant: 'custom-primary',
              }));
            }}
          >Dispatch Now</Button>

          <Dropdown.Toggle 
            variant={'custom-primary'}
            size={'sm'}
            split
          />

          <Dropdown.Menu>
            <Dropdown.Item
              active={false}
              disabled={false}
              onClick={() => {
                
              }}
            >Mark as Draft</Dropdown.Item>
            <Dropdown.Item
              active={false}
              disabled={false}
              onClick={() => {
                
              }}
            >Mark as Completed</Dropdown.Item>
            <Dropdown.Item
              active={false}
              disabled={false}
              onClick={() => {
                
              }}
            >Mark as Cancelled</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  </>
}

export default Footer;
