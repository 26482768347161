import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Divider } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields, findStatus, formatCustomerSiteAddress, concatStringRemarks } from '../slice.tsx';
import jobStepsSlice from './jobSteps/slice.tsx';

// enums
import { limitRows, getMaxPageSize } from '../../../../v5/utils/utils.tsx';
import { CustomerSiteAvailability } from '../../../../v5/utils/enums.tsx';

import { LocationEnum } from '../../manage/jobs/jobTypes/slice.tsx';

// components
import SmartSelect from '../../../../v5/components/select/SmartSelect.tsx';

import { ReactComponent as EditIcon } from "../../../../v5/assets/icons/edit-normal.svg";
import { ReactComponent as PlusIcon } from "../../../../v5/assets/icons/plus.svg";

// ----------------------------------------------------------------------

const Location = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { jobTemplate } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();


  const getElements = () => {
    let count = 0;
    return <>

      {(values.steps && values.steps.length > 0) && <>
        {values.steps.map((item: any, i: number) => {
          if(item.locationType == LocationEnum.CustomerSite){
            count = count + 1;

            return <Col key={'template_steps_' + i} xs={12}>
              <Row className='gx-32 align-items-center'>
                <Col xs={12} className='semibold gray-600 mb-12'>New Location (Step {(i+1)})</Col>
      
                <Col xs={12} md={6}>
                  <Row className='g-16 align-items-center'>
                    <Col xs={12}>
                      <Form.Group>
                        <Form.Label disabled={!(values.customerId)}>{fields.customerSiteId.label}</Form.Label>
                        <Form.Control
                          as={SmartSelect}
                          isInvalid={!!(errors && errors.steps && errors.steps.length > 0 && errors.steps[i] && typeof errors.steps[i] === 'object' && errors.steps[i].customerSiteId)}
                          value={item.customerSiteId}
                          placeholder={fields.customerSiteId.placeholder}
      
                          addText={'Add new customer site'}
                          apiPath={'customerSite'}
                          customParams={{
                            currentPage: 1,
                            pageSize: getMaxPageSize(),
                            customerId: values?.customerId,
                            showRecentJob: true,
                          }}
                          fieldNames={{ value: 'customerSiteId', label: 'siteNameDisplay' }}
                          selected={{ customerSiteId: item.customerSiteId, siteNameDisplay: item.customerSiteName }}
      
                          createResponseFunc={(options: any) => {
                            return (options) ? options : null;
                          }}
                          onCreateFunc={(option: any) => {
                            if(option){
                              setFieldValue('steps.' + i + '.customerSiteId', option.customerSiteId);
                              setFieldValue('steps.' + i + '.customerSiteName', option.customerSiteName);
                            }
                          }}
                          updateResponseFunc={(options: any) => {
                            return (options) ? options : null;
                          }}
                          onUpdateFunc={(option: any) => {
                            if(option && values.customerSiteId === option.customerSiteId){
                              setFieldValue('steps.' + i + '.customerSiteId', option.customerSiteId);
                              setFieldValue('steps.' + i + '.customerSiteName', option.customerSiteName);
                            }
                          }}
      
                          footerRender={(isLoading: boolean, ref: any) => {
                            return <Button
                              variant={'custom-text'}
                              size={'sm'}
                              disabled={isLoading}
                              onClick={(e) => { 
                                e.preventDefault();
                                e.stopPropagation();
      
                              }}
                            ><PlusIcon /> Add new customer site</Button>
                          }}
      
                          hasOptionRender={false}
                          hasFooterRender={true}
                          searchable={true}
                          allowClear={true}
                          selectedIcon={false}
                          disabled={!(values.customerId)}
                          virtual={true}
      
                          onChangeValue={async (value: any, option: any, ref: any) => {
                            if(option){
                              await setFieldValue('steps.' + i + '.customerSiteId', value);
                              await setFieldValue('steps.' + i + '.customerSiteName', option.siteNameDisplay);
                              await setFieldValue('steps.' + i + '.customerSite', option);

                              if(option){
                                let remarks = (option && option.remarks && option.remarks !== '') ? option.remarks : '';
                                let instructions = (option && option.instructions && option.instructions !== '') ? option.instructions : '';
                                let contactPersonName = (option && option.contactPersonName && option.contactPersonName !== '') ? option.contactPersonName : '';
                                let contactPersonPhoneNumber = (option && option.contactPersonPhoneNumber && option.contactPersonPhoneNumber !== '') ? option.contactPersonPhoneNumber : '';
                                let contactPersonNameTwo = (option && option.contactPersonNameTwo && option.contactPersonNameTwo !== '') ? option.contactPersonNameTwo : '';
                                let contactPersonPhoneNumberTwo = (option && option.contactPersonPhoneNumberTwo && option.contactPersonPhoneNumberTwo !== '') ? option.contactPersonPhoneNumberTwo : '';
                                
                                let isContactPersonSendNotification = ((option.isContactPersonSendNotification === false) || (option.isContactPersonSendNotification === true)) ? option.isContactPersonSendNotification : false;
                                let isContactPersonTwoSendNotification = ((option.isContactPersonTwoSendNotification === false) || (option.isContactPersonTwoSendNotification === true)) ? option.isContactPersonTwoSendNotification : false;
                        
                                let copySiteRemarksToJob = (option && ((option.copySiteRemarksToJob == false) || (option.copySiteRemarksToJob == true))) ? option.copySiteRemarksToJob : false;
                                
                                let weekdaysAvailability = (option && option.weekdaysAvailability) ? option.weekdaysAvailability : CustomerSiteAvailability.All;
                                let weekendAvailability = (option && option.weekendAvailability) ? option.weekendAvailability : CustomerSiteAvailability.All;
                                
                                let defaultDrivers = (option.defaultDrivers && option.defaultDrivers.length > 0) ? option.defaultDrivers : [];
                                if(defaultDrivers && defaultDrivers.length > 0){
                                  let defArr = defaultDrivers.filter((x: any) => x.defaultVehicle !== null);
                                  if(defArr && defArr.length > 0){
                                    let driverItem = defArr[0];
                                    let driverId = (driverItem.driverId && driverItem.driverId > 0) ? driverItem.driverId : null;
                                    let driverName = (driverItem.driverName && driverItem.driverName !== '') ? driverItem.driverName : '';
                                    
                                    if(values.driverId === null || values.driverId <= 0){
                                      await setFieldValue('driverId', driverId);
                                      await setFieldValue('driverName', driverName);
                                    }

                                    let vehicleItem = defArr[0].defaultVehicle;
                                    let vehicleId = (vehicleItem.vehicleId && vehicleItem.vehicleId > 0) ? vehicleItem.vehicleId : null;
                                    let vehicleName = (vehicleItem.vehicleName && vehicleItem.vehicleName !== '') ? vehicleItem.vehicleName : '';
                                    
                                    if(values.vehicleId === null || values.vehicleId <= 0){
                                      await setFieldValue('vehicleId', vehicleId);
                                      await setFieldValue('vehicleName', vehicleName);


                                      let selectedStatus = findStatus(jobTemplate?.templateStatuses, 'unassigned');
                                      if (values.statusId === null || values.statusId === 0 || values.statusId === selectedStatus.jobStatusId) {
                                        let newStatus = findStatus(jobTemplate?.templateStatuses, 'assigned');
                                        let newStatusId = (newStatus.jobStatusId) ? newStatus.jobStatusId : null;
                                        let newStatusName = (newStatus.jobStatusName) ? newStatus.jobStatusName : '';
                                        
                                        await setFieldValue('statusId', newStatusId);
                                        await setFieldValue('statusName', newStatusName);
                                      }
                                    }
                                  }
                                }

                                let prevRemarks = (values.customerSite && values.customerSite.remarks && values.customerSite.remarks != '') ? values.customerSite.remarks : '';
                                let newRemarks = concatStringRemarks(values.remarks, remarks, prevRemarks, copySiteRemarksToJob);
                                await setFieldValue('remarks', newRemarks);
                                
                                await setFieldValue('steps.' + i + '.siteRemarks', instructions);
                                await setFieldValue('steps.' + i + '.contactPersonOne', contactPersonName);
                                await setFieldValue('steps.' + i + '.contactNumberOne', contactPersonPhoneNumber);
                                await setFieldValue('steps.' + i + '.contactPersonTwo', contactPersonNameTwo);
                                await setFieldValue('steps.' + i + '.contactNumberTwo', contactPersonPhoneNumberTwo);
                                await setFieldValue('steps.' + i + '.isContactPersonSendNotification', isContactPersonSendNotification);
                                await setFieldValue('steps.' + i + '.isContactPersonTwoSendNotification', isContactPersonTwoSendNotification);
                                await setFieldValue('steps.' + i + '.customFormParams.weekdaysAvailability', weekdaysAvailability);
                                await setFieldValue('steps.' + i + '.customFormParams.weekendAvailability', weekendAvailability);
                                await setFieldValue('steps.' + i + '.customerSiteAddress', formatCustomerSiteAddress(option));
                              }
                            } else {
                              await setFieldValue('steps.' + i + '.customerSiteId', null);
                              await setFieldValue('steps.' + i + '.customerSiteName', '');
                              await setFieldValue('steps.' + i + '.customerSite', null);
                            }
                          }}
                        />
                        <Form.Control.Feedback type="invalid">{(errors && errors.steps && errors.steps[i] && errors.steps[i].customerSiteId) && errors.steps[i].customerSiteId}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group>
                        <Form.Label disabled={false}>{fields.siteRemarks.label}</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={fields.siteRemarks.rows}
                          autoComplete='off'
                          placeholder={fields.siteRemarks.placeholder}
                          disabled={false}
                          value={item.siteRemarks}
                          onChange={(e) => {
                            limitRows(e.target.value, fields.siteRemarks.rows, async (value: any) => {
                              setFieldValue('steps.' + i + '.siteRemarks', value);
                            });
                          }}
                          isInvalid={!!(errors && errors.steps && errors.steps.length > 0 && errors.steps[i] && typeof errors.steps[i] === 'object' && errors.steps[i].siteRemarks)}
                        />
                        <Form.Control.Feedback type="invalid">{(errors && errors.steps && errors.steps[i] && errors.steps[i].siteRemarks) && errors.steps[i].siteRemarks}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
      
                <Col xs={12} md={6}>
                  <Row className='g-16 align-items-center'>
                    <Col xs={12}>
                      <Form.Label disabled={false}>{fields.contactPersonOne.label}</Form.Label>
                      <Row className='g-8 align-items-center'>
                        <Col xs={6} sm={4} xl={6} xxl={4}>
                          <Form.Group>
                            <Form.Control
                              type={'text'}
                              autoComplete='off'
                              placeholder={fields.contactPersonOne.placeholder}
                              disabled={false}
                              value={item.contactPersonOne}
                              onChange={async (e) => {
                                setFieldValue('steps.' + i + '.contactPersonOne', e.target.value);
                              }}
                              isInvalid={!!(errors && errors.steps && errors.steps.length > 0 && errors.steps[i] && typeof errors.steps[i] === 'object' && errors.steps[i].contactPersonOne)}
                            />
                            <Form.Control.Feedback type="invalid">{(errors && errors.steps && errors.steps[i] && errors.steps[i].contactPersonOne) && errors.steps[i].contactPersonOne}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={6} sm={8} xl={6} xxl={8}>
                          <Form.Group>
                            {/* <Form.Label disabled={false}>{fields.contactNumberOne.label}</Form.Label> */}
                            <Form.Control
                              type={'text'}
                              autoComplete='off'
                              placeholder={fields.contactNumberOne.placeholder}
                              disabled={false}
                              value={item.contactNumberOne}
                              onChange={async (e) => {
                                setFieldValue('steps.' + i + '.contactNumberOne', e.target.value);
                              }}
                              isInvalid={!!(errors && errors.steps && errors.steps.length > 0 && errors.steps[i] && typeof errors.steps[i] === 'object' && errors.steps[i].contactNumberOne)}
                            />
                            <Form.Control.Feedback type="invalid">{(errors && errors.steps && errors.steps[i] && errors.steps[i].contactNumberOne) && errors.steps[i].contactNumberOne}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Form.Label disabled={false}>{fields.contactPersonTwo.label}</Form.Label>
                      <Row className='g-8 align-items-center'>
                        <Col xs={6} sm={4} xl={6} xxl={4}>
                          <Form.Group>
                            <Form.Control
                              type={'text'}
                              autoComplete='off'
                              placeholder={fields.contactPersonTwo.placeholder}
                              disabled={false}
                              value={item.contactPersonTwo}
                              onChange={async (e) => {
                                setFieldValue('steps.' + i + '.contactPersonTwo', e.target.value);
                              }}
                              isInvalid={!!(errors && errors.steps && errors.steps.length > 0 && errors.steps[i] && typeof errors.steps[i] === 'object' && errors.steps[i].contactPersonTwo)}
                            />
                            <Form.Control.Feedback type="invalid">{(errors && errors.steps && errors.steps[i] && errors.steps[i].contactPersonTwo) && errors.steps[i].contactPersonTwo}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={6} sm={8} xl={6} xxl={8}>
                          <Form.Group>
                            {/* <Form.Label disabled={false}>{fields.contactNumberTwo.label}</Form.Label> */}
                            <Form.Control
                              type={'text'}
                              autoComplete='off'
                              placeholder={fields.contactNumberTwo.placeholder}
                              disabled={false}
                              value={item.contactNumberTwo}
                              onChange={async (e) => {
                                setFieldValue('steps.' + i + '.contactNumberTwo', e.target.value);
                              }}
                              isInvalid={!!(errors && errors.steps && errors.steps.length > 0 && errors.steps[i] && typeof errors.steps[i] === 'object' && errors.steps[i].contactNumberTwo)}
                            />
                            <Form.Control.Feedback type="invalid">{(errors && errors.steps && errors.steps[i] && errors.steps[i].contactNumberTwo) && errors.steps[i].contactNumberTwo}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
      
                <Col xs={12} className='mt-16'>
                  <Divider />
                </Col>
              </Row>
            </Col>
          }
        })}
      </>}
      
      {(count === 0) && <Col xs={12}>There are no customer sites</Col>}
    </>
  }

  
  return <Col xs={12} xxl={10}>
    <Row className='gy-16 align-items-center'>
      <Col xs={12}>
        <Row className='g-16 align-items-center'>
          <Col xs={true}>
            <span className="large semibold gray-900">3. Location</span>
          </Col>
          <Col xs={'auto'}>
            <Button 
              variant={'custom-secondary'}
              size={'sm'}
              onClick={() => {
                dispatch(jobStepsSlice.setShow(true));
              }}
            >
              <EditIcon /><span className='ps-s4'>Edit Job Steps</span>
            </Button>
          </Col>
        </Row>
      </Col>

      {getElements()}

      {/* <Col xs={12}>
        <Divider />
      </Col> */}
    </Row>
  </Col>
}

export default Location;
