import _ from 'lodash';

// Download ----------------------------------------------------------------------

export const getUint8Array = (base64: any) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return new Uint8Array(byteNumbers);
}
export const downloadPDF = (name: string, byte: any, type = "application/pdf") => {
  let byteArray = getUint8Array(byte);
  let blob = new Blob([byteArray], { type });
  let url = window.URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
export const viewPDF = (byte: any) => {
  let byteArray = getUint8Array(byte);
  const blob = new Blob([byteArray], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  window.open(url, '_blank');
}
export const downloadCSV = (name: string, byte: any, type = "text/csv") => {
  let blob = new Blob([byte], { type });
  let url = window.URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}
export const downloadEXCEL = (name: string, byte: any, type = "application/vnd.ms-excel") => {
  let byteArray = getUint8Array(byte);
  let blob = new Blob([byteArray], { type });
  let url = window.URL.createObjectURL(blob);
  let link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export const isBase64 = (url: string) => {
  return /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+)?;base64,/.test(url);
};
export const getBase64 = (file: any, field: string = 'url') => {
  const base64 = (file && file[field]) ? file[field].split(',')[1] : '';
  const mimeType = (file && file[field]) ? file[field].split(';')[0]?.split(':')[1] : '';

  return {
    base64: base64,
    mimeType: mimeType,
  }
}
export const downloadImage = (file: any) => {
  let byteArrayPromise;
  let mimeType = 'application/octet-stream';

  if (isBase64(file.url) || isBase64(file.thumbUrl)) {
    let base64Data = file.url ? getBase64(file, 'url') : getBase64(file, 'thumbUrl');
    byteArrayPromise = Promise.resolve(getUint8Array(base64Data?.base64));
    mimeType = base64Data?.mimeType || 'application/octet-stream';
  } else {
    let urlLink = (file.url && file.url != '') ? file.url : (file.response && file.response != '') ? file.response : '';
    byteArrayPromise = fetch(urlLink)
      .then((response) => {
        mimeType = response.headers.get('Content-Type') || 'application/octet-stream';
        return response.arrayBuffer();
      })
      .then((buffer) => new Uint8Array(buffer));
  }
  
  byteArrayPromise.then((byteArray) => {
    const blob = new Blob([byteArray], { type: mimeType  });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}
export const downloadImageByUrl = (url: string) => {
  let byteArrayPromise;
  let mimeType = 'application/octet-stream';

  byteArrayPromise = fetch(url)
    .then((response) => {
      mimeType = response.headers.get('Content-Type') || 'application/octet-stream';
      return response.arrayBuffer();
    })
    .then((buffer) => new Uint8Array(buffer));
  
  byteArrayPromise.then((byteArray) => {
    const blob = new Blob([byteArray], { type: mimeType  });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = getFileNameFromUrl(url);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}
export const getFileNameFromUrl = (url: string) => {
  if (url && url.includes('/')) {
    return url.substring(url.lastIndexOf('/') + 1);
  } else {
    return 'Image';
  }
}