/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
// import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

// import { axios } from '../../../../../v5/utils/axios';
// import { ReadAPIParams, success, successAPI, errorAPI } from '../../../../../v5/utils/utils';
// import Yup from '../../../../../v5/utils/yup';
// import {  } from '../../../../../v5/utils/enums';

import GoogleMap, { addRoute, clearRoute } from '../../../../../v5/components/googleMap/GoogleMap.jsx';


interface InitState {
  show: boolean,
  steps: Array<any>,
}

export const showRoute = (coordinates: Array<any>) => {
  setTimeout(() => {
    if(coordinates && coordinates.length > 1){
      let startPoint: any = null;
      let endPoint: any = null;
      let midPoints: Array<any> = [];
      coordinates.forEach((item: any, i: number) => {
        if(i === 0){
          startPoint = item;
        } else if(i === (coordinates.length-1)){
          endPoint = item;
        } else {
          midPoints.push({
            location: item,
            stopover: true
          })
        }
      });
  
      addRoute(startPoint, endPoint, midPoints, (total: any, directions: any) => {
        console.log('⚡ addRoute | total, directions:', total, directions);
      })
    } else {
      clearRoute();
    }
  });
}

function NewReducer() {
  const name = 'jobFormJobSummarySlice';


  const initialState: InitState = {
    show: false,
    steps: [],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, steps: Array<any> }>) => {
      state.show = action.payload.show;
      state.steps = action.payload.steps;
    },
  };


  const apis = {
    
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();