/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
// import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

// import { axios } from '../../../../../v5/utils/axios';
// import { ReadAPIParams, success, successAPI, errorAPI } from '../../../../../v5/utils/utils';
// import Yup from '../../../../../v5/utils/yup';
// import {  } from '../../../../../v5/utils/enums';



export const fields = {
  customerId: {
    id: 'customerId',
    label: 'Company name',
    placeholder: 'Select',
  },
  customerNote: {
    id: 'customerNote',
    label: 'Customer Note',
    placeholder: 'Default',
  },
};


interface InitState {
  show: boolean,
  tab: string,
}

function NewReducer() {
  const name = 'jobFormBinDetailsServicesSlice';


  const initialState: InitState = {
    show: false,
    tab: 'tab1',
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setShow: (state: InitState, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
    setTab: (state: InitState, action: PayloadAction<string>) => {
      state.tab = action.payload;
    },
  };


  const apis = {
    
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();