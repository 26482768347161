import React from 'react';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Badge,
  Dropdown,
  Form,
  InputGroup,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Divider, Switch } from 'antd';

// Auth
// import { useAuthContext } from '../../../../auth/use-auth-context.js';
import { useRouter } from '../../../../auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector, Tables } from '../../../../utils/store.tsx';
import slice, { fields, JobTemplateType } from './slice.tsx';
import downloadSlice from '../../../../components/download/downloadSlice.tsx';
import confirmSlice from '../../../../components/modal/confirmSlice.tsx';

// enums
import { escapeChars, escapeCharsInStringOnPaste, getIds, success, updateRow, removeRow, cloneOriginal, getMaxPageSize, isNumeric } from '../../../../utils/utils.tsx';
import { UserCookieSettingType } from '../../../../utils/enums.tsx';

import MaterialDataTable from '../../../../components/table/withSlice/MaterialDataTable.jsx';
import DataTableVisibility from '../../../../components/table/withSlice/DataTableVisibility.tsx';
import createGenericSlice, { BackOptions } from '../../../../components/table/withSlice/tableSlice.tsx';
import TableActions from '../../../../components/popover/TableActions.tsx';
import TableFilter from '../../../../components/popover/TableFilter.tsx';
import DefaultPopup from '../../../../components/popover/DefaultPopup.tsx';
import Select from '../../../../components/select/Select.tsx';
import NoResults from '../../../../components/noResults/NoResults.tsx';
import DownloadModal from '../../../../components/download/DownloadModal.tsx'

import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash-2.svg";
import { ReactComponent as BatchEditIcon } from "../../../../assets/icons/batch-edit.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/check.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy-2.svg";
import { ReactComponent as AuditTrailIcon } from "../../../../assets/icons/audit-trail.svg";
import { ReactComponent as XIcon } from "../../../../assets/icons/x.svg";

// ----------------------------------------------------------------------

const table = createGenericSlice<any>(Tables.JobTypes);


const ListComponent = React.forwardRef((props: any, ref: any) => {
  const router = useRouter();

  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  const { tableIsLoading, tableIsFirstLoad, tableData, tableTotal, searchQuery, isIncludeInactive, isInactive, sortColumn, sortDir, columnVisibility, columnSizing, columnOrder, tableStatus, isSavedCookie, isLoadingSaveCookie } = useTypedSelector((state: RootState) => state.tableJobTypes);
  const { isLoadingChangeStatus } = useTypedSelector((state: RootState) => state.manageJobTypesSlice);
  const dispatch = useDispatch<AppDispatch>();
  
  const pageName = 'manage-jobtypes-page-v5';
  const settingsType = UserCookieSettingType.Manage;
  const sliceName = Tables.JobTypes;
  const method = 'get';
  const rowId = 'jobTemplateId';
  const listPath = 'JobTemplate';
  const sortColumnInit = 'jobTemplateType';
  const sortDirInit = 'asc';
  const searchQueryInit = null;
  const tableStatusInit = 2;
  const isIncludeInactiveInit = true;
  const isInactiveInit = null;
  const customSortParams = {};
  const initialParams = {
    currentPage: 1,
    pageSize: 10,
    sortColumn: sortColumnInit,
    sortDir: sortDirInit,
    searchQuery: searchQueryInit,
    isIncludeInactive: isIncludeInactiveInit,
    isInactive: isInactiveInit,
  };

  const refTableActions = React.useRef<any>(null);
  const [dataTable, setDataTable] = React.useState<any>(null);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const columnsRender = [
    {
      id: 'jobTemplateName',
      Cell: ({ renderedCellValue, row }: any) => {
        let isNew = (row && row.original && ((row.original.isNew === true) || (row.original.isNew === false))) ? row.original.isNew : null;

        return <>
          {(isNew === true) && <small className='me-2'><Badge style={{ lineHeight: '12px' }} pill bg={'primary'}>NEW</Badge></small>}
          {(isNew === false) && <small className='me-2'><Badge style={{ lineHeight: '12px' }} pill bg={'warning'}>EDITED</Badge></small>}
          <span>{row?.original?.jobTemplateName}</span>
        </>
      },
      PDF: (row: any) => {
        return row?.jobTemplateName;
      }
    },

    {
      id: 'isActive',
      Cell: ({ renderedCellValue, row }: any) => {
        return <Switch
          disabled={isLoadingChangeStatus}
          checked={row.original.isActive}
          onChange={(checked: any, e: any) => {
            e.preventDefault();
            e.stopPropagation();

            let rowData = (row && row.original) ? _.cloneDeep(row.original) : null;
            let jobTemplateId = (rowData && isNumeric(rowData.jobTemplateId)) ? rowData.jobTemplateId : null;
            let jobTemplateName = (rowData && rowData.jobTemplateName && rowData.jobTemplateName != '') ? rowData.jobTemplateName : null;
            let isActive = checked;

            let params = {
              jobTemplateId: jobTemplateId,
              jobTemplateName: jobTemplateName,
              isActive: isActive,
            };

            dispatch(slice.callChangeStatusApi(params, (state: boolean, data: any) => {
              if (state) {
                rowData.isActive = isActive;
                let rows = updateRow(tableData, rowId, rowData);
                dispatch(table.callSetData({ data: rows, total: tableTotal}));
              }
            }));
          }}
        />
      },
      PDF: (row: any) => {
        return (row  && row.isActive) ? 'Active' : 'Inactive';
      }
    },

    {
      id: 'jobTemplateType',
      Cell: ({ renderedCellValue, row }: any) => {
        let item = (row && row.original && isNumeric(row.original.jobTemplateType)) ? row.original.jobTemplateType : null;
        
        return <div>
          {item === JobTemplateType.Default && <div>Default</div>}
          {item === JobTemplateType.Custom && <div>Custom</div>}
        </div>

      },
      PDF: (row: any) => {
        let item = (row && isNumeric(row.jobTemplateType)) ? row.jobTemplateType : null;
        return item === JobTemplateType.Custom ? "Custom" : "Default";
      }
    },
    
    {
      id: 'actions',
      Cell: ({ renderedCellValue, row }: any) => {
        let item = (row && row.original && isNumeric(row.original.jobTemplateType)) ? row.original.jobTemplateType : null;

        return <div className='text-end w-100'>
          <TableActions 
            ref={refTableActions}
            disabled={false}
          >

            {/* <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                // dispatch(slice.setShow({ show: true, id: row.original[rowId] }));

                if(refTableActions && refTableActions.current){
                  refTableActions.current.hide();
                }
              }}
            >
              <AuditTrailIcon />
              <span className={'ps-2'}>Audit Trail</span>
            </Dropdown.Item> */}

            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                router.push('/v5/manage/job/job-types-form?id=' + row.id);

                if(refTableActions && refTableActions.current){
                  refTableActions.current.hide();
                }
              }}
            >
              <EditIcon />
              <span className={'ps-2'}>Edit</span>
            </Dropdown.Item>

            <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                router.push('/v5/manage/job/job-types-form?id=' + row.id + '&isCopy=true');

                if(refTableActions && refTableActions.current){
                  refTableActions.current.hide();
                }
              }}
            >
              <CopyIcon />
              <span className={'ps-2'}>Copy</span>
            </Dropdown.Item>

            {(item === JobTemplateType.Custom) && <Dropdown.Item
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                dispatch(confirmSlice.show(() => {
                  let params = {
                    [rowId]: row.original[rowId],
                    jobTemplateName: row.original.jobTemplateName,
                  };
                  dispatch(slice.callDeleteApi(params, async (state: boolean, data: any) => {
                    if(state){
                      let rows = await removeRow(tableData, rowId, params);
                      dispatch(table.callSetData({ data: rows, total: tableTotal}));
                    }
                  }));

                  dispatch(confirmSlice.setHide());
                }, {
                  title: 'Delete Job Type',
                  body: 'Are you sure to delete this job type? This cannot be undone',
                  size: 'md',
                  cancelText: 'Cancel',
                  cancelVariant: 'custom-outline',
                  confirmText: 'Delete',
                  confirmVariant: 'custom-danger',
                }));
                
                if(refTableActions && refTableActions.current){
                  refTableActions.current.hide();
                }
              }}
            >
              <TrashIcon />
              <span className={'ps-2 text-danger'}>Delete</span>
            </Dropdown.Item>}

          </TableActions>
        </div>
      }
    },
  ];
  const columns = [
    {
      id: 'jobTemplateName',
      accessorKey: 'jobTemplateName',
      columnDefType: 'data',
      header: fields.jobTemplateName.labelTable,
      enableHiding: false,
      size: 300,
    },
    {
      id: 'jobTemplateDescription',
      accessorKey: 'jobTemplateDescription',
      columnDefType: 'data',
      header: fields.jobTemplateDescription.labelTable,
      size: 600,
    },
    {
      id: 'isActive',
      accessorKey: 'isActive',
      columnDefType: 'data',
      header: fields.isActive.labelTable,
      size: 200,
      enableSorting: false,
    },
    {
      id: 'jobTemplateType',
      accessorKey: 'jobTemplateType',
      columnDefType: 'data',
      header: fields.jobTemplateType.labelTable,
      size: 300,
    },

    {
      id: 'actions',
      accessorKey: 'actions',
      columnDefType: 'data',
      header: '',
      size: 50,
      enableColumnOrdering: false,
      enableSorting: false,
      enableResizing: false,
      enableHiding: false,
    },
  ];
  const columnVisibilityInit = {
    jobTemplateName: true,
    jobTemplateDescription: true,
    isActive: true,
    jobTemplateType: true,
    actions: true,
  };
  const columnOrderInit = [
    "jobTemplateName",
    "jobTemplateDescription",
    "isActive",
    "jobTemplateType",
    "actions",
  ];

  
	React.useImperativeHandle(
    ref,
    () => ({
      clearRowSelection() {
        clearRowSelection();
      },
      reloadTable() {
        reloadTable();
      },
    }),
  );
  

  React.useEffect(() => {
    let args: any = router.getArgs();
    let location = router.getLocation();
    if(args && (args.backOptions === BackOptions.Create || args.backOptions === BackOptions.Update)){
      router.removeArgs(location.pathname);
    } else {
      dispatch(table.callReadUserCookiesApi({
        pageName: pageName, 
        settingsType: settingsType, 
        searchQuery: searchQueryInit, 
        sortColumn: sortColumnInit, 
        sortDir: sortDirInit,  
        columnVisibility: columnVisibilityInit, 
        columnSizing: columnSizing, 
        columnOrder: columnOrderInit, 
        tableStatus: tableStatusInit, 
        isIncludeInactive: isIncludeInactiveInit,
        isInactive: isInactiveInit,
        isSavedCookie: false, 
      }, 
      async (state: boolean, options: any) => {
        if(state && options){
          await dispatch(table.callSetOptions(options, () => {
            firstLoadTable();
          }));
        }
      }));
    }
  }, []);


  const clearRowSelection = () => {
    setSelectedRows([]);
    dataTable?.table?.setRowSelection({});
  }

  const firstLoadTable = () => {
    dispatch(table.callReadApi(listPath, null, false, true, () => {}));
  }

  const loadTable = (apiParams: any) => {
    dispatch(table.callReadApi(listPath, apiParams, true, false, () => {}));
  }

  const reloadTable = (params: any = null) => {
    clearRowSelection();
    dispatch(table.callReadApi(listPath, params, false, false, () => {}));
  }


  const topSection = () => {
    return <div className=''>
      <Row className='align-items-center g-12'>
        <Col xs={12} md={4} lg={3} xl={2}>
          <InputGroup>
            <InputGroup.Text className='p-search'>
              <SearchIcon />
            </InputGroup.Text>
            <Form.Control
              type={'text'}
              autoComplete='off'
              placeholder={'Search'}
              size={'sm'}
              isInvalid={false}
              value={(searchQuery && searchQuery != '') ? searchQuery : ''}
              onChange={(e: any) => {
                escapeChars(e);
                dispatch(table.callSetOptions({ 
                  searchQuery: e.target.value,
                  isIncludeInactive: isIncludeInactive,
                  isInactive: isInactive,
                  currentPage: 1,
                }));
              }}
              onKeyDown={(e: any) => {
                escapeChars(e);

                if (e.key === 'Enter') {
                  reloadTable();
                  
                  dispatch(table.callSaveUserCookiesApi({
                    pageName: pageName, 
                    settingsType: settingsType, 
                    searchQuery: searchQuery,
                    isIncludeInactive: isIncludeInactive,
                    isInactive: isInactive,
                    sortColumn: sortColumn,
                    sortDir: sortDir, 
                    columnVisibility: columnVisibility, 
                    columnSizing: columnSizing, 
                    columnOrder: columnOrder, 
                    tableStatus: tableStatus, 
                    isSavedCookie: true,
                  }, 
                  async (state: boolean, options: any, apiParams: any) => {
                    if(state && options){
                      await dispatch(table.callSetOptions(options));
                    }
                  }));
                }
              }}
              onPaste={(e: any) => {
                const pastedText = e.clipboardData.getData('text');
                const cleanedText = escapeCharsInStringOnPaste(pastedText);
                dispatch(table.callSetOptions({ 
                  searchQuery: cleanedText,
                  isIncludeInactive: isIncludeInactive,
                  isInactive: isInactive,
                  currentPage: 1,
                }));
                e.preventDefault();
              }}
            />
          </InputGroup>
        </Col>
        <Col xs={12} md={true} className='d-none d-md-block'></Col>

        {
          (selectedRows && selectedRows.length > 0)
          ?
          <>
            <Col xs={'auto'}><small className='gray-700'>{selectedRows.length} selected</small></Col>
            <Col xs={'auto'}><Divider type="vertical" style={{ height: '25px' }} /></Col>
            <Col xs={'auto'} className='pe-2 pe-lg-32'>
              <a href={"/"} 
                className={'medium hover text-decoration-underline fs-12 gray-800'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  
                  clearRowSelection();
                }}
              >Deselect</a>
            </Col>

            <Col xs={12} className='d-block d-lg-none'></Col>

            <Col xs={'auto'}>
              <Button
                variant={'custom-outlined'}
                size='sm'
                onClick={(e) => {
                  let ids = getIds(selectedRows);
                  
                }}
              >
                <BatchEditIcon /><span className='ps-2'>Edit</span>
              </Button>
            </Col>
            <Col xs={'auto'}>
              <Button
                variant={'custom-outlined'}
                size='sm'
                onClick={(e) => {
                  let ids = getIds(selectedRows);
                  let isActive = true;

                  dispatch(confirmSlice.show(() => {
                    dispatch(slice.callActivateApi(isActive, ids, (state: boolean, data: any) => {
                      if(state){
                        let originalRows = cloneOriginal(selectedRows, { isActive: isActive });

                        let rows = updateRow(tableData, rowId, originalRows);
                        dispatch(table.callSetData({ data: rows, total: tableTotal}));

                        clearRowSelection();
                        dispatch(confirmSlice.setHide());
                        success('Mark as active successfully', 'Your data has been successfully marked as active.');
                      }
                    }));
                  }, {
                    title: 'Active ' + selectedRows.length + ' job types?',
                    body: 'This will active job types. Are you sure want to active this job types?',
                    size: 'md',
                    cancelText: 'Cancel',
                    cancelVariant: 'custom-outline',
                    confirmText: 'Confirm',
                    confirmVariant: 'custom-primary',
                  }));
                }}
              >
                <CheckIcon /><span className='ps-2'>Active</span>
              </Button>
            </Col>
            <Col xs={'auto'}>
              <Button
                variant={'custom-danger'}
                size='sm'
                onClick={(e) => {
                  let ids = getIds(selectedRows);
                  let isActive = false;

                  dispatch(confirmSlice.show(() => {
                    dispatch(slice.callActivateApi(isActive, ids, (state: boolean, data: any) => {
                      if(state){
                        let originalRows = cloneOriginal(selectedRows, { isActive: isActive });

                        let rows = updateRow(tableData, rowId, originalRows);
                        dispatch(table.callSetData({ data: rows, total: tableTotal}));

                        clearRowSelection();
                        dispatch(confirmSlice.setHide());
                        success('Mark as inactive successfully', 'Your data has been successfully marked as inactive.');
                      }
                    }));
                  }, {
                    title: 'Inactive ' + selectedRows.length + ' job types?',
                    body: 'This will inactive job types. Are you sure want to inactive this job types?',
                    size: 'md',
                    cancelText: 'Cancel',
                    cancelVariant: 'custom-outline',
                    confirmText: 'Confirm',
                    confirmVariant: 'custom-primary',
                  }));
                }}
              >
                <XIcon /><span className='ps-2'>Inactive</span>
              </Button>
            </Col>
          </>
          :
          <>
            {/* <Col xs={'auto'}>
              <Button
                variant={'custom-outlined'}
                size='sm'
                onClick={(e) => {
                  dispatch(downloadSlice.setShow({ show: true, statusFilter: null, fileName: 'Job Types' }));
                }}
              >
                <DownloadIcon /><span className='ps-2'>Export</span>
              </Button>
            </Col> */}
            {/* <Col xs={'auto'}>
              <TableFilter
                onOpen={() => {
                  if(isIncludeInactive){
                    dispatch(table.callTableStatusApi(1));
                  } else {
                    if(isInactive){
                      dispatch(table.callTableStatusApi(3));
                    } else {
                      dispatch(table.callTableStatusApi(2));
                    }
                  }
                }}
                onUpdate={async (state: boolean) => {
                  let isIncludeInactive: any = true;
                  let isInactive: any = null;
                  let currentPage: any = 1;

                  if(state){
                    if(tableStatus == 2){
                      isIncludeInactive = null;
                      isInactive = false;

                      await dispatch(table.callSetOptions({ 
                        tableStatus: 2,
                        isIncludeInactive: isIncludeInactive,
                        isInactive: isInactive,
                        currentPage: currentPage,
                      }));

                    } else if(tableStatus == 3){
                      isIncludeInactive = null;
                      isInactive = true;

                      await dispatch(table.callSetOptions({ 
                        tableStatus: 3,
                        isIncludeInactive: isIncludeInactive,
                        isInactive: isInactive,
                        currentPage: currentPage,
                      }));

                    } else {
                      isIncludeInactive = true;
                      isInactive = null;

                      await dispatch(table.callSetOptions({ 
                        tableStatus: 1,
                        isIncludeInactive: isIncludeInactive,
                        isInactive: isInactive,
                        currentPage: currentPage,
                      }));
                    }

                  } else {
                    isIncludeInactive = true;
                    isInactive = null;

                    await dispatch(table.callSetOptions({ 
                      tableStatus: 1,
                      isIncludeInactive: isIncludeInactive,
                      isInactive: isInactive,
                      currentPage: currentPage,
                    }));
                  }
                  
                  dispatch(table.callSaveUserCookiesApi({
                    pageName: pageName, 
                    settingsType: settingsType, 
                    isIncludeInactive: isIncludeInactive,
                    isInactive: isInactive,
                    sortColumn: sortColumn,
                    sortDir: sortDir, 
                    columnVisibility: columnVisibility, 
                    columnSizing: columnSizing, 
                    columnOrder: columnOrder, 
                    tableStatus: tableStatus, 
                    searchQuery: searchQuery,
                    isSavedCookie: true,
                  }, 
                  async (state: boolean, options: any, apiParams: any) => {
                    if(state && options){
                      await dispatch(table.callSetOptions(options));
                      loadTable(apiParams);
                    }
                  }));
                }}
              >
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as={Select}
                    value={tableStatus}
                    placeholder={'Status'}
                    searchable={false}
                    allowClear={false}
                    selectedIcon={true}
                    loading={false}
                    onChangeValue={(value: any, option: any, ref: any) => {
                      dispatch(table.callTableStatusApi(value));
                      
                      dispatch(table.callSaveUserCookiesApi({
                        pageName: pageName, 
                        settingsType: settingsType, 
                        tableStatus: value, 
                        sortColumn: sortColumn,
                        sortDir: sortDir, 
                        columnVisibility: columnVisibility, 
                        columnSizing: columnSizing, 
                        columnOrder: columnOrder, 
                        searchQuery: searchQuery,
                        isIncludeInactive: isIncludeInactive,
                        isInactive: isInactive,
                        isSavedCookie: true,
                      }, 
                      async (state: boolean, options: any, apiParams: any) => {
                        if(state && options){
                          await dispatch(table.callSetOptions(options));
                        }
                      }));
                    }}
                    options={[
                      { value: 1, label: 'Any status' },
                      { value: 2, label: 'Active' },
                      { value: 3, label: 'Inactive' },
                    ]}
                  />
                </Form.Group>
              </TableFilter>
            </Col> */}
            <Col xs={'auto'} className='text-end'>
              <DefaultPopup
                icon={<SettingsIcon />}
                text={'Edit columns'}
                disabled={false}
              >
                <DataTableVisibility
                  dataTable={dataTable}
                  isSavedCookie={isSavedCookie}
                  isLoadingSaveCookie={isLoadingSaveCookie}
                  onResetColumns={() => {
                    dispatch(table.callSaveUserCookiesApi({
                      pageName: pageName, 
                      settingsType: settingsType, 
                      sortColumn: sortColumnInit,
                      sortDir: sortDirInit, 
                      columnVisibility: columnVisibilityInit, 
                      columnSizing: {}, 
                      columnOrder: columnOrderInit, 
                      tableStatus: tableStatusInit, 
                      searchQuery: searchQueryInit,
                      isIncludeInactive: isIncludeInactiveInit,
                      isInactive: isInactiveInit,
                      isSavedCookie: false,
                    }, 
                    async (state: boolean, options: any, apiParams: any) => {
                      if(state && options){
                        await dispatch(table.callSetOptions(options));
                        loadTable(apiParams);
                      }
                    }));
                  }}
                />
              </DefaultPopup>
            </Col>
          </>
        }
      </Row>
    </div>
  }

  const tableSection = () => {
    return <MaterialDataTable
      onInit={(t: any) => setDataTable(t)}
      sliceName={sliceName}

      endpoint={listPath}
      method={method}
      firstTimeApiCall={false}
      rowId={rowId}
      initialParams={initialParams}
      initialExtraParams={null}
      customSortParams={customSortParams}

      columns={columns}
      columnsRender={columnsRender}
      showPagination={true}

      enableRowSelection={false}
      enableSorting={true}
      enableColumnResizing={true}
      enableColumnOrdering={true}

      renderDetailPanel={null}

      onRowClick={(row: any) => {
        router.push('/v5/manage/job/job-types-form?id=' + row.id);
      }}

      onRowSelection={setSelectedRows}

      onChangeSort={(sortColumn: any, sortDir: any) => {
        dispatch(table.callSaveUserCookiesApi({
          pageName: pageName, 
          settingsType: settingsType, 
          sortColumn: sortColumn, 
          sortDir: sortDir, 
          isIncludeInactive: isIncludeInactive,
          isInactive: isInactive, 
          columnVisibility: columnVisibility, 
          columnSizing: columnSizing, 
          columnOrder: columnOrder, 
          tableStatus: tableStatus, 
          searchQuery: searchQuery,
          isSavedCookie: true,
        }, 
        async (state: boolean, options: any, apiParams: any) => {
          if(state && options){
            await dispatch(table.callSetOptions(options));
          }
        }));
      }}
      onChangeColumnVisibility={(columnVisibility: any) => {
        dispatch(table.callSaveUserCookiesApi({
          pageName: pageName, 
          settingsType: settingsType, 
          columnVisibility: columnVisibility, 
          isIncludeInactive: isIncludeInactive,
          isInactive: isInactive,
          sortColumn: sortColumn,
          sortDir: sortDir, 
          columnSizing: columnSizing, 
          columnOrder: columnOrder, 
          tableStatus: tableStatus, 
          searchQuery: searchQuery,
          isSavedCookie: true,
        }, 
        async (state: boolean, options: any, apiParams: any) => {
          if(state && options){
            await dispatch(table.callSetOptions(options));
          }
        }));
      }}
      onChangeColumnSizing={(columnSizing: any) => {
        dispatch(table.callSaveUserCookiesApi({
          pageName: pageName, 
          settingsType: settingsType, 
          columnSizing: columnSizing, 
          isIncludeInactive: isIncludeInactive,
          isInactive: isInactive,
          sortColumn: sortColumn,
          sortDir: sortDir, 
          columnVisibility: columnVisibility, 
          columnOrder: columnOrder, 
          tableStatus: tableStatus, 
          searchQuery: searchQuery,
          isSavedCookie: true,
        }, 
        async (state: boolean, options: any, apiParams: any) => {
          if(state && options){
            await dispatch(table.callSetOptions(options));
          }
        }));
      }}
      onChangeColumnOrder={(columnOrder: any) => {
        dispatch(table.callSaveUserCookiesApi({
          pageName: pageName, 
          settingsType: settingsType, 
          columnOrder: columnOrder, 
          isIncludeInactive: isIncludeInactive,
          isInactive: isInactive,
          sortColumn: sortColumn,
          sortDir: sortDir, 
          columnVisibility: columnVisibility, 
          columnSizing: columnSizing, 
          tableStatus: tableStatus, 
          searchQuery: searchQuery,
          isSavedCookie: true,
        }, 
        async (state: boolean, options: any, apiParams: any) => {
          if(state && options){
            await dispatch(table.callSetOptions(options));
          }
        }));
      }}
    />
  }

  
  return <>
    <NoResults
      isLoading={tableIsLoading}
      isFirstLoad={tableIsFirstLoad}
      total={tableTotal}
      searchQuery={searchQuery}
      title={'No results found'}
      desc={'There aren\'t any results for job types data.'}
      button={null}
    >
      <Row className='g-24'>
        <Col xs={12}>
          {topSection()}
        </Col>
        <Col xs={12} className='mb-100'>
          {tableSection()}
        </Col>
      </Row>
    </NoResults>
    
    <DownloadModal 
      title={'Export job types'}

      pathExcel={'JobTemplate/download-excel'}
      methodExcel={'get'}
      paramsExcel={{
        currentPage: 1,
        pageSize: getMaxPageSize(),
        sortColumn: sortColumn,
        sortDir: sortDir,
        searchQuery: searchQuery,
        isIncludeInactive: (isIncludeInactive == true) ? true : false,
        isInactive: (isInactive == true) ? true : false,
      }}

      pathPdf={listPath}
      methodPdf={'get'}
      paramsPdf={{
        currentPage: 1,
        pageSize: getMaxPageSize(),
        sortColumn: sortColumn,
        sortDir: sortDir,
        searchQuery: searchQuery,
        isIncludeInactive: (isIncludeInactive == true) ? true : false,
        isInactive: (isInactive == true) ? true : false,
      }}

      columns={dataTable?.table?.getAllColumns()}
      columnOrder={columnOrder}
      columnsRender={columnsRender}
    />
  </>
});

export default ListComponent;
