import React from 'react';
import _ from 'lodash';
import debounce from 'lodash/debounce';
import { Form, InputGroup } from 'react-bootstrap';
import { AutoComplete as AutoCompleteField, Spin } from 'antd';
import Inputmask from "inputmask";

import { axios } from '../../utils/axios';
import { successAPI, errorAPI } from '../../utils/utils';

import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";

// ----------------------------------------------------------------------

type Ref = {
  callReadApi: (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => void,
  callCreateApi: (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => void,
  callUpdateApi: (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => void,
  callDeleteApi: (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => void,
};

const Autocomplete = ({ fieldNames, searchable, onSearch, onTextChange, regex = null, selectedIcon, onChangeValue, onSelectValue, onDropdownVisibleChange, optionRender, disableActions, loading, options, selected, value, footer, ...props }: any) => {
  let selectedValue = (selected && selected[fieldNames.value] && selected[fieldNames.label]) ? selected : [];
  
  const searchRef = React.useRef<any>(null);
  const inputRef = React.useRef<HTMLInputElement|null>(null);

  const [isLoading, setIsLoading] = React.useState(loading || false);
  const [optionsData, setOptionsData] = React.useState(options || [selectedValue]);


  React.useEffect(() => {
    setOptionsData(options)
  }, [options]);
  
  React.useEffect(() => {
    let selectedValue = (selected && selected[fieldNames.value] && selected[fieldNames.label]) ? selected : null;
    let newOptions = (optionsData && optionsData.length > 0) ? _.cloneDeep(optionsData) : [];
    if(selectedValue){
      let index = (newOptions && newOptions.length > 0) ? newOptions.findIndex((x: any) => x[fieldNames.value] === selected[fieldNames.value]) : -1;
      if(index > -1){
        newOptions[index][fieldNames.label] = selected[fieldNames.label];
      } else {
        newOptions.push(selected);
      }
      setOptionsData(newOptions);
    }
  }, [selected]);

  React.useEffect(() => {
    if(regex && inputRef && inputRef.current){
      Inputmask({ regex: regex, jitMasking: true }).mask(inputRef.current)
    }
  }, []);


  const ref = React.useRef<Ref>(null);
  React.useImperativeHandle(ref, () => ({
    callReadApi: async (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => {
      setIsLoading(true);

      await axios.get(apiPath, { params: apiParam }).then(result => {
        let data = result.data;
        
        let options = (data && data.data && data.data.length > 0) ? data.data : [];
        setOptionsData(options);

        successAPI(data);

        if(apiCallback){
          apiCallback(true, data);
        }

        setIsLoading(false);
      }).catch(error => {
        errorAPI(error);
        
        if(apiCallback){
          apiCallback(false, null);
        }
        
        setIsLoading(false);
      });
    },
    callCreateApi: async (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => {
      setIsLoading(true);

      await axios.post(apiPath, apiParam).then(result => {
        let data = result.data;
        
        let obj = (data && data.data) ? data.data : null;

        let options = _.cloneDeep(optionsData);
        options.unshift(obj);
        setOptionsData(options);
        
        successAPI(data);

        if(apiCallback){
          apiCallback(true, obj);
        }

        setIsLoading(false);
      }).catch(error => {
        errorAPI(error);
        
        if(apiCallback){
          apiCallback(false, null);
        }
        
        setIsLoading(false);
      });
    },
    callUpdateApi: async (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => {
      setIsLoading(true);

      await axios.put(apiPath, apiParam).then(result => {
        let data = result.data;
        
        let obj = (data && data.data) ? data.data : null;

        let options = _.cloneDeep(optionsData);
        let index = options.findIndex((x: any) => x[fieldNames.value] === apiParam[fieldNames.value]);
        if(index > -1){
          options[index] = obj;
        }
        setOptionsData(options);

        successAPI(data);

        if(apiCallback){
          apiCallback(true, obj);
        }

        setIsLoading(false);
      }).catch(error => {
        errorAPI(error);
        
        if(apiCallback){
          apiCallback(false, null);
        }
        
        setIsLoading(false);
      });
    },
    callDeleteApi: async (apiPath: string, apiParam: any, apiCallback: (state: boolean, data: any) => void) => {
      setIsLoading(true);

      await axios.delete(apiPath, { data: apiParam }).then(result => {
        let data = result.data;
        
        let options = optionsData.filter((x: any) => x[fieldNames.value] !== apiParam[fieldNames.value]);
        setOptionsData(options);

        successAPI(data);

        if(apiCallback){
          apiCallback(true, data);
        }

        setIsLoading(false);
      }).catch(error => {
        errorAPI(error);
        
        if(apiCallback){
          apiCallback(false, null);
        }
        
        setIsLoading(false);
      });
    },
    clearSearch: () => {
      if(searchRef && searchRef.current){
        searchRef.current.value = '';
      }
    },
    focusSearch: () => {
      if(searchRef && searchRef.current){
        searchRef.current.focus();
      }
    },
    getInput: () => {
      return inputRef.current
    }
  }));


  const handleOnDropdownVisibleChange = (open: any) => {
    if(onDropdownVisibleChange){
      onDropdownVisibleChange(open, ref.current);
    }
  }

  const handleOnChangeValue = (value: any, option: any) => {
    if(onChangeValue){
      onChangeValue(value, option, ref.current);
    }
  }
  
  const handleOnSelect = (value: any, option: any) => {
    if(regex && inputRef && inputRef.current){
      Inputmask.remove(inputRef.current);
    }

    if(onSelectValue){
      onSelectValue(value, option, ref.current);
    }
  }
  
  const handleOnTextChange = (value: any) => {
    if(regex && inputRef && inputRef.current){
      Inputmask.remove(inputRef.current);
      Inputmask({ regex: regex, jitMasking: true }).mask(inputRef.current)
    }

    if(onTextChange){
      onTextChange(value, ref.current);
    }
  }

  const handleOptionRender = (option: any): React.ReactNode => {
    if(optionRender){
      return optionRender(option, ref.current);
    }
  }

  const handleOnSearch = debounce((e: any) => {
    if(onSearch){
      onSearch(e, ref.current);
    }
  }, 300)

  
  return <AutoCompleteField
    className={'w-100'}
    variant={'borderless'}
    defaultActiveFirstOption={false}
    showSearch={false}
    value={value}
    loading={isLoading}
    options={optionsData}
    onDropdownVisibleChange={handleOnDropdownVisibleChange}
    onChange={handleOnChangeValue}
    onSelect={handleOnSelect}
    onSearch={handleOnTextChange}
    optionRender={optionRender ? handleOptionRender : null}
    menuItemSelectedIcon={selectedIcon ? <CheckIcon /> : null}
    notFoundContent={<div className={'text-center'}>No Results!</div>}
    dropdownRender={(menu: any) => (
      <>
        {searchable && <div className='custom-select-search'>
          <InputGroup>
            <InputGroup.Text>
              <SearchIcon />
            </InputGroup.Text>
            <Form.Control
              ref={searchRef}
              type={'text'}
              autoComplete='off'
              placeholder={'Search'}
              disabled={isLoading || disableActions}
              isInvalid={false}
              onChange={(e: any) => handleOnSearch(e)}
            />
          </InputGroup>
        </div>}
        {searchable && <hr className='m-1' />}
        {isLoading ? <div className='text-center mt-3 mb-3'><Spin /></div> : <div className='custom-select-menu'>{menu}</div>}
        {footer && <hr className='m-1' />}
        {footer && <div className='custom-select-footer'>{(typeof footer === 'function') ? footer(isLoading, ref.current) : footer}</div>}
      </>
    )}
    fieldNames={fieldNames}
    {...props}
  >
    <input
      ref={inputRef}
      type={'text'}
      className="custom-ant-input"
      value={value}
      onChange={(e: any) => handleOnChangeValue(e.target.value, null)}
    />
  </AutoCompleteField>
};

export default Autocomplete;