import React, { ReactNode } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Offcanvas,
  Modal,
  Card,
  ListGroup,
  Form,
  InputGroup,
} from 'react-bootstrap';
// import styled from 'styled-components';

import { Radio } from 'antd';
import CurrencyInput from 'react-currency-input-field';

// Auth
// import { useAuthContext } from '../../../auth/use-auth-context.js';
// import { useRouter } from '../../../auth/auth-guard';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../utils/store.tsx';
import slice, { FormikContext, initialValues, formSchema } from './slice.tsx';

import { getDriverAppOptionsStepInfo, DriverAppOptionsLabelEnum, CustomOptions } from '../../../manage/jobs/jobTypes/slice.tsx';
import { StyledInputGroup } from '../../../manage/jobs/jobTypes/form/Popup.tsx';

import { maxInputNumber12, maxInputNumber012 } from '../../../../utils/utils.tsx';
// import {  } from '../../../../utils/enums.tsx';

import { ReactComponent as PlusIcon } from "../../../../../v5/assets/icons/plus-2.svg";
import { ReactComponent as MinusIcon } from "../../../../../v5/assets/icons/minus.svg";

// ----------------------------------------------------------------------

const PageComponent = (props: any) => {
  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  // const router = useRouter();

  const { show, steps } = useTypedSelector((state: RootState) => state.jobFormDriverOptionsSlice);
  const dispatch = useDispatch<AppDispatch>();


  React.useEffect(() => {
    if(show){
      setValues(steps);
    }
  }, [show, steps])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema(null),
    onSubmit: values => {
      if(props.onSave){
        props.onSave(values);
      }

      onCancel();
    },
  });
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;


  const onCancel = () => {
    dispatch(slice.setShow({ show: false, steps: [] }));
    dispatch(slice.resetSlice());
  }
  

  const footerElements = () => {
    return <Row className='w-100 gx-12'>
      <Col xs={true}></Col>
      <Col xs={'auto'} className='text-end'>
        <Button
          variant={'custom-text'}
          size={'sm'}
          className='me-12'
          onClick={(e) => {
            onCancel();
          }}
        >Cancel</Button>
        <Button
          variant={'custom-primary'}
          size={'sm'}
          onClick={(e) => {
            handleSubmit();
          }}
        >Save Changes</Button>
      </Col>
    </Row>
  }

  const formElements = () => {
    return <Row className='g-24 align-items-center'>

      {(values && values.length > 0) && <>
        {values.map((item: any, i: number) => {
          let stepInfo: any = getDriverAppOptionsStepInfo(i, item, values);

          return <Col key={'driver_options_steps_' + i} xs={12} xl={8}>
            <Card className='card-list'>
              <Card.Header>{(i === 0) ? 'Start Job' : (i === (values.length-1)) ? 'Complete Job' : 'In Progress Job'}</Card.Header>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <Row className='align-items-center'>
                    <Col xs={12} lg={6}></Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>Yes</Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>No</Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>Optional</Col>
                  </Row>
                </ListGroup.Item>
                
                {stepInfo?.RequiredBinID && <ListGroup.Item>
                  <Row className='align-items-center'>
                    <Col xs={12} lg={6} className='gray-700'>{DriverAppOptionsLabelEnum.RequiredBinID}</Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.isRequireBinNumberToStart === true)}
                        onChange={(e) => {
                          setFieldValue(i + '.isRequireBinNumberToStart', true);
                          setFieldValue(i + '.isRequireBinNumberToEnd', true);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.isRequireBinNumberToStart === false)}
                        onChange={(e) => {
                          setFieldValue(i + '.isRequireBinNumberToStart', false);
                          setFieldValue(i + '.isRequireBinNumberToEnd', false);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'></Col>
                  </Row>
                </ListGroup.Item>}

                {stepInfo?.ChangeBin && <ListGroup.Item>
                  <Row className='align-items-center'>
                    <Col xs={12} lg={6} className='gray-700'>{DriverAppOptionsLabelEnum.ChangeBin}</Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.canDriverChangeBinType === true)}
                        onChange={(e) => {
                          setFieldValue(i + '.canDriverChangeBinType', true);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.canDriverChangeBinType === false)}
                        onChange={(e) => {
                          setFieldValue(i + '.canDriverChangeBinType', false);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'></Col>
                  </Row>
                </ListGroup.Item>}

                {stepInfo?.ChangeWaste && <ListGroup.Item>
                  <Row className='align-items-center'>
                    <Col xs={12} lg={6} className='gray-700'>{DriverAppOptionsLabelEnum.ChangeWaste}</Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.canDriverChangeWasteType === true)}
                        onChange={(e) => {
                          setFieldValue(i + '.canDriverChangeWasteType', true);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.canDriverChangeWasteType === false)}
                        onChange={(e) => {
                          setFieldValue(i + '.canDriverChangeWasteType', false);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'></Col>
                  </Row>
                </ListGroup.Item>}

                {stepInfo?.NewBinID && <ListGroup.Item>
                  <Row className='align-items-center'>
                    <Col xs={12} lg={6} className='gray-700'>{DriverAppOptionsLabelEnum.NewBinID}</Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.isEditableBinNumber === true)}
                        onChange={(e) => {
                          setFieldValue(i + '.isEditableBinNumber', true);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.isEditableBinNumber === false)}
                        onChange={(e) => {
                          setFieldValue(i + '.isEditableBinNumber', false);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'></Col>
                  </Row>
                </ListGroup.Item>}

                {stepInfo?.TakePhoto && <ListGroup.Item>
                  <Row className='align-items-center'>
                    <Col xs={12} lg={6} className='gray-700'>
                      <Row className='align-items-center gx-2'>
                        <Col xs={'auto'}>{DriverAppOptionsLabelEnum.TakePhoto}</Col>
                        <Col xs={'auto'}>
                          <Form.Group>
                            <StyledInputGroup>
                              <InputGroup.Text
                                onClick={() => {
                                  if(item.mustTakePhoto !== CustomOptions.No){
                                    const minNumb = ((item.mustTakePhoto === CustomOptions.Yes) || (item.mustTakePhoto === CustomOptions.Optional)) ? 1 : 0;
                                  
                                    if(item.numberofPhotosRequired > minNumb){
                                      setFieldValue(i + '.numberofPhotosRequired', (item.numberofPhotosRequired-1));
                                    }
                                  }
                                }}
                              >
                                <MinusIcon />
                              </InputGroup.Text>
                              <Form.Control
                                className='custom'
                                as={CurrencyInput}
                                allowDecimals={false}
                                allowNegativeValue={false}
                                decimalsLimit={0}
                                maxLength={1}

                                disabled={(item.mustTakePhoto === CustomOptions.No) ? true : false}
                                autoComplete='off'
                                placeholder={''}
                                value={item.numberofPhotosRequired}
                                onValueChange={(value: any) => {
                                  const pattern = ((item.mustTakePhoto === CustomOptions.Yes) || (item.mustTakePhoto === CustomOptions.Optional)) ? maxInputNumber12() : maxInputNumber012();
                                  if (pattern.test(value) || value === '') {
                                    setFieldValue(i + '.numberofPhotosRequired', value);
                                  }
                                }}
                                isInvalid={false}
                              />
                              <InputGroup.Text
                                onClick={() => {
                                  if(item.mustTakePhoto !== CustomOptions.No){
                                    if(item.numberofPhotosRequired < 2){
                                      setFieldValue(i + '.numberofPhotosRequired', (item.numberofPhotosRequired+1));
                                    }
                                  }
                                }}
                              >
                                <PlusIcon />
                              </InputGroup.Text>
                            </StyledInputGroup>
                          </Form.Group>
                        </Col>
                        <Col xs={'auto'}>{DriverAppOptionsLabelEnum.TakePhotoSuffix}</Col>
                      </Row>
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.mustTakePhoto === CustomOptions.Yes)}
                        onChange={(e) => {
                          setFieldValue(i + '.mustTakePhoto', CustomOptions.Yes);
                          setFieldValue(i + '.numberofPhotosRequired', 1);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.mustTakePhoto === CustomOptions.No)}
                        onChange={(e) => {
                          setFieldValue(i + '.mustTakePhoto', CustomOptions.No);
                          setFieldValue(i + '.numberofPhotosRequired', 0);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.mustTakePhoto === CustomOptions.Optional)}
                        onChange={(e) => {
                          setFieldValue(i + '.mustTakePhoto', CustomOptions.Optional);
                          setFieldValue(i + '.numberofPhotosRequired', 1);
                        }}
                      />
                    </Col>
                  </Row>
                </ListGroup.Item>}

                {stepInfo?.Signature && <ListGroup.Item>
                  <Row className='align-items-center'>
                    <Col xs={12} lg={6} className='gray-700'>{DriverAppOptionsLabelEnum.Signature}</Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.mustTakeSignature === CustomOptions.Yes)}
                        onChange={(e) => {
                          setFieldValue(i + '.mustTakeSignature', CustomOptions.Yes);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.mustTakeSignature === CustomOptions.No)}
                        onChange={(e) => {
                          setFieldValue(i + '.mustTakeSignature', CustomOptions.No);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.mustTakeSignature === CustomOptions.Optional)}
                        onChange={(e) => {
                          setFieldValue(i + '.mustTakeSignature', CustomOptions.Optional);
                        }}
                      />
                    </Col>
                  </Row>
                </ListGroup.Item>}

                {stepInfo?.InputBinWeight && <ListGroup.Item>
                  <Row className='align-items-center'>
                    <Col xs={12} lg={6} className='gray-700'>{DriverAppOptionsLabelEnum.InputBinWeight}</Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.isRequireBinWeight === true)}
                        onChange={(e) => {
                          setFieldValue(i + '.isRequireBinWeight', true);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.isRequireBinWeight === false)}
                        onChange={(e) => {
                          setFieldValue(i + '.isRequireBinWeight', false);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'></Col>
                  </Row>
                </ListGroup.Item>}

                {stepInfo?.ReviewWasteType && <ListGroup.Item>
                  <Row className='align-items-center'>
                    <Col xs={12} lg={6} className='gray-700'>{DriverAppOptionsLabelEnum.ReviewWasteType}</Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.isRequireReviewWasteType === true)}
                        onChange={(e) => {
                          setFieldValue(i + '.isRequireReviewWasteType', true);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'>
                      <Radio
                        disabled={false}
                        checked={(item.isRequireReviewWasteType === false)}
                        onChange={(e) => {
                          setFieldValue(i + '.isRequireReviewWasteType', false);
                        }}
                      />
                    </Col>
                    <Col xs={true} lg={2} className='semibold gray-800 fs-12 text-center'></Col>
                  </Row>
                </ListGroup.Item>}

              </ListGroup>
            </Card>
          </Col>
        })}
      </>}

    </Row>
  }


  return <Offcanvas
    backdrop={'static'}
    scroll={false}
    placement={'end'}
    show={show}
    onHide={() => {
      onCancel();
    }}
    style={{ width: '60%' }}
  >
    <FormikContext.Provider value={formik}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <div>Driver Options</div>
          <div className='regular fs-14 gray-600'>Job-based rules and permission on Driver’s mobile app</div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>{formElements()}</Offcanvas.Body>
      <Modal.Footer className='border-top'>{footerElements()}</Modal.Footer>
    </FormikContext.Provider>
  </Offcanvas>
}

export default PageComponent;
