import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

// import {
//   Row,
//   Col,
//   Card,
// } from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Spin } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields, findStatus, driverMarkerIcon, arrowMarkerIcon, defaultMarkerIcon } from '../slice.tsx';
import jobSummarySlice from './jobSummary/slice.tsx';

// enums
import { getDefLatLngZoomV4, info, mToKm } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

import { LocationEnum } from '../../manage/jobs/jobTypes/slice.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';
import GoogleMap, { addMarker, clearAllMarkers, addPolyline, clearAllPolyline } from '../../../../v5/components/googleMap/GoogleMap.jsx';

// import { ReactComponent as XIcon } from "../../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------


const Map = () => {
  const defaultLatLngZoom: any = getDefLatLngZoomV4();
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { id, isLoading, latestLocationDrivers, jobTemplate, isLoadingLatestLocationDrivers, defaultLocations, isLoadingDistance, distance } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();

  const [map, setMap] = React.useState<any>(null);
  const [customElements, setCustomElements] = React.useState<Array<any>>([
    {
      element: 'span',
      text: 'Total: 0km',
      className: 'badge bg-custom-light btn-lg',
      style: {
        position: 'absolute',
        bottom: '25px',
        left: '10px',
      },
      visible: true,
      disabled: false,
      callback: null,
    },
    {
      element: 'button',
      text: 'See larger view',
      className: 'btn btn-custom-outlined btn-sm',
      style: {
        position: 'absolute',
        bottom: '25px',
        right: '10px',
      },
      visible: true,
      disabled: !(values.steps && values.steps.length > 0),
      callback: (e: any) => {
        dispatch(jobSummarySlice.setShow({ show: true, steps: values.steps }));
      },
    }
  ]);

  const latestLocationDriversMarkersRef = React.useRef<Array<google.maps.Marker>>([]);
  const driversPathMarkersRef = React.useRef<Array<google.maps.Marker>>([]);
  const polylineRef = React.useRef<Array<google.maps.Polyline>>([]);
  const defaultLocationsMarkersRef = React.useRef<Array<google.maps.Marker>>([]);
  const jobTemplateRef = React.useRef(jobTemplate);
  const stepsRef = React.useRef(values.steps);


  React.useEffect(() => {
    jobTemplateRef.current = jobTemplate;
    
    let coordinates: Array<any> = [];
    if(values.steps && values.steps.length > 0){
      values.steps.forEach((item: any, i: number) => {
        if(item.locationType == LocationEnum.CustomerSite){
          if(item.customerSite){
            coordinates.push({
              customerSiteId: item.customerSiteId,
              latitude: item.customerSite?.latitude,
              longitude: item.customerSite?.longitude,
            });
          }
        } else {
          if(item.hasDefaultSite){
            let locationItem = defaultLocations.find((x: any) => x.defaultLocationId === item.defaultLocationId);
            if(locationItem){
              coordinates.push({
                customerSiteId: null,
                latitude: locationItem.latitude,
                longitude: locationItem.longitude,
              });
            }
          } else {
            
          }
        }
      });
    }
    dispatch(slice.callGetDistanceApi(coordinates, (state: boolean, data: any) => {}));
  }, [jobTemplate]);

  React.useEffect(() => {
    stepsRef.current = values.steps;
  }, [values.steps]);

  React.useEffect(() => {
    clearAllMarkers(latestLocationDriversMarkersRef);
    clearAllMarkers(driversPathMarkersRef);
    clearAllPolyline(polylineRef);

    if(!(id && id > 0)){
      if(latestLocationDrivers && latestLocationDrivers.length > 0){
        if(values.driverId){
          let driver = latestLocationDrivers.find((x: any) => x.driverId === values.driverId);
          if(driver){
            addMarker(latestLocationDriversMarkersRef, {
              map: map,
              ...driver,
              ...driverMarkerIcon(driver.driverName, '#43936C'),
            }, (marker: any, markerItem: any) => {
              onClickDriver(markerItem);
            })
          }

        } else {
          latestLocationDrivers.forEach((item: any) => {
            addMarker(latestLocationDriversMarkersRef, {
              map: map,
              ...item,
              ...driverMarkerIcon(item.driverName, '#43936C'),
            }, (marker: any, markerItem: any) => {
              onClickDriver(markerItem);
            })
          });
        }

        latestLocationDrivers.forEach((item: any, i: number) => {
          addMarker(driversPathMarkersRef, {
            map: map,
            ...item,
            ...arrowMarkerIcon(latestLocationDrivers, item, i),
          })
        });
        
        addPolyline(polylineRef, latestLocationDrivers.map((x: any) => x.position), {
          map: map,
          strokeColor: "#185CFF",
          strokeOpacity: 0.5,
          strokeWeight: 4,
        });
      }
    }
  }, [latestLocationDrivers, values.driverId, window.google])

  React.useEffect(() => {
    let items = _.cloneDeep(customElements);
    items[1].disabled = !(values.steps && values.steps.length > 0);
    items[1].callback = (e: any) => {
      dispatch(jobSummarySlice.setShow({ show: true, steps: stepsRef.current }));
    };
    setCustomElements(items);

    clearAllMarkers(defaultLocationsMarkersRef);

    if(values.steps && values.steps.length > 0){
      values.steps.forEach((item: any, i: number) => {
        if(item.locationType == LocationEnum.CustomerSite){
          if(item.customerSite){
            addMarker(defaultLocationsMarkersRef, {
              map: map,
              position: {
                lat: item.customerSite?.latitude,
                lng: item.customerSite?.longitude,
              },
              title: item.customerSite.siteNameDisplay,
              ...defaultMarkerIcon((i+1).toString()),
            })
          }
        } else {
          if(item.hasDefaultSite){
            let locationItem = defaultLocations.find((x: any) => x.defaultLocationId === item.defaultLocationId);
            if(locationItem){
              addMarker(defaultLocationsMarkersRef, {
                map: map,
                position: {
                  lat: locationItem.latitude,
                  lng: locationItem.longitude,
                },
                title: locationItem.address,
                ...defaultMarkerIcon((i+1).toString()),
              })
            }
          } else {
            
          }
        }
      });
    }
  }, [values.steps]);

  React.useEffect(() => {
    let items = _.cloneDeep(customElements);
    items[0].text = isLoadingDistance ? 'Loading...' : 'Total: ' + mToKm(distance);
    setCustomElements(items);
  }, [isLoadingDistance, distance]);


  const onClickDriver = (markerItem: any) => {
    const currentJobTemplate = jobTemplateRef.current;
    if(currentJobTemplate){
      let item = (markerItem && markerItem.item) ? markerItem.item : null;

      let driver = (item && item.driver) ? item.driver : null;
      let driverId = (driver && driver.driverId) ? driver.driverId : null;
      let driverName = (driver && driver.driverName) ? driver.driverName : '';

      setFieldValue('driverId', driverId);
      setFieldValue('driverName', driverName);
      
      info(driverName, 'Driver has been assigned'); 

      if(values.vehicleId === null || values.vehicleId <= 0){
        if(driver && driver.defaultVehicle){
          let vehicleItem = driver.defaultVehicle;
          let vehicleId = (vehicleItem.vehicleId && vehicleItem.vehicleId > 0) ? vehicleItem.vehicleId : null;
          let vehicleName = (vehicleItem.vehicleName && vehicleItem.vehicleName !== '') ? vehicleItem.vehicleName : '';

          setFieldValue('vehicleId', vehicleId);
          setFieldValue('vehicleName', vehicleName);
        }
      }
      
      let selectedStatus = findStatus(currentJobTemplate?.templateStatuses, 'unassigned');
      if (values.statusId === null || values.statusId === 0 || values.statusId === selectedStatus.jobStatusId) {
        let newStatus = findStatus(currentJobTemplate?.templateStatuses, 'assigned');
        let newStatusId = (newStatus.jobStatusId) ? newStatus.jobStatusId : null;
        let newStatusName = (newStatus.jobStatusName) ? newStatus.jobStatusName : '';

        setFieldValue('statusId', newStatusId);
        setFieldValue('statusName', newStatusName);
      }
    }
  }


  return <Spin spinning={isLoadingLatestLocationDrivers}>
    <div style={{ height: '250px' }}>
      <GoogleMap
        canConextMenu={false}
        options={{
          center: defaultLatLngZoom.coord,
          zoom: defaultLatLngZoom.zoom,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: false,
          fullscreenControl: false,
          gestureHandling: "cooperative",
        }}
        customElements={customElements}
        onLoad={(map: any, ref: any) => {
          setMap(map);
        }}
      />
    </div>
  </Spin>
}

export default Map;
