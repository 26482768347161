import React, { ReactNode } from 'react';
import _ from 'lodash';

import {
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Modal,
} from 'react-bootstrap';
import styled from 'styled-components';

import { Switch, Radio, Divider } from 'antd';
import CurrencyInput from 'react-currency-input-field';

// Auth
// import { useAuthContext } from '../../../auth/use-auth-context.js';
// import { useRouter } from '../../../auth/auth-guard';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../../utils/store.tsx';
import slice, { useFormikContext, getStepDescription, getDriverAppOptionsStepInfo, CustomOptions, DriverAppOptionsLabelEnum } from '../slice.tsx';

import { maxInputNumber12, maxInputNumber012, isNumeric } from '../../../../../utils/utils.tsx';
// import {  } from '../../../../../utils/enums.tsx';

import { ReactComponent as PlusIcon } from "../../../../../../v5/assets/icons/plus-2.svg";
import { ReactComponent as MinusIcon } from "../../../../../../v5/assets/icons/minus.svg";

// ----------------------------------------------------------------------

const StyledRow = styled(Row)`
  .ant-divider {
    margin-top: 4px;
    margin-bottom: 4px;
    padding-bottom: 12px;
    border-color: var(--bs-gray-100);
  }

  .ant-radio-wrapper {
    margin-inline-end: 0px;
  }
`;
export const StyledInputGroup = styled(InputGroup)`
  .input-group-text {
    padding: 5px 8px !important;
    cursor: pointer;

    svg {
      position: relative;
      top: -2px;
    }

    &:hover {
      svg {
        path {
          stroke: var(--bs-gray-900) !important;
        }
      }
    }

    &:first-child ~ .form-control.custom {
      padding-left: 30px !important;
    }
  }

  .form-control {
    width: 80px;
    padding-right: 30px !important;
    text-align: center;
    padding-top: 4px !important;
    padding-bottom: 2px !important;
    min-height: 32px !important;

    &.custom {
      padding-right: 30px !important;
    }
  }
`;


const PopupComponent = (props: any) => {
  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  // const router = useRouter();

  const { show, index } = useTypedSelector((state: RootState) => state.manageJobTypesSlice);
  const dispatch = useDispatch<AppDispatch>();

  const { values, errors, setFieldValue }: any = useFormikContext();

  const [item, setItem] = React.useState<any|null>(null);

  const [isRequireBinNumber, setIsRequireBinNumber] = React.useState<boolean>(true);
  const [isEditableBinNumber, setIsEditableBinNumber] = React.useState<boolean>(true);
  const [isRequireBinWeight, setIsRequireBinWeight] = React.useState<boolean>(true);
  const [canDriverChangeBinType, setCanDriverChangeBinType] = React.useState<boolean>(true);
  const [canDriverChangeWasteType, setCanDriverChangeWasteType] = React.useState<boolean>(true);
  const [isRequireReviewWasteType, setIsRequireReviewWasteType] = React.useState<boolean>(true);
  const [mustTakePhoto, setMustTakePhoto] = React.useState<number>(CustomOptions.Yes);
  const [numberofPhotosRequired, setNumberofPhotosRequired] = React.useState<number>(1);
  const [mustTakeSignature, setMustTakeSignature] = React.useState<number>(CustomOptions.Yes);


  React.useEffect(() => {
    if(show){
      let steps = (values && values.templateSteps && values.templateSteps.length > 0) ? values.templateSteps : [];
      let item = ((index != null) && steps && steps.length > 0 && steps[index]) ? steps[index] : null;

      setItem(item);
      
      let isRequireBinNumber = (item && ((item.isRequireBinNumber === true) || (item.isRequireBinNumber === false))) ? item.isRequireBinNumber : true;
      let isEditableBinNumber = (item && ((item.isEditableBinNumber === true) || (item.isEditableBinNumber === false))) ? item.isEditableBinNumber : true;
      let isRequireBinWeight = (item && ((item.isRequireBinWeight === true) || (item.isRequireBinWeight === false))) ? item.isRequireBinWeight : true;
      let canDriverChangeBinType = (item && ((item.canDriverChangeBinType === true) || (item.canDriverChangeBinType === false))) ? item.canDriverChangeBinType : true;
      let canDriverChangeWasteType = (item && ((item.canDriverChangeWasteType === true) || (item.canDriverChangeWasteType === false))) ? item.canDriverChangeWasteType : true;
      let isRequireReviewWasteType = (item && ((item.isRequireReviewWasteType === true) || (item.isRequireReviewWasteType === false))) ? item.isRequireReviewWasteType : true;
      let mustTakePhoto = (item && isNumeric(item.mustTakePhoto)) ? item.mustTakePhoto : CustomOptions.Yes;
      let numberofPhotosRequired = (item && isNumeric(item.numberofPhotosRequired)) ? item.numberofPhotosRequired : 1;
      let mustTakeSignature = (item && isNumeric(item.mustTakeSignature)) ? item.mustTakeSignature : CustomOptions.Yes;

      setIsRequireBinNumber(isRequireBinNumber);
      setIsEditableBinNumber(isEditableBinNumber);
      setIsRequireBinWeight(isRequireBinWeight);
      setCanDriverChangeBinType(canDriverChangeBinType);
      setCanDriverChangeWasteType(canDriverChangeWasteType);
      setIsRequireReviewWasteType(isRequireReviewWasteType);
      setMustTakePhoto(mustTakePhoto);
      setNumberofPhotosRequired(numberofPhotosRequired);
      setMustTakeSignature(mustTakeSignature);
    }
  }, [show])


  const onSave = () => {
    setFieldValue('templateSteps.' + index + '.isRequireBinNumber', isRequireBinNumber);
    setFieldValue('templateSteps.' + index + '.isEditableBinNumber', isEditableBinNumber);
    setFieldValue('templateSteps.' + index + '.isRequireBinWeight', isRequireBinWeight);
    setFieldValue('templateSteps.' + index + '.canDriverChangeBinType', canDriverChangeBinType);
    setFieldValue('templateSteps.' + index + '.canDriverChangeWasteType', canDriverChangeWasteType);
    setFieldValue('templateSteps.' + index + '.isRequireReviewWasteType', isRequireReviewWasteType);
    setFieldValue('templateSteps.' + index + '.mustTakePhoto', mustTakePhoto);
    setFieldValue('templateSteps.' + index + '.numberofPhotosRequired', numberofPhotosRequired);
    setFieldValue('templateSteps.' + index + '.mustTakeSignature', mustTakeSignature);

    dispatch(slice.setShow({ show: false, index: null }));
  }
  const onCancel = () => {
    dispatch(slice.setShow({ show: false, index: null }));
  }
  

  const footerElements = () => {
    return <Row className='w-100 gx-12'>
      <Col xs={true} className='text-end'>
        <Button
          variant={'custom-text'}
          size={'sm'}
          className='me-12'
          disabled={false}
          onClick={(e) => {
            onCancel();
          }}
        >Cancel</Button>
        <Button
          variant={'custom-primary'}
          size={'sm'}
          disabled={false}
          onClick={() => {
            onSave();
          }}
        >Save</Button>
      </Col>
    </Row>
  }

  const formElements = () => {
    if(index != null && item){
      let i: number = index ? index : 0;
      let stepName: any = getStepDescription(i, item);
      let stepInfo: any = getDriverAppOptionsStepInfo(i, item, values.templateSteps);

      return <>
        <Col xs={12}>
          <Row className='align-items-center'>
            <Col xs={12} lg={6} className='semibold gray-900 pb-1 pb-lg-0'>Step {i+1}: {stepName}</Col>
            <Col xs={true} lg={2} className='semibold gray-900 text-center'>Yes</Col>
            <Col xs={true} lg={2} className='semibold gray-900 text-center'>No</Col>
            <Col xs={true} lg={2} className='semibold gray-900 text-center'>Optional</Col>
          </Row>
        </Col>
        
        {stepInfo?.RequiredBinID && <Col xs={12}>
          <StyledRow className='align-items-center'>
            <Col xs={12}><Divider /></Col>
            <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.RequiredBinID}</Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(isRequireBinNumber === true)}
                onChange={(e) => {
                  setIsRequireBinNumber(true);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(isRequireBinNumber === false)}
                onChange={(e) => {
                  setIsRequireBinNumber(false);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={true}
                checked={false}
                onChange={(e) => {
                  
                }}
              />
            </Col>
          </StyledRow>
        </Col>}

        {stepInfo?.ChangeBin && <Col xs={12}>
          <StyledRow className='align-items-center'>
            <Col xs={12}><Divider /></Col>
            <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.ChangeBin}</Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(canDriverChangeBinType === true)}
                onChange={(e) => {
                  setCanDriverChangeBinType(true);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(canDriverChangeBinType === false)}
                onChange={(e) => {
                  setCanDriverChangeBinType(false);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={true}
                checked={false}
                onChange={(e) => {
                  
                }}
              />
            </Col>
          </StyledRow>
        </Col>}

        {stepInfo?.ChangeWaste && <Col xs={12}>
          <StyledRow className='align-items-center'>
            <Col xs={12}><Divider /></Col>
            <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.ChangeWaste}</Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(canDriverChangeWasteType === true)}
                onChange={(e) => {
                  setCanDriverChangeWasteType(true);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(canDriverChangeWasteType === false)}
                onChange={(e) => {
                  setCanDriverChangeWasteType(false);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={true}
                checked={false}
                onChange={(e) => {
                  
                }}
              />
            </Col>
          </StyledRow>
        </Col>}

        {stepInfo?.NewBinID && <Col xs={12}>
          <StyledRow className='align-items-center'>
            <Col xs={12}><Divider /></Col>
            <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.NewBinID}</Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(isEditableBinNumber === true)}
                onChange={(e) => {
                  setIsEditableBinNumber(true);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(isEditableBinNumber === false)}
                onChange={(e) => {
                  setIsEditableBinNumber(false);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={true}
                checked={false}
                onChange={(e) => {
                  
                }}
              />
            </Col>
          </StyledRow>
        </Col>}

        {stepInfo?.TakePhoto && <Col xs={12}>
          <StyledRow className='align-items-center'>
            <Col xs={12}><Divider /></Col>
            <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>
              <Row className='align-items-center'>
                <Col xs={'auto'}>{DriverAppOptionsLabelEnum.TakePhoto}</Col>
                <Col xs={'auto'}>
                  <Form.Group>
                    <StyledInputGroup>
                      <InputGroup.Text
                        onClick={() => {
                          if(mustTakePhoto !== CustomOptions.No){
                            const minNumb = ((mustTakePhoto === CustomOptions.Yes) || (mustTakePhoto === CustomOptions.Optional)) ? 1 : 0;
                          
                            if(numberofPhotosRequired > minNumb){
                              setNumberofPhotosRequired((numberofPhotosRequired-1));
                            }
                          }
                        }}
                      >
                        <MinusIcon />
                      </InputGroup.Text>
                      <Form.Control
                        className='custom'
                        as={CurrencyInput}
                        allowDecimals={false}
                        allowNegativeValue={false}
                        decimalsLimit={0}
                        maxLength={1}

                        disabled={(mustTakePhoto === CustomOptions.No) ? true : false}
                        autoComplete='off'
                        placeholder={''}
                        value={numberofPhotosRequired}
                        onValueChange={(value: any) => {
                          const pattern = ((mustTakePhoto === CustomOptions.Yes) || (mustTakePhoto === CustomOptions.Optional)) ? maxInputNumber12() : maxInputNumber012();
                          if (pattern.test(value) || value === '') {
                            setNumberofPhotosRequired(value);
                          }
                        }}
                        isInvalid={false}
                      />
                      <InputGroup.Text
                        onClick={() => {
                          if(mustTakePhoto !== CustomOptions.No){
                            if(numberofPhotosRequired < 2){
                              setNumberofPhotosRequired((numberofPhotosRequired+1));
                            }
                          }
                        }}
                      >
                        <PlusIcon />
                      </InputGroup.Text>
                    </StyledInputGroup>
                  </Form.Group>
                </Col>
                <Col xs={'auto'}>{DriverAppOptionsLabelEnum.TakePhotoSuffix}</Col>
              </Row>
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(mustTakePhoto === CustomOptions.Yes)}
                onChange={(e) => {
                  setMustTakePhoto(CustomOptions.Yes);
                  setNumberofPhotosRequired(1);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(mustTakePhoto === CustomOptions.No)}
                onChange={(e) => {
                  setMustTakePhoto(CustomOptions.No);
                  setNumberofPhotosRequired(0);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(mustTakePhoto === CustomOptions.Optional)}
                onChange={(e) => {
                  setMustTakePhoto(CustomOptions.Optional);
                  setNumberofPhotosRequired(1);
                }}
              />
            </Col>
          </StyledRow>
        </Col>}

        {stepInfo?.Signature && <Col xs={12}>
          <StyledRow className='align-items-center'>
            <Col xs={12}><Divider /></Col>
            <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.Signature}</Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(mustTakeSignature === CustomOptions.Yes)}
                onChange={(e) => {
                  setMustTakeSignature(CustomOptions.Yes);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(mustTakeSignature === CustomOptions.No)}
                onChange={(e) => {
                  setMustTakeSignature(CustomOptions.No);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(mustTakeSignature === CustomOptions.Optional)}
                onChange={(e) => {
                  setMustTakeSignature(CustomOptions.Optional);
                }}
              />
            </Col>
          </StyledRow>
        </Col>}

        {stepInfo?.InputBinWeight && <Col xs={12}>
          <StyledRow className='align-items-center'>
            <Col xs={12}><Divider /></Col>
            <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.InputBinWeight}</Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(isRequireBinWeight === true)}
                onChange={(e) => {
                  setIsRequireBinWeight(true);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(isRequireBinWeight === false)}
                onChange={(e) => {
                  setIsRequireBinWeight(false);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={true}
                checked={false}
                onChange={(e) => {
                  
                }}
              />
            </Col>
          </StyledRow>
        </Col>}

        {stepInfo?.ReviewWasteType && <Col xs={12}>
          <StyledRow className='align-items-center'>
            <Col xs={12}><Divider /></Col>
            <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.ReviewWasteType}</Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(isRequireReviewWasteType === true)}
                onChange={(e) => {
                  setIsRequireReviewWasteType(true);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={false}
                checked={(isRequireReviewWasteType === false)}
                onChange={(e) => {
                  setIsRequireReviewWasteType(false);
                }}
              />
            </Col>
            <Col xs={true} lg={2} className='text-center'>
              <Radio
                disabled={true}
                checked={false}
                onChange={(e) => {
                  
                }}
              />
            </Col>
          </StyledRow>
        </Col>}

        {!(
          stepInfo?.RequiredBinID ||
          stepInfo?.ChangeBin ||
          stepInfo?.ChangeWaste ||
          stepInfo?.NewBinID ||
          stepInfo?.TakePhoto ||
          stepInfo?.Signature ||
          stepInfo?.InputBinWeight ||
          stepInfo?.ReviewWasteType
        ) &&  <Col xs={12}>No setting</Col>}
      </>
    } else {
      return <Col xs={12}>No setting</Col>
    }
  }


  return <Modal
    show={show}
    onHide={() => {
      onCancel();
    }}
    backdrop={'static'}
    // keyboard={false}
    // enforceFocus={false}
    centered={true}
    size={'lg'}
  >
      <Modal.Header closeButton>
        <h6 className='fw-medium'>Driver App Options</h6>
      </Modal.Header>

      <Modal.Body>
        <Row className='w-100 g-12'>
          {formElements()}
        </Row>
      </Modal.Body>

      <Modal.Footer className='border-top'>{footerElements()}</Modal.Footer>
  </Modal>
}

export default PopupComponent;
