import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Card,
  Button,
  Form,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Divider, Checkbox } from "antd";
import CurrencyInput from 'react-currency-input-field';

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields } from '../slice.tsx';
import servicesSlice from './services/slice.tsx';

// enums
// import {  } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

// components
import SmartSelect from '../../../../v5/components/select/SmartSelect.tsx';
import SmartAutocomplete from '../../../../v5/components/autocomplete/SmartAutocomplete.tsx';

import { ReactComponent as PlusIcon } from "../../../../v5/assets/icons/plus.svg";

// ----------------------------------------------------------------------

const StyledCard: React.FC<React.ComponentProps<typeof Card>> = styled(Card)`
  padding: 8px 16px 16px 16px;
`;


const BinDetails = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  // const { isLoading } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();


  const getBinOUTElements = () => {
    return <StyledCard className='no-bg'>
      <Row className='g-12 align-items-center'>
        <Col xs={12} className='semibold gray-600'>BIN OUT</Col>

        <Col xs={12}>
          <Row className='g-8 align-items-center'>
            <Col xs={12}>
              <Checkbox
                className='sm'
                checked={false}
                onChange={(e) => {
                  
                }}
              >{fields.isTrackingBin.label}</Checkbox>
            </Col>
            
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label>{fields.binTypeId.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  placeholder={fields.binTypeId.placeholder}
                  disabled={false}
                  readOnly={true}
                  value={values.binTypeId}
                  onChange={async (e) => {
                    
                  }}
                  onClick={() => {
                    dispatch(servicesSlice.setShow(true));
                  }}
                  isInvalid={!!(errors && errors.binTypeId)}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.binTypeId as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label>{fields.wasteTypeId.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  placeholder={fields.wasteTypeId.placeholder}
                  disabled={false}
                  readOnly={true}
                  value={values.wasteTypeId}
                  onChange={async (e) => {
                    
                  }}
                  onClick={() => {
                    dispatch(servicesSlice.setShow(true));
                  }}
                  isInvalid={!!(errors && errors.wasteTypeId)}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.wasteTypeId as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label>{fields.binNumberId.label}</Form.Label>
                <Form.Control
                  as={SmartAutocomplete}
                  isInvalid={!!(errors && errors.binNumberId)}
                  value={values.binNumberId}
                  placeholder={fields.binNumberId.placeholder}

                  addText={'Add new bin number'}
                  apiPath={'binNumber'}
                  customParams={{
                    recentJob: true,
                  }}
                  fieldNames={{ value: 'binNumberId', label: 'binNumber' }}
                  selected={{ binNumberId: values.binNumberId, binNumber: values.binNumberName }}

                  createResponseFunc={(options: any) => {
                    return (options) ? options : null;
                  }}
                  onCreateFunc={(option: any) => {
                    if(option){
                      setFieldValue('binNumberId', option.binNumberId);
                      setFieldValue('binNumberName', option.binNumberName);
                    }
                  }}
                  updateResponseFunc={(options: any) => {
                    return (options) ? options : null;
                  }}
                  onUpdateFunc={(option: any) => {
                    if(option && values.binNumberId === option.binNumberId){
                      setFieldValue('binNumberId', option.binNumberId);
                      setFieldValue('binNumberName', option.binNumberName);
                    }
                  }}

                  footerRender={(isLoading: boolean, ref: any) => {
                    return <Button
                      variant={'custom-text'}
                      size={'sm'}
                      disabled={isLoading}
                      onClick={(e) => { 
                        e.preventDefault();
                        e.stopPropagation();

                      }}
                    ><PlusIcon /> Add new bin number</Button>
                  }}

                  hasOptionRender={false}
                  hasFooterRender={true}
                  searchable={true}
                  allowClear={true}
                  selectedIcon={false}
                  disabled={false}
                  virtual={true}

                  onChangeValue={async (value: any, option: any, ref: any) => {
                    if(option){
                      await setFieldValue('binNumberId', value);
                      await setFieldValue('binNumberName', option.binNumberName);
                    } else {
                      await setFieldValue('binNumberId', null);
                      await setFieldValue('binNumberName', '');
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.binNumberId as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label disabled={true}>{fields.serviceTag.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  placeholder={fields.serviceTag.placeholder}
                  disabled={true}
                  value={values.serviceTag}
                  onChange={async (e) => {
                    await setFieldValue('serviceTag', e.target.value);
                  }}
                  isInvalid={!!(errors && errors.serviceTag)}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.serviceTag as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col xs={12}>
              <Divider className='mt-8' />
            </Col>
          </Row>
        </Col>

        <Col xs={12}>
          <Row className='g-8 align-items-center'>
            <Col xs={12}>
              <Checkbox
                className='sm'
                checked={false}
                onChange={(e) => {
                  
                }}
              >{fields.isTrackingBin.label}</Checkbox>
            </Col>
            
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label>{fields.binTypeId.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  placeholder={fields.binTypeId.placeholder}
                  disabled={false}
                  readOnly={true}
                  value={values.binTypeId}
                  onChange={async (e) => {
                    
                  }}
                  onClick={() => {
                    dispatch(servicesSlice.setShow(true));
                  }}
                  isInvalid={!!(errors && errors.binTypeId)}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.binTypeId as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label>{fields.wasteTypeId.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  placeholder={fields.wasteTypeId.placeholder}
                  disabled={false}
                  readOnly={true}
                  value={values.wasteTypeId}
                  onChange={async (e) => {
                    
                  }}
                  onClick={() => {
                    dispatch(servicesSlice.setShow(true));
                  }}
                  isInvalid={!!(errors && errors.wasteTypeId)}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.wasteTypeId as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label>{fields.binNumberId.label}</Form.Label>
                <Form.Control
                  as={SmartAutocomplete}
                  isInvalid={!!(errors && errors.binNumberId)}
                  value={values.binNumberId}
                  placeholder={fields.binNumberId.placeholder}

                  addText={'Add new bin number'}
                  apiPath={'binNumber'}
                  customParams={{
                    recentJob: true,
                  }}
                  fieldNames={{ value: 'binNumberId', label: 'binNumber' }}
                  selected={{ binNumberId: values.binNumberId, binNumber: values.binNumberName }}

                  createResponseFunc={(options: any) => {
                    return (options) ? options : null;
                  }}
                  onCreateFunc={(option: any) => {
                    if(option){
                      setFieldValue('binNumberId', option.binNumberId);
                      setFieldValue('binNumberName', option.binNumberName);
                    }
                  }}
                  updateResponseFunc={(options: any) => {
                    return (options) ? options : null;
                  }}
                  onUpdateFunc={(option: any) => {
                    if(option && values.binNumberId === option.binNumberId){
                      setFieldValue('binNumberId', option.binNumberId);
                      setFieldValue('binNumberName', option.binNumberName);
                    }
                  }}

                  footerRender={(isLoading: boolean, ref: any) => {
                    return <Button
                      variant={'custom-text'}
                      size={'sm'}
                      disabled={isLoading}
                      onClick={(e) => { 
                        e.preventDefault();
                        e.stopPropagation();

                      }}
                    ><PlusIcon /> Add new bin number</Button>
                  }}

                  hasOptionRender={false}
                  hasFooterRender={true}
                  searchable={true}
                  allowClear={true}
                  selectedIcon={false}
                  disabled={false}
                  virtual={true}

                  onChangeValue={async (value: any, option: any, ref: any) => {
                    if(option){
                      await setFieldValue('binNumberId', value);
                      await setFieldValue('binNumberName', option.binNumberName);
                    } else {
                      await setFieldValue('binNumberId', null);
                      await setFieldValue('binNumberName', '');
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.binNumberId as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label disabled={true}>{fields.serviceTag.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  placeholder={fields.serviceTag.placeholder}
                  disabled={true}
                  value={values.serviceTag}
                  onChange={async (e) => {
                    await setFieldValue('serviceTag', e.target.value);
                  }}
                  isInvalid={!!(errors && errors.serviceTag)}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.serviceTag as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledCard>
  }

  const getBinINElements = () => {
    return <StyledCard className='no-bg'>
      <Row className='g-12 align-items-center'>
        <Col xs={12} className='semibold gray-600'>BIN IN</Col>
        
        <Col xs={12}>
          <Row className='g-8 align-items-center'>
            <Col xs={12}>
              <Checkbox
                className='sm'
                checked={false}
                onChange={(e) => {
                  
                }}
              >{fields.sameAsBinOut.label}</Checkbox>
              <Checkbox
                className='sm'
                checked={false}
                onChange={(e) => {
                  
                }}
              >{fields.isTrackingBin.label}</Checkbox>
            </Col>

            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label>{fields.binTypeId.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  placeholder={fields.binTypeId.placeholder}
                  disabled={false}
                  readOnly={true}
                  value={values.binTypeId}
                  onChange={async (e) => {
                    
                  }}
                  onClick={() => {
                    dispatch(servicesSlice.setShow(true));
                  }}
                  isInvalid={!!(errors && errors.binTypeId)}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.binTypeId as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label>{fields.wasteTypeId.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  placeholder={fields.wasteTypeId.placeholder}
                  disabled={false}
                  readOnly={true}
                  value={values.wasteTypeId}
                  onChange={async (e) => {
                    
                  }}
                  onClick={() => {
                    dispatch(servicesSlice.setShow(true));
                  }}
                  isInvalid={!!(errors && errors.wasteTypeId)}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.wasteTypeId as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label>{fields.binNumberId.label}</Form.Label>
                <Form.Control
                  as={SmartAutocomplete}
                  isInvalid={!!(errors && errors.binNumberId)}
                  value={values.binNumberId}
                  placeholder={fields.binNumberId.placeholder}

                  addText={'Add new bin number'}
                  apiPath={'binNumber'}
                  customParams={{
                    recentJob: true,
                  }}
                  fieldNames={{ value: 'binNumberId', label: 'binNumber' }}
                  selected={{ binNumberId: values.binNumberId, binNumber: values.binNumberName }}

                  createResponseFunc={(options: any) => {
                    return (options) ? options : null;
                  }}
                  onCreateFunc={(option: any) => {
                    if(option){
                      setFieldValue('binNumberId', option.binNumberId);
                      setFieldValue('binNumberName', option.binNumberName);
                    }
                  }}
                  updateResponseFunc={(options: any) => {
                    return (options) ? options : null;
                  }}
                  onUpdateFunc={(option: any) => {
                    if(option && values.binNumberId === option.binNumberId){
                      setFieldValue('binNumberId', option.binNumberId);
                      setFieldValue('binNumberName', option.binNumberName);
                    }
                  }}

                  footerRender={(isLoading: boolean, ref: any) => {
                    return <Button
                      variant={'custom-text'}
                      size={'sm'}
                      disabled={isLoading}
                      onClick={(e) => { 
                        e.preventDefault();
                        e.stopPropagation();

                      }}
                    ><PlusIcon /> Add new bin number</Button>
                  }}

                  hasOptionRender={false}
                  hasFooterRender={true}
                  searchable={true}
                  allowClear={true}
                  selectedIcon={false}
                  disabled={false}
                  virtual={true}

                  onChangeValue={async (value: any, option: any, ref: any) => {
                    if(option){
                      await setFieldValue('binNumberId', value);
                      await setFieldValue('binNumberName', option.binNumberName);
                    } else {
                      await setFieldValue('binNumberId', null);
                      await setFieldValue('binNumberName', '');
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.binNumberId as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label disabled={true}>{fields.serviceTag.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  placeholder={fields.serviceTag.placeholder}
                  disabled={true}
                  value={values.serviceTag}
                  onChange={async (e) => {
                    await setFieldValue('serviceTag', e.target.value);
                  }}
                  isInvalid={!!(errors && errors.serviceTag)}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.serviceTag as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label disabled={false}>{fields.binweight.label}</Form.Label>
                <Form.Control
                  as={CurrencyInput}
                  allowDecimals={true}
                  allowNegativeValue={false}
                  decimalsLimit={2}

                  autoComplete='off'
                  disabled={false}
                  placeholder={fields.binweight.placeholder}
                  defaultValue={values.binweight}
                  onValueChange={async (value: any) => {
                    await setFieldValue('binweight', value);
                  }}
                
                  isInvalid={!!(errors && errors.binweight)}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.binweight as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            
            <Col xs={12}>
              <Divider className='mt-8' />
            </Col>
          </Row>
        </Col>
        
        <Col xs={12}>
          <Row className='g-8 align-items-center'>
            <Col xs={12}>
              <Checkbox
                className='sm'
                checked={false}
                onChange={(e) => {
                  
                }}
              >{fields.sameAsBinOut.label}</Checkbox>
              <Checkbox
                className='sm'
                checked={false}
                onChange={(e) => {
                  
                }}
              >{fields.isTrackingBin.label}</Checkbox>
            </Col>

            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label>{fields.binTypeId.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  placeholder={fields.binTypeId.placeholder}
                  disabled={false}
                  readOnly={true}
                  value={values.binTypeId}
                  onChange={async (e) => {
                    
                  }}
                  onClick={() => {
                    dispatch(servicesSlice.setShow(true));
                  }}
                  isInvalid={!!(errors && errors.binTypeId)}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.binTypeId as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label>{fields.wasteTypeId.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  placeholder={fields.wasteTypeId.placeholder}
                  disabled={false}
                  readOnly={true}
                  value={values.wasteTypeId}
                  onChange={async (e) => {
                    
                  }}
                  onClick={() => {
                    dispatch(servicesSlice.setShow(true));
                  }}
                  isInvalid={!!(errors && errors.wasteTypeId)}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.wasteTypeId as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label>{fields.binNumberId.label}</Form.Label>
                <Form.Control
                  as={SmartAutocomplete}
                  isInvalid={!!(errors && errors.binNumberId)}
                  value={values.binNumberId}
                  placeholder={fields.binNumberId.placeholder}

                  addText={'Add new bin number'}
                  apiPath={'binNumber'}
                  customParams={{
                    recentJob: true,
                  }}
                  fieldNames={{ value: 'binNumberId', label: 'binNumber' }}
                  selected={{ binNumberId: values.binNumberId, binNumber: values.binNumberName }}

                  createResponseFunc={(options: any) => {
                    return (options) ? options : null;
                  }}
                  onCreateFunc={(option: any) => {
                    if(option){
                      setFieldValue('binNumberId', option.binNumberId);
                      setFieldValue('binNumberName', option.binNumberName);
                    }
                  }}
                  updateResponseFunc={(options: any) => {
                    return (options) ? options : null;
                  }}
                  onUpdateFunc={(option: any) => {
                    if(option && values.binNumberId === option.binNumberId){
                      setFieldValue('binNumberId', option.binNumberId);
                      setFieldValue('binNumberName', option.binNumberName);
                    }
                  }}

                  footerRender={(isLoading: boolean, ref: any) => {
                    return <Button
                      variant={'custom-text'}
                      size={'sm'}
                      disabled={isLoading}
                      onClick={(e) => { 
                        e.preventDefault();
                        e.stopPropagation();

                      }}
                    ><PlusIcon /> Add new bin number</Button>
                  }}

                  hasOptionRender={false}
                  hasFooterRender={true}
                  searchable={true}
                  allowClear={true}
                  selectedIcon={false}
                  disabled={false}
                  virtual={true}

                  onChangeValue={async (value: any, option: any, ref: any) => {
                    if(option){
                      await setFieldValue('binNumberId', value);
                      await setFieldValue('binNumberName', option.binNumberName);
                    } else {
                      await setFieldValue('binNumberId', null);
                      await setFieldValue('binNumberName', '');
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.binNumberId as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label disabled={true}>{fields.serviceTag.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  placeholder={fields.serviceTag.placeholder}
                  disabled={true}
                  value={values.serviceTag}
                  onChange={async (e) => {
                    await setFieldValue('serviceTag', e.target.value);
                  }}
                  isInvalid={!!(errors && errors.serviceTag)}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.serviceTag as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label disabled={false}>{fields.binweight.label}</Form.Label>
                <Form.Control
                  as={CurrencyInput}
                  allowDecimals={true}
                  allowNegativeValue={false}
                  decimalsLimit={2}

                  autoComplete='off'
                  disabled={false}
                  placeholder={fields.binweight.placeholder}
                  defaultValue={values.binweight}
                  onValueChange={async (value: any) => {
                    await setFieldValue('binweight', value);
                  }}
                
                  isInvalid={!!(errors && errors.binweight)}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.binweight as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledCard>
  }


  return <Col xs={12} xxl={10}>
    <Row className='g-16 align-items-center'>
      <Col xs={12}>
        <span className="large semibold gray-900">4. Bin Detail</span>
      </Col>

      <Col xs={12}>{getBinOUTElements()}</Col>
      <Col xs={12}>{getBinINElements()}</Col>

      <Col xs={12}>
        <Divider />
      </Col>
    </Row>
  </Col>
}

export default BinDetails;
