import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

// import { Switch } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields } from '../slice.tsx';

// enums
// import {  } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';

import { ReactComponent as XIcon } from "../../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------

const StyledButton: React.FC<React.ComponentProps<typeof Button>> = styled(Button)`
  line-height: 14px;
  padding: 5px;

  svg {
    path {
      stroke: var(--bs-gray-700);
    }
  }
`;


const Header = (props: any) => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  // const { isLoading } = useTypedSelector((state: RootState) => state.jobFormSlice);
  // const dispatch = useDispatch<AppDispatch>();


  return <>
    <Row className='align-items-center'>
      <Col xs={true}>
        <h6 className="medium mb-0">Create New Job</h6>
      </Col>
      
      <Col xs={'auto'}>
        <StyledButton
          variant={'custom-outlined'}
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
            
            if(props.onCancel){
              props.onCancel();
            }
          }}
        >
          <XIcon />
        </StyledButton>
      </Col>
    </Row>
  </>
}

export default Header;
