import { SliceCaseReducers, ValidateSliceCaseReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { axios, axiosAuth } from '../../../utils/axios';
import { errorAPI, successAPI, getUserCookieSettings, getUserCookieSettingsIndex, getUserCookies, isNumeric } from '../../../utils/utils';
import { PageCookieSettingType } from '../../../utils/enums';

import { type MRT_ColumnDef } from 'material-react-table';


export const BackOptions = {
  Read: 0,
  Create: 1,
  Update: 2,
  Delete: 3
}

export const getOptions = (slice: any = null, args: any = {}, join: boolean = false) => {
  let params = null;

  if(slice){
    const { currentPage, pageSize, sortColumn, sortDir, searchQuery, isIncludeInactive, isInactive, statusFilter, fromDate, toDate } = slice;

    if(join){
      params = args;

    } else {
      let obj: any = {
        currentPage: currentPage,
        pageSize: pageSize,
        sortColumn: sortColumn,
        sortDir: sortDir,
        searchQuery: searchQuery,
        statusFilter: statusFilter,
        fromDate: fromDate,
        toDate: toDate,
      }
  
      if(isIncludeInactive != null){
        obj['isIncludeInactive'] = isIncludeInactive;
      }
  
      if(isInactive != null){
        obj['isInactive'] = isInactive;
      }
  
      params = (args) ? Object.assign({}, obj, args) : obj;
    }
  }

  return params;
}

export interface InitState<T> {
  tableIsFirstLoad: boolean,

  tableIsLoading: boolean,
  tableTotal: number,
  tableData: T[],
  tableColumns: MRT_ColumnDef<any>[],

  currentPage:number|null,
  pageSize:number|null,
  sortColumn:string|null,
  sortDir:string|null,
  searchQuery:string|null,
  isIncludeInactive: boolean|null,
  isInactive: boolean|null,

  statusFilter: any,
  fromDate: any,
  toDate: any,
  
  columnVisibility: any,
  columnSizing: any,
  columnOrder: any,
  tableStatus: any,

  isSavedCookie: boolean,
  isLoadingSaveCookie: boolean,
}


const PAGE_NAME_PREFIX = 'v5-';
const CURRENT_PAGE = 1;
const PAGE_SIZE = 10;
const SORT_COLUMN = 'created';
const SORT_DIR = 'desc';
const SEARCH_QUERY = null;
const IS_INCLUDE_INACTIVE = null;
const IS_INACTIVE = null;

const createGenericSlice = <T,>(sliceName: string) => {
  const initialState: InitState<T> = {
    tableIsFirstLoad: true,
    
    tableIsLoading: false,
    tableTotal: 0,
    tableData: [],
    tableColumns: [],

    currentPage: CURRENT_PAGE,
    pageSize: PAGE_SIZE,
    sortColumn: SORT_COLUMN,
    sortDir: SORT_DIR,
    searchQuery: SEARCH_QUERY,
    isIncludeInactive: IS_INCLUDE_INACTIVE,
    isInactive: IS_INACTIVE,
    statusFilter: null,
    fromDate: null,
    toDate: null,
    
    columnVisibility: {},
    columnSizing: {},
    columnOrder: [],
    tableStatus: null,

    isSavedCookie: false,
    isLoadingSaveCookie: false,
  };


  const reducers: ValidateSliceCaseReducers<InitState<T>, SliceCaseReducers<InitState<T>>> = {
    setColumns: (state, action) => {
      state.tableColumns = action.payload;
    },
    setData: (state, action) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      let total = (action.payload && action.payload.total) ? action.payload.total : 0;

      state.tableData = data;
      state.tableTotal = total;
    },

    setOptions: (state, action) => {
      let options = action.payload;
      if(options){
        state.currentPage = (options.currentPage != undefined) ? options.currentPage : state.currentPage;
        state.pageSize = (options.pageSize != undefined) ? options.pageSize : state.pageSize;
        state.sortColumn = (options.sortColumn != undefined) ? options.sortColumn : state.sortColumn;
        state.sortDir = (options.sortDir != undefined) ? options.sortDir : state.sortDir;
        state.searchQuery = (options.searchQuery != undefined) ? options.searchQuery : state.searchQuery;
        state.isIncludeInactive = (options.isIncludeInactive == null || options.isIncludeInactive == false || options.isIncludeInactive == true) ? options.isIncludeInactive : state.isIncludeInactive;
        state.isInactive = (options.isInactive == null || options.isInactive == false || options.isInactive == true) ? options.isInactive : state.isInactive;
        state.statusFilter = (options.statusFilter != undefined) ? options.statusFilter : state.statusFilter;
        state.fromDate = (options.fromDate != undefined) ? options.fromDate : state.fromDate;
        state.toDate = (options.toDate != undefined) ? options.toDate : state.toDate;
        state.columnVisibility = (options.columnVisibility != undefined) ? options.columnVisibility : state.columnVisibility;
        state.columnSizing = (options.columnSizing != undefined) ? options.columnSizing : state.columnSizing;
        state.columnOrder = (options.columnOrder != undefined) ? options.columnOrder : state.columnOrder;
        state.tableStatus = (options.tableStatus != undefined) ? options.tableStatus : state.tableStatus;
        state.isSavedCookie = (options.isSavedCookie != undefined) ? options.isSavedCookie : state.isSavedCookie;
      }
    },

    setIsFirstLoad: (state, action) => {
      state.tableIsFirstLoad = action.payload;
    },

    setIsLoadingSaveCookie: (state, action) => {
      state.isLoadingSaveCookie = action.payload;
    },
    setTableStatus: (state, action) => {
      state.tableStatus = action.payload;
    },
    setColumnVisibility: (state, action) => {
      state.columnVisibility = action.payload;
    },
    setColumnSizing: (state, action) => {
      state.columnSizing = action.payload;
    },
    setColumnOrder: (state, action) => {
      state.columnOrder = action.payload;
    },

    startRead: (state, action) => {
      state.tableIsFirstLoad = action.payload;
      state.tableIsLoading = true;
    },
    finishRead: (state, action) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      let total = (action.payload && action.payload.total) ? action.payload.total : 0;

      state.tableData = data;
      state.tableTotal = total;

      state.tableIsLoading = false;
    },
    
    startDownload: (state, action) => {
      state.tableIsLoading = true;
    },
    finishDownload: (state, action) => {
      state.tableIsLoading = false;
    },
  };


  const { reducer, actions } = createSlice({
    name: sliceName,
    initialState,
    reducers
  })
  

  const apis = {
    callSetColumns: (columns: MRT_ColumnDef<any>[]) => async (dispatch: any) => {
      dispatch(actions.setColumns(columns));
    },

    callSetData: (data: any) => async (dispatch: any) => {
      dispatch(actions.setData(data));
    },

    callSetOptions: (options: any, callback: any = null) => async (dispatch: any) => {
      dispatch(actions.setOptions(options));

      if(callback){
        callback(options);
      }
    },

    callSetIsFirstLoad: (state: boolean) => async (dispatch: any) => {
      dispatch(actions.setIsFirstLoad(state));
    },
    
    callTableStatusApi: (val: any) => async (dispatch: any) => {
      dispatch(actions.setTableStatus(val));
    },

    callReadApi: (path: string, args: any, join: boolean = false, isFirstLoad = false, callback: (state: boolean, data: any) => void) => async (dispatch: any, getState: any) => {
      dispatch(actions.startRead(isFirstLoad));

      let slice = getState()[sliceName];
      let params = getOptions(slice, args, join);
      
      try {
        const result = await axios.get(path, { params: params });
        let data = result.data.data;
        let total = result.data.total;

        callback(true, data);
        dispatch(actions.finishRead({ data, total }));
      } catch (error) {
        errorAPI(error);

        callback(false, null);
        dispatch(actions.finishRead({ data: [], total: 0 }));
      }
    },

    callReadPostApi: (path: string, args: any, join: boolean = false, isFirstLoad = false, callback: (state: boolean, data: any) => void) => async (dispatch: any, getState: any) => {
      dispatch(actions.startRead(isFirstLoad));

      let slice = getState()[sliceName];
      let params = getOptions(slice, args, join);
      
      try {
        const result = await axios.post(path, params);
        let data = result.data.data;
        let total = result.data.total;

        callback(true, data);
        dispatch(actions.finishRead({ data, total }));
      } catch (error) {
        errorAPI(error);

        callback(false, null);
        dispatch(actions.finishRead({ data: [], total: 0 }));
      }
    },

    callDownloadApi: (path: string, args: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDownload(null));

      try {
        const result = await axios.get(path, { params: args });
        let data = result.data;

        callback(true, data);
        dispatch(actions.finishDownload({ data }));
      } catch (error) {
        errorAPI(error);

        callback(false, null);
        dispatch(actions.finishDownload({ data: null }));
      }
    },

    callReadUserCookiesApi: (obj: any, callback: (state: boolean, options: any) => void) => async (dispatch: any) => {
      let pageName = (obj && obj.pageName) ? PAGE_NAME_PREFIX + obj.pageName : null;
      let settingsType = (obj && isNumeric(obj.settingsType)) ? obj.settingsType : null;
      
      let searchQueryInit = (obj && obj.searchQuery) ? obj.searchQuery : SEARCH_QUERY;
      let sortColumnInit = (obj && obj.sortColumn) ? obj.sortColumn : SORT_COLUMN;
      let sortDirInit = (obj && obj.sortDir) ? obj.sortDir : SORT_DIR;
      let columnVisibilityInit = (obj && obj.columnVisibility) ? obj.columnVisibility : {};
      let columnSizingInit = (obj && obj.columnSizing) ? obj.columnSizing : {};
      let columnOrderInit = (obj && obj.columnOrder) ? obj.columnOrder : [];
      let tableStatusInit = (obj && obj.tableStatus) ? obj.tableStatus : null;
      let isIncludeInactiveInit = (obj && ((obj.isIncludeInactive == false) || (obj.isIncludeInactive == true))) ? obj.isIncludeInactive : null;
      let isInactiveInit = (obj && ((obj.isInactive == false) || (obj.isInactive == true))) ? obj.isInactive : null;
      let fromDateInit = (obj && obj.fromDate) ? obj.fromDate : initialState.fromDate;
      let toDateInit = (obj && obj.toDate) ? obj.toDate : initialState.toDate;
      let isSavedCookieInit = (obj && obj.isSavedCookie) ? obj.isSavedCookie : false;


      if(isNumeric(settingsType)){
        let settingsItem = getUserCookieSettings(settingsType, pageName);
        
        let valueLocalStorageStr: any = window.localStorage.getItem(pageName + '_' + settingsType);
        let valueLocalStorage = JSON.parse(valueLocalStorageStr);
        
        let currentPageValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.currentPage] && valueLocalStorage[PageCookieSettingType.currentPage] !== "" ? valueLocalStorage[PageCookieSettingType.currentPage] : initialState.currentPage;
        let searchQueryValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.searchQuery] && valueLocalStorage[PageCookieSettingType.searchQuery] !== "" ? valueLocalStorage[PageCookieSettingType.searchQuery] : searchQueryInit;
        let statusFilterValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.statusFilter] && valueLocalStorage[PageCookieSettingType.statusFilter] !== "" ? valueLocalStorage[PageCookieSettingType.statusFilter] : initialState.statusFilter;
        let fromDateValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.fromDate] && valueLocalStorage[PageCookieSettingType.fromDate] !== "" ? valueLocalStorage[PageCookieSettingType.fromDate] : fromDateInit;
        let toDateValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.toDate] && valueLocalStorage[PageCookieSettingType.toDate] !== "" ? valueLocalStorage[PageCookieSettingType.toDate] : toDateInit;
        
        let sortColumnValue = settingsItem && settingsItem[PageCookieSettingType.sortColumn] && settingsItem[PageCookieSettingType.sortColumn] !== "" ? settingsItem[PageCookieSettingType.sortColumn] : sortColumnInit;
        let sortDirValue = settingsItem && settingsItem[PageCookieSettingType.sortDir] && settingsItem[PageCookieSettingType.sortDir] !== "" ? settingsItem[PageCookieSettingType.sortDir] : sortDirInit;
        let pageSizeValue = settingsItem && settingsItem[PageCookieSettingType.pageSize] && settingsItem[PageCookieSettingType.pageSize] !== "" ? settingsItem[PageCookieSettingType.pageSize] : initialState.pageSize;
        
        let isIncludeInactiveValue = settingsItem && settingsItem[PageCookieSettingType.isIncludeInactive] !== "" ? settingsItem[PageCookieSettingType.isIncludeInactive] : isIncludeInactiveInit;
        let isInactiveValue = settingsItem && settingsItem[PageCookieSettingType.isInactive] !== "" ? settingsItem[PageCookieSettingType.isInactive] : isInactiveInit;

        let columnVisibilityValue = (settingsItem && settingsItem[PageCookieSettingType.columnVisibility]) ? settingsItem[PageCookieSettingType.columnVisibility] : columnVisibilityInit;
        let columnSizingValue = (settingsItem && settingsItem[PageCookieSettingType.columnSizing]) ? settingsItem[PageCookieSettingType.columnSizing] : columnSizingInit;
        let columnOrderValue = (settingsItem && settingsItem[PageCookieSettingType.columnOrder] && settingsItem[PageCookieSettingType.columnOrder].length > 0) ? settingsItem[PageCookieSettingType.columnOrder] : columnOrderInit;
        
        let tableStatusValue = (settingsItem && settingsItem[PageCookieSettingType.tableStatus]) ? settingsItem[PageCookieSettingType.tableStatus] : tableStatusInit;
        
        let isSavedCookieValue = (settingsItem && settingsItem[PageCookieSettingType.isSavedCookie] && settingsItem[PageCookieSettingType.isSavedCookie] !== '') ? settingsItem[PageCookieSettingType.isSavedCookie] : isSavedCookieInit;


        let options = {
          currentPage: currentPageValue,
          searchQuery: searchQueryValue,
          statusFilter: statusFilterValue,
          fromDate: fromDateValue,
          toDate: toDateValue,
          
          sortColumn: sortColumnValue,
          sortDir: sortDirValue,
          pageSize: pageSizeValue,
          isIncludeInactive: isIncludeInactiveValue,
          isInactive: isInactiveValue,

          columnVisibility: columnVisibilityValue,
          columnSizing: columnSizingValue,
          columnOrder: columnOrderValue,

          tableStatus: tableStatusValue,

          isSavedCookie: isSavedCookieValue,
        }
        callback(true, options);
      } else {
        callback(false, null);
      }
    },
    callSaveUserCookiesApi: (obj: any, callback: (state: boolean, data: any, apiParams: any) => void) => async (dispatch: any, getState: any) => {
      dispatch(actions.setIsLoadingSaveCookie(true));

      let pageName = (obj && obj.pageName) ? PAGE_NAME_PREFIX + obj.pageName : null;
      let settingsType = (obj && isNumeric(obj.settingsType)) ? obj.settingsType : null;
      
      
      if(isNumeric(settingsType)){
        let slice = getState()[sliceName];

        let columnVisibility = (obj && obj.columnVisibility) ? obj.columnVisibility : {};
        let columnSizing = (obj && obj.columnSizing) ? obj.columnSizing : {};
        let columnOrder = (obj && obj.columnOrder) ? obj.columnOrder : [];
        let sortColumn = (obj && obj.sortColumn) ? obj.sortColumn : slice.sortColumn;
        let sortDir = (obj && obj.sortDir) ? obj.sortDir : slice.sortDir;
        let tableStatus = (obj && obj.tableStatus) ? obj.tableStatus : null;
        let searchQuery = (obj && obj.searchQuery) ? obj.searchQuery : slice.searchQuery;
        let isIncludeInactive = (obj && ((obj.isIncludeInactive == false) || (obj.isIncludeInactive == true))) ? obj.isIncludeInactive : null;
        let isInactive = (obj && ((obj.isInactive == false) || (obj.isInactive == true))) ? obj.isInactive : null;
        let fromDate = (obj && obj.fromDate) ? obj.fromDate : slice.fromDate;
        let toDate = (obj && obj.toDate) ? obj.toDate : slice.toDate;
        let isSavedCookie = (obj && obj.isSavedCookie) ? obj.isSavedCookie : false;
        
        let options = {
          currentPage: slice.currentPage,
          searchQuery: searchQuery,
          statusFilter: slice.statusFilter,
          fromDate: fromDate,
          toDate: toDate,
          
          sortColumn: sortColumn,
          sortDir: sortDir,
          pageSize: slice.pageSize,
          isIncludeInactive: isIncludeInactive,
          isInactive: isInactive,

          columnVisibility: columnVisibility,
          columnSizing: columnSizing,
          columnOrder: columnOrder,

          tableStatus: tableStatus,
          isSavedCookie: isSavedCookie,
        }

        let apiOptions: any = {
          currentPage: slice.currentPage,
          pageSize: slice.pageSize,
          sortColumn: sortColumn,
          sortDir: sortDir,
          searchQuery: searchQuery,
          statusFilter: slice.statusFilter,
          fromDate: fromDate,
          toDate: toDate,
        }
        if(isIncludeInactive != null){
          apiOptions['isIncludeInactive'] = isIncludeInactive;
        }
        if(isInactive != null){
          apiOptions['isInactive'] = isInactive;
        }


        let valueLocalStorageStr = window.localStorage.getItem(pageName + '_' + settingsType);
        let valueLocalStorage = (valueLocalStorageStr && valueLocalStorageStr != '') ? JSON.parse(valueLocalStorageStr) : {};

        valueLocalStorage[PageCookieSettingType.currentPage] = options.currentPage;
        valueLocalStorage[PageCookieSettingType.searchQuery] = options.searchQuery;
        valueLocalStorage[PageCookieSettingType.statusFilter] = options.statusFilter;
        valueLocalStorage[PageCookieSettingType.fromDate] = options.fromDate;
        valueLocalStorage[PageCookieSettingType.toDate] = options.toDate;

        window.localStorage.setItem(pageName + '_' + settingsType, JSON.stringify(valueLocalStorage));
  
        let value = {
          [PageCookieSettingType.sortColumn]: options.sortColumn,
          [PageCookieSettingType.sortDir]: options.sortDir,
          [PageCookieSettingType.pageSize]: options.pageSize,
          [PageCookieSettingType.isIncludeInactive]: options.isIncludeInactive,
          [PageCookieSettingType.isInactive]: options.isInactive,
          [PageCookieSettingType.columnVisibility]: options.columnVisibility,
          [PageCookieSettingType.columnSizing]: options.columnSizing,
          [PageCookieSettingType.columnOrder]: options.columnOrder,
          [PageCookieSettingType.tableStatus]: options.tableStatus,
          [PageCookieSettingType.isSavedCookie]: options.isSavedCookie,
        };
        

        let data: any = {
          name: pageName,
          type: settingsType,
          value: JSON.stringify(value),
        };
  
        await axiosAuth.post('usercookiesetting', data).then(result => {
          let data = result.data;
          
          successAPI(data);

          let settings = (data && data.data) ? data.data : null;
          let settingsArr = [];
          if(settings){
            let userCookieSettings = getUserCookies();
            settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
            let settingsIndex = getUserCookieSettingsIndex(settingsArr, settingsType);
            if(settingsIndex !== -1 && settingsArr[settingsIndex]){
              let oldValue = JSON.parse(settingsArr[settingsIndex].value);
              let newValue = JSON.parse(settings.value);

              let value = Object.assign({}, oldValue);
              value[PageCookieSettingType.sortColumn] = newValue[PageCookieSettingType.sortColumn];
              value[PageCookieSettingType.sortDir] = newValue[PageCookieSettingType.sortDir];
              value[PageCookieSettingType.pageSize] = newValue[PageCookieSettingType.pageSize];
              value[PageCookieSettingType.isIncludeInactive] = newValue[PageCookieSettingType.isIncludeInactive];
              value[PageCookieSettingType.isInactive] = newValue[PageCookieSettingType.isInactive];
              value[PageCookieSettingType.columnVisibility] = newValue[PageCookieSettingType.columnVisibility];
              value[PageCookieSettingType.columnSizing] = newValue[PageCookieSettingType.columnSizing];
              value[PageCookieSettingType.columnOrder] = newValue[PageCookieSettingType.columnOrder];
              value[PageCookieSettingType.tableStatus] = newValue[PageCookieSettingType.tableStatus];
              value[PageCookieSettingType.isSavedCookie] = newValue[PageCookieSettingType.isSavedCookie];

              settingsArr[settingsIndex].updated = settings.updated;
              settingsArr[settingsIndex].value = JSON.stringify(value);
            } else {
              settingsArr.push(settings);
            }
          }

          callback(true, options, apiOptions);
          dispatch(actions.setIsLoadingSaveCookie(false));
        }).catch(error => {
          errorAPI(error);
          
          callback(false, null, null);
          dispatch(actions.setIsLoadingSaveCookie(false));
        });
      } else {
        callback(false, null, null);
        dispatch(actions.setIsLoadingSaveCookie(false));
      }
    },
  };


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default createGenericSlice;