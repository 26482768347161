import React from 'react';

import { Button } from 'react-bootstrap';
import { Image, Space, Card, Tooltip } from 'antd';
  
// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../utils/store.tsx';
import slice from './imagePreviewSlice.tsx';

import { downloadImageByUrl } from '../../utils/utils.tsx';

import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";

// ----------------------------------------------------------------------

const ImagePreview = ({ onRemove }: any) => {
    const { show, items, index } = useTypedSelector((state: RootState) => state.imagePreviewSlice);
    const dispatch = useDispatch<AppDispatch>();

    return <Image.PreviewGroup
        items={items}
        preview={{
            toolbarRender: (originalNode: any, info: any) => {
                return <Card size="small">
                    <Space size={20}>
                        <Tooltip overlayClassName='dark'  title='Download'>
                            <Button
                                variant={'custom-none-primary'}
                                onClick={() => {
                                    if(items && items.length > 0 && items[index]){
                                        downloadImageByUrl(items[index]);
                                    }
                                }}
                            >
                                <DownloadIcon />
                            </Button>
                        </Tooltip>
                        {onRemove && <Tooltip overlayClassName='dark'  title='Remove'>
                            <Button
                                variant={'custom-none-primary'}
                                onClick={() => {
                                    if(items && items.length > 0 && items[index]){
                                        onRemove(items[index], index);
                                    }
                                }}
                            >
                                <TrashIcon />
                            </Button>
                        </Tooltip>}
                    </Space>
                </Card>
            },
            visible: show,
            current: index,
            onVisibleChange: (visible: any) => {
                dispatch(slice.setVisibility(visible))
            },
            onChange: (current: any, prev: any) => {
                dispatch(slice.setIndex(current))
            },
        }}
    />
}

export default ImagePreview;