import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Switch, Checkbox, Tooltip, Divider } from "antd";
import CurrencyInput from 'react-currency-input-field';

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields } from '../slice.tsx';

// enums
import { minInputNumber, isNumeric } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';

import { StyledInputGroup } from '../../manage/jobs/jobTypes/form/Popup.tsx';

import { ReactComponent as PlusIcon } from "../../../../v5/assets/icons/plus-2.svg";
import { ReactComponent as MinusIcon } from "../../../../v5/assets/icons/minus.svg";
import { ReactComponent as InfoIcon } from "../../../../v5/assets/icons/i.svg";

// ----------------------------------------------------------------------

const StyledInfoIcon = styled(InfoIcon)`
  position: relative;
  top: 0px;
  width: 18px;
  height: 19px;
  margin-left: 5px;
  cursor: pointer;

  path {
    stroke: var(--bs-gray-400);
  }
`;


const MultipleTrips = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  // const { isLoading } = useTypedSelector((state: RootState) => state.jobFormSlice);
  // const dispatch = useDispatch<AppDispatch>();

  
  return <>
    <Form.Group as={Row} className='checkbox-field align-items-center w-100 g-0'>
      <Form.Label column xs={true} className='m-0'>{fields.multipleTrips.label}</Form.Label>
      <Col xs={'auto'}>
        <Switch
          checked={values.multipleTrip}
          onChange={(checked) => {
            setFieldValue('multipleTrip', checked)

            if(!checked){
              setFieldValue('numberOfTrip', 0);
              setFieldValue('assignToSameDriverVehicle', false);
            } else {
              setFieldValue('numberOfTrip', 2);
            }
          }}
        />
      </Col>
    </Form.Group>
    
    {values.multipleTrip && <Row className='checkbox-field align-items-center w-100 g-0 mt-8 px-12 py-16'>
      <Col xs={12}>
        <Row className='align-items-center w-100 g-0'>
          <Col xs={true}>
            {fields.numberOfTrip.label}
            <Tooltip title={fields.numberOfTrip.info}><StyledInfoIcon /></Tooltip>
          </Col>
          <Col xs={'auto'}>
            <Form.Group>
              <StyledInputGroup>
                <InputGroup.Text
                  onClick={() => {
                    const minNumb = (values.multipleTrip) ? 2 : 0;
                    let numb = isNumeric(values.numberOfTrip) ? parseInt(values.numberOfTrip) : 0;

                    if(numb > minNumb){
                      setFieldValue('numberOfTrip', (numb-1).toString())
                    }
                  }}
                >
                  <MinusIcon />
                </InputGroup.Text>
                <Form.Control
                  className='custom'
                  as={CurrencyInput}
                  allowDecimals={false}
                  allowNegativeValue={false}
                  decimalsLimit={0}

                  disabled={false}
                  autoComplete='off'
                  placeholder={''}
                  value={values.numberOfTrip}
                  onValueChange={(value: any) => {
                    setFieldValue('numberOfTrip', value)
                  }}
                  onBlur={async () => {
                    let numb = isNumeric(values.numberOfTrip) ? parseInt(values.numberOfTrip) : 0;

                    if(numb < 2){
                      await setFieldValue('numberOfTrip', 2);
                    }
                  }}
                  isInvalid={false}
                />
                <InputGroup.Text
                  onClick={() => {
                    let numb = isNumeric(values.numberOfTrip) ? parseInt(values.numberOfTrip) : 0;
                    setFieldValue('numberOfTrip', (numb+1).toString())
                  }}
                >
                  <PlusIcon />
                </InputGroup.Text>
              </StyledInputGroup>
            </Form.Group>
          </Col>
          <Col xs={12}><Divider /></Col>
        </Row>
        
        <Row className='align-items-center w-100 g-0 mt-12'>
          <Col xs={true}>{fields.assignToSameDriverVehicle.label}</Col>
          <Col xs={'auto'}>
            <Checkbox
              checked={values.assignToSameDriverVehicle}
              onChange={(e) => {
                setFieldValue('assignToSameDriverVehicle', e.target.checked)
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>}
  </>
}

export default MultipleTrips;
