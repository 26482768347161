import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Divider } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields, findStatus } from '../slice.tsx';
import jobTypeHistorySlice from './jobTypeHistory/slice.tsx';

// enums
// import {  } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';

import { ReactComponent as RefreshNewIcon } from "../../../../v5/assets/icons/refresh-new.svg";

// ----------------------------------------------------------------------

const JobType = React.forwardRef((props: any, ref: any) => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { id, jobTemplates } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();


  React.useImperativeHandle(
    ref,
    () => ({
      onSelectTemplate(item: any) {
        onSelectTemplate(item);
      },
    }),
  );


  React.useEffect(() => {
    dispatch(slice.callReadJobTemplateApi(null, (state: boolean, data: any) => {}))
  }, [])

  
  const onSelectTemplate = async (item: any) => {
    if(values.jobTemplateId === item.jobTemplateId){
      await setFieldValue('jobTemplateId', null);
      await setFieldValue('statusId', null);
      await setFieldValue('statusName', '');
      await setFieldValue('steps', []);

      dispatch(slice.clearJobTemplate());
    } else {
      let templateSteps = (item && item.templateSteps && item.templateSteps.length > 0) ? item.templateSteps : [];
      await setFieldValue('jobTemplateId', item.jobTemplateId);
      await setFieldValue('steps', templateSteps);


      dispatch(slice.callDetailsJobTemplateApi(item.jobTemplateId, (state: boolean, data: any) => {
        if(state){
          if(!(id && id > 0) && values.statusId === null){
            let statusUnassigned = findStatus(data?.templateStatuses, 'unassigned');
            if(statusUnassigned && statusUnassigned.jobStatusId) {
                let statusId = (statusUnassigned.jobStatusId) ? statusUnassigned.jobStatusId : null;
                let statusName = (statusUnassigned.jobStatusName) ? statusUnassigned.jobStatusName : '';
                
                setFieldValue('statusId', statusId);
                setFieldValue('statusName', statusName);
            }
          }
        }
      }));
    }
  }

  const getElements = () => {
    return <Row className='g-8 align-items-center'>
      {jobTemplates.map((item: any, i: number) => {
        return <Col key={'job_form_job_template_item_' + i} xs={4} md={3}>
          <Button
            className={'w-100 text-truncate'}
            size={'sm'}
            active={(values.jobTemplateId === item.jobTemplateId)}
            disabled={false}
            variant={'custom-label-outlined'}
            onClick={async () => {
              onSelectTemplate(item);
            }}
            title={item.jobTemplateName}
          >
            {item.jobTemplateName}
          </Button>
        </Col>
      })}
      
    </Row>
  }


  return <Col xs={12} xxl={10}>
    <Row className='gy-16 align-items-center'>
      <Col xs={12}>
        <Row className='g-16 align-items-center'>
          <Col xs={true}>
            <span className="large semibold gray-900">2. Job Type</span>
          </Col>
          <Col xs={'auto'}>
            <Button 
              variant={'custom-secondary'}
              size={'sm'}
              onClick={() => {
                dispatch(jobTypeHistorySlice.setShow({ show: true, id: values.jobTemplateId }));
              }}
            >
              <RefreshNewIcon /><span className='ps-s4'>Load History</span>
            </Button>
          </Col>
        </Row>
      </Col>

      <Col xs={12}>{getElements()}</Col>

      <Col xs={12}>
        <Divider />
      </Col>
    </Row>
  </Col>
});

export default JobType;
