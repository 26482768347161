export const LOGIN_PATH = '/auth/login';
export const PATH_AFTER_LOGIN = 'dashboard';
export const MAIN_BREAKPOINT = 'md';

export const STORAGE_KEY = 'accessToken';
export const STORAGE_USER = 'user';
export const STORAGE_USER_ACCOUNT = 'userAccount';
export const STORAGE_USER_COOKIES = 'userCookies';
export const STORAGE_EXPIRES_AT = 'tokenExpiresAt';


export const UserCookieSettingType = {
  Customer: 0,
  CustomerStandardService: 1,
  CustomerAdditionalService: 2,
  CustomerSite: 3,
  CustomerSiteStandardService: 4,
  CustomerSiteAdditionalService: 5,
  Job: 6,
  BillingBySite: 7,
  BillingByCustomer: 8,
  BillingByJobDates: 9,
  Reminders: 10,
  Alert: 11,
  CustomerServices: 12,
  BillingBySiteTable: 13,
  BillingBySitePanel: 14,
  BillingByCustomerDetails: 15,
  Manage: 16,
  AuditTrail: 17,
  GlobalService: 18,
  BillingCategory: 19,
  BinCenter: 20,
  CustomersEmail: 21,
};
export const PageCookieSettingType = {
  currentPage: 'current_page',
  searchQuery: 'search_query',
  sortColumn: 'sort_column',
  sortDir: 'sort_dir',
  pageSize: 'page_size',
  isIncludeInactive: 'is_include_inactive',
  isInactive: 'is_inactive',
  statusFilter: 'status_filter',
  fromDate: 'from_date',
  toDate: 'to_date',
  columnVisibility: 'column_visibility',
  columnSizing: 'column_sizing',
  columnOrder: 'column_order',
  tableStatus: 'table_status',
  isSavedCookie: 'is_saved_cookie',
};


export const Permissions = {
  All: 0,
  AuditTrail: [ 42 ],
  Dashboard: [ 12 ],
  Customers: [ 3, 4 ],
  CustomersOverview: [ 47 ],
  CustomerServices: [ 17 ],
  CustomerSites: [ 16 ],
  CustomerEmail: null,
  Jobs: [ 6, 7, 18 ],
  ManageJobs: [ 29, 25, 37 ],
}


export const AccountSettingType = {
  DefaultMapJobType: 0,
  DefaultMapPositionIsoCode: 1,
  DefaultMapPositionCountryName: 2,
  DefaultMapPositionLatitude: 3,
  DefaultMapPositionLongitude: 4,
  DefaultMapPositionZoom: 5,
  CurrencyCode: 6,
  CurrencyCodeValue: 7,
  AllowDefaultUomChange: 8,
  AllowDriverMultipleJobs: 9
};


export const XeroPaymentTermType = {
  DAYS_AFTER_BILL_DATE: 0,
  DAYS_AFTER_BILL_MONTH: 1,
  OF_CURRENT_MONTH: 2,
  OF_FOLLOWING_MONTH: 3,
};
export const XeroPaymentTermTypeItems = [
  {
      value: XeroPaymentTermType.DAYS_AFTER_BILL_DATE,
      title: 'day(s) after bill date',
  },
  {
      value: XeroPaymentTermType.DAYS_AFTER_BILL_MONTH,
      title: 'day(s) after bill month',
  },
  {
      value: XeroPaymentTermType.OF_CURRENT_MONTH,
      title: 'of the current month',
  },
  {
      value: XeroPaymentTermType.OF_FOLLOWING_MONTH,
      title: 'of the following month',
  }
];
export const AuditTrailObjectTypeText = [
    'Customer',
    'Customer Site',
    'Service',
    'Standard Service',
    'Additional Service',
    'Job Template',
    'Recurring Job',
    'Billing Profile',
    'Bin Type',
    'Waste Type',
    'Bin Number',
    'Driver',
    'Vehicle',
    'Payroll',
    'Reminder',
    'Job Working Time',
    'Job',
    '',
    '',
    'Driver Details Monhly',
];

export const CustomerSiteAvailability = {
  All: 0,
  SelectedHours: 1,
  NotAvailable: 2 
}

export const JobTemplateType = {
  Default: 0,
  Custom: 1,
}

export const JobStepType = {
  Pull: 0,
  Put: 1,
  Exchange: 2,
  OnTheSpot: 3,
  Out: 4,
  Shift: 5,
  ThrowAtCustomer: 6,
  Task: 7
}