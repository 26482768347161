import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Divider } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields, weekDayNumb, isWeekend } from '../slice.tsx';

// enums
import { getDispatchUser } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';
import Dropzone from '../../../../v5/components/dropzone/Dropzone.tsx';

// import { ReactComponent as XIcon } from "../../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------

const { 
  REACT_APP_DOC_GEN_API_URL
} = process.env;


const Signature = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  let dispatchUser: any = getDispatchUser();
  let dispatchAccountId = (dispatchUser && dispatchUser.accountId) ? dispatchUser.accountId : null;

  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { isLoading } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();


  const getElements = () => {
    return <Row className='g-32'>
      <Col xs={12} md={'auto'}>
        <Dropzone
          accept={'image/jpeg,image/jpg,image/png'}
          acceptText={'.jpeg, .jpg, .png'}
          method={'post'}
          name={'file'}
          action={REACT_APP_DOC_GEN_API_URL + 'doc-generation-job/upload-file'}
          headers={{
            'UploadPath': 'dispatch/' + dispatchAccountId + '/jobs/signature/attachment/',
            'Authorization': 'mWU89QAnk0ik'
          }}
          maxCount={1}
          maxSize={null}
          multiple={false}
          showDownload={true}
          canCrop={false}
          files={values.jobSignatureUrl}
          onChange={({ fileList: newFileList }: any) => {
            setFieldValue('jobSignatureUrl', newFileList);
          }}
          onRemoveFile={({ fileList: newFileList }: any) => {
            setFieldValue('jobSignatureUrl', newFileList);
          }}
        />
      </Col>
      <Col xs={12} md={4} lg={3} xl={4}>
        <Row className='g-16 align-items-center'>
          <Col xs={12}>
            <Form.Group>
              <Form.Label disabled={true}>{fields.jobSignedUserName.label}</Form.Label>
              <Form.Control
                type={'text'}
                autoComplete='off'
                placeholder={fields.jobSignedUserName.placeholder}
                disabled={false}
                value={values.jobSignedUserName}
                onChange={async (e) => {
                  await setFieldValue('jobSignedUserName', e.target.value);
                }}
                isInvalid={!!(errors && errors.jobSignedUserName)}
              />
              <Form.Control.Feedback type="invalid">{errors && errors.jobSignedUserName as ReactNode}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label disabled={true}>{fields.jobSignedUserContact.label}</Form.Label>
              <Form.Control
                type={'text'}
                autoComplete='off'
                placeholder={fields.jobSignedUserContact.placeholder}
                disabled={false}
                value={values.jobSignedUserContact}
                onChange={async (e) => {
                  await setFieldValue('jobSignedUserContact', e.target.value);
                }}
                isInvalid={!!(errors && errors.jobSignedUserContact)}
              />
              <Form.Control.Feedback type="invalid">{errors && errors.jobSignedUserContact as ReactNode}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Col>
    </Row>
  }

  return <Col xs={12} xxl={10}>
    <Row className='gy-16 align-items-center'>
    <Col xs={12}>
        <span className="large semibold gray-900">8. Signature</span>
      </Col>

      <Col xs={12}>{getElements()}</Col>
      
      <Col xs={12}>
        <Divider />
      </Col>
    </Row>
  </Col>
}

export default Signature;
