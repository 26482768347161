import React from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
} from 'react-bootstrap';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import { Spin } from "antd";

// Auth
// import { useAuthContext } from '../../../v5/auth/use-auth-context.js';
import { useRouter } from '../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector, Tables } from '../../../v5/utils/store.tsx';
import slice, { FormikContext, fields, initialValues, formSchema, prepareForm, prepareData } from './slice.tsx';

// enums
import { getIntProps, getBoolProps, updateRow, createRow, getMaxPageSize } from '../../../v5/utils/utils.tsx';
import { MAIN_BREAKPOINT, Permissions } from '../../../v5/utils/enums.tsx';

// components
import MainContainer from '../../../v5/components/container/MainContainer.jsx';
import createGenericSlice, { BackOptions } from '../../../v5/components/table/withSlice/tableSlice.tsx';

import Header from './form/Header.tsx';
import Footer from './form/Footer.tsx';
import PrintPreview from './form/printPreview/Page.tsx';
import RecurringJob from './form/recurringJob/Page.tsx';
import BillingJob from './form/billingJob/Page.tsx';
import WeightChit from './form/weightChit/Page.tsx';
import JobRoutes from './form/JobRoutes.tsx';
import JobSummary from './form/jobSummary/Page.tsx';
import Alerts from './form/Alerts.tsx';
import CustomerDetails from './form/CustomerDetails.tsx';
import JobType from './form/JobType.tsx';
import JobTypeHistory from './form/jobTypeHistory/Page.tsx';
import Location from './form/Location.tsx';
import JobSteps from './form/jobSteps/Page.tsx';
import BinDetails from './form/BinDetails.tsx';
import Services from './form/services/Page.tsx';
import DispatchOrder from './form/DispatchOrder.tsx';
import DriverOptions from './form/driverOptions/Page.tsx';
import Payment from './form/Payment.tsx';
import Attachments from './form/Attachments.tsx';
import Signature from './form/Signature.tsx';
import AuditTrail from './form/AuditTrail.tsx';

// import { ReactComponent as XIcon } from "../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------

const StyledLayoutWrapper = styled.div`
  width: 100%;
  display: inline-block;

  ${up('lg')} {
    height: calc(100vh - var(--header-height, 64px) - 5px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  ${down('lg')} {
    width: 100%;
  }

  .top-section {
    border-bottom: 1px solid var(--bs-gray-100);
    z-index: 1;

    ${up('lg')} {
      position: sticky;
      top: 0px;
      padding: 20px 32px;
    }
  
    ${down('lg')} {
      position: relative;
      padding: 20px 16px;
    }
  }

  .content-section {
    width: 100%;

    ${up('lg')} {
      display: inline-flex;
      height: calc(100vh - var(--header-height, 64px) - var(--job-form-top-height, 72px) - var(--job-form-footer-height, 72px) - 7px);
      padding: 24px 32px;
    }
  
    ${down('lg')} {
      display: block;
      height: auto;
      padding: 24px 16px;
    }

    .form-section {
      ${up('xl')} {
        overflow: auto;
      }

      ${up('lg')} {
        width: calc(100% - 470px);
      }
    
      ${down('lg')} {
        width: 100%;
        margin-bottom: 32px;
      }

      > div {
        width: 100%;

        > div {
          padding-right: 0px;
          justify-items: center;
          
          > div {
            width: 100%;

            > div {
              ${down('lg')} {
                padding-right: 0px;
              }
            }
          }
        }
      }

      .ant-divider {
        margin: 16px 0px 0px 0px;
      }
    }

    .side-section {
      overflow: auto;
      
      ${up('lg')} {
        width: 470px;
      }
    
      ${down('lg')} {
        width: 100%;
      }

      > div {
        width: 100%;

        > div {
          padding-right: 0px;
          justify-items: flex-end;

          ${down('lg')} {
            justify-items: center;
          }
          
          > div {
            width: 100%;

            > div {
              padding-right: 0px;
            }
          }
        }
      }
    }
  }

  .footer-section {
    border-top: 1px solid var(--bs-gray-100);
    position: sticky;
    bottom: 0px;
    background-color: white;
    z-index: 2;
    
    ${up('lg')} {
      padding: 20px 32px;
    }
  
    ${down('lg')} {
      padding: 20px 16px;
    }
  }
`;


// const table = createGenericSlice<any>(Tables.Jobs);

const FormComponent = () => {
  // const location = useLocation();
  const router = useRouter();

  const topHeight = document.getElementById('top_ID')?.clientHeight || 72;
  const footerHeight = document.getElementById('footer_ID')?.clientHeight || 72;
  document.documentElement.style.setProperty('--job-form-top-height', `${(topHeight)}px`);
  document.documentElement.style.setProperty('--job-form-footer-height', `${(footerHeight)}px`);

  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  // const formikRef = React.useRef<any>(null);

  const { isLoading, id, details, isLoadingCreditLimit, isCreditLimit, isLoadingJobTemplates } = useTypedSelector((state: RootState) => state.jobFormSlice);
  // const { tableData, tableTotal } = useTypedSelector((state: RootState) => state.tableJobs);
  const dispatch = useDispatch<AppDispatch>();

  const [backLink, _setBackLink] = React.useState<any>('/jobs');

  const jobTypeRef = React.useRef<any>(null);


  React.useEffect(() => {
    let id = getIntProps();
    dispatch(slice.setId(id));

    dispatch(slice.callLatestLocationDriversApi([], (state: Boolean, data: any) => {}));

    let params = {
      currentPage: 1,
      pageSize: getMaxPageSize(),
      sortColumn: 'locationName',
      sortDir: 'asc',
    };
    dispatch(slice.callReadDefaultLocationApi(params, (state: boolean, data: any) => {}));
  }, []);

  React.useEffect(() => {
    if(id && id > 0){
      dispatch(slice.callDetailsApi(id, (state: boolean, data: any) => {}));
    }
  }, [id]);

  React.useEffect(() => {
    dispatch(slice.setLoading(true));
    let form = prepareForm(details, initialValues);
    setValues(form);
    dispatch(slice.setLoading(false));
  }, [details]);


  const formik = useFormik({
    initialValues: initialValues,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: formSchema(null),
    onSubmit: values => {
      onSubmit(values, (data: any) => {});
    },
  });
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;
  // formikRef.current = formik;

  const onSubmit = (values: any, callback: (data: any) => void) => {
    let isCopy = getBoolProps();
    let data = prepareData(values, id, isCopy);

    if(id && id > 0){
      if(isCopy){
        dispatch(slice.callCreateApi(data, (state: boolean, data: any) => {
          if(state){
            // let rows = createRow(tableData, 'jobId', { ...data, isNew: true });
            // dispatch(table.callSetData({ data: rows, total: (tableTotal+1)}));
            onCancel(BackOptions.Create, data);
          }
        }));
      } else {
        dispatch(slice.callUpdateApi(data, (state: boolean, data: any) => {
          if(state){
            // let rows = updateRow(tableData, 'jobId', { ...data, isNew: false });
            // dispatch(table.callSetData({ data: rows, total: tableTotal}));
            onCancel(BackOptions.Update, data);
          }
        }));
      }
    } else {
      dispatch(slice.callCreateApi(data, (state: boolean, data: any) => {
        if(state){
          // let rows = createRow(tableData, 'jobId', { ...data, isNew: true });
          // dispatch(table.callSetData({ data: rows, total: (tableTotal+1)}));
          onCancel(BackOptions.Create, data);
        }
      }));
    }
  }
  const onCancel = (backOptions: number = BackOptions.Read, data: any = null) => {
    let form = prepareForm(null, initialValues);
    setValues(form);
    dispatch(slice.setValues(form));
    dispatch(slice.setId(null));
    dispatch(slice.resetSlice());
    
    if(backOptions === BackOptions.Create){
      router.pushArgs(backLink, { backOptions: backOptions, backData: data });
    } else if(backOptions === BackOptions.Update){
      router.pushArgs(backLink, { backOptions: backOptions, backData: data });
    } else {
      router.push(backLink);
    }
  }


  const setLayout = () => {
    return <StyledLayoutWrapper>
      <div id={'top_ID'} className='top-section'>
        <Header
          onCancel={() => {
            onCancel();
          }}
        />
      </div>

      <div id={'content_ID'} className='content-section'>
        <div className='form-section'>
          <Row className='gy-32 align-items-center'>
            <Alerts />
            <CustomerDetails />
            {values.customerId && <JobType ref={jobTypeRef} />}
            {values.jobTemplateId && <Location />}
            {!isCreditLimit && <>
              {values.jobTemplateId && <BinDetails />}
              {values.jobTemplateId && <DispatchOrder />}
              {values.jobTemplateId && <Payment />}
              {values.jobTemplateId && <Attachments />}
              {values.jobTemplateId && <Signature />}
              {values.jobTemplateId && <AuditTrail />}
            </>}
          </Row>
        </div>

        <div className='side-section'>
          <Row className='gy-32 align-items-center'>
            <JobRoutes />
          </Row>
        </div>
      </div>

      <div id={'footer_ID'} className='footer-section'>
        <Footer />
      </div>
    </StyledLayoutWrapper>
  }


  return <MainContainer className={'p-0'} title={'Job form'} isLoading={false} roles={Permissions.Jobs}>
    <FormikContext.Provider value={formik}>
      <Spin spinning={(
        isLoading || 
        isLoadingCreditLimit || 
        isLoadingJobTemplates
      )} wrapperClassName={'no-height'}>{setLayout()}</Spin>
    </FormikContext.Provider>

    <JobTypeHistory
      onSave={(selectedId: number|null, selectedItem: any) => {
        if(jobTypeRef && jobTypeRef.current){
          jobTypeRef.current.onSelectTemplate(selectedItem);
        }
      }}
    />
    <JobSteps />
    <JobSummary />
    <Services />
    <DriverOptions
      onSave={(steps: Array<any>) => {
        setFieldValue('steps', steps);
      }}
    />
    <PrintPreview />
    <RecurringJob />
    <BillingJob />
    <WeightChit />
  </MainContainer>
}

export default FormComponent;
