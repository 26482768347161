import React, { ReactNode } from 'react';
import _ from 'lodash';
import moment from 'moment';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
  InputGroup,
  Button,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Switch, Checkbox, Tooltip, Spin, Divider } from "antd";
import CurrencyInput from 'react-currency-input-field';
import InputMask from 'react-input-mask';
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields } from '../slice.tsx';
import confirmSlice from '../../../components/modal/confirmSlice.tsx';

// enums
import { warning, dateFormat, timeFormat, getDateObjectTime, getDaysBetween, addDate, isValidDate, isNumeric } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';

import { StyledInputGroup } from '../../manage/jobs/jobTypes/form/Popup.tsx';

import { ReactComponent as PlusIcon } from "../../../../v5/assets/icons/plus-2.svg";
import { ReactComponent as MinusIcon } from "../../../../v5/assets/icons/minus.svg";
import { ReactComponent as CalendarIcon } from "../../../../v5/assets/icons/calendar.svg";
import { ReactComponent as ClockIcon } from "../../../../v5/assets/icons/clock.svg";
import { ReactComponent as InfoIcon } from "../../../../v5/assets/icons/i.svg";

// ----------------------------------------------------------------------

const StyledInfoIcon = styled(InfoIcon)`
  position: relative;
  top: 0px;
  width: 18px;
  height: 19px;
  margin-left: 5px;
  cursor: pointer;

  path {
    stroke: var(--bs-gray-400);
  }
`;


const CollectBin = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { isLoadingCheckAllowPullJob } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();

  
  return <>
    <Form.Group as={Row} className='checkbox-field align-items-center w-100 g-0'>
      <Form.Label column xs={true} className='m-0'>{fields.collectBin.label}</Form.Label>
      <Col xs={'auto'}>
        <Switch
          checked={values.haveFollowUp}
          onChange={(checked) => {
            setFieldValue('haveFollowUp', checked)
            setFieldValue('followUpDate', null);
            setFieldValue('followUpTimeSpecific', null);
            setFieldValue('followUpAssignToSameDriverVehicle', false);

            if(!checked){
              setFieldValue('followUpDays', 0);
            } else {
              dispatch(slice.callCheckAllowPullJobApi((state: boolean, data: any) => {
                if(data){
                  if(checked){
                    let valueDate = (values.jobDate && values.jobDate != '') ? moment(values.jobDate, dateFormat()) : moment();
                    let newDate = valueDate.clone().add(7, 'd');
                    let days = getDaysBetween(newDate, valueDate);

                    setFieldValue('followUpDays', days);
                    setFieldValue('followUpDate', newDate.format(dateFormat()));
                  } else {
                    setFieldValue('followUpDays', 0);
                  }

                  // if (!values.isRequireBinNumberToEnd1) {
                  //   Show popup
                  // }

                  dispatch(confirmSlice.show(() => {
                    dispatch(confirmSlice.setHide());
                  }, {
                    title: 'Collect Bin Info',
                    body: 'Options "Driver is required to input bin ID" will change to YES for this job. Do you want to continue?',
                    size: 'md',
                    cancelText: 'No',
                    cancelVariant: 'custom-outlined',
                    cancelCallback: () => {
                      setFieldValue('haveFollowUp', false);
                      setFieldValue('followUpDays', 0);
                    },
                    confirmText: 'Yes',
                    confirmVariant: 'custom-primary',
                  }));
                  
                } else {
                  setFieldValue('haveFollowUp', false);
                  setFieldValue('followUpDays', 0);
                  
                  warning('Warning', 'Please activate default PULL job to create Collect Bin job');
                }
              }));
            }
          }}
        />
      </Col>
    </Form.Group>
    
    {values.haveFollowUp && <Row className='checkbox-field align-items-center w-100 g-0 mt-8 px-12 py-16'>
      <Spin spinning={isLoadingCheckAllowPullJob}>
        <Col xs={12}>
          <Row className='align-items-center w-100 g-0'>
            <Col xs={true}>
              {fields.followUpDays.label}
              <Tooltip title={fields.followUpDays.info}><StyledInfoIcon /></Tooltip>
            </Col>
            <Col xs={'auto'}>
              <Form.Group>
                <StyledInputGroup>
                  <InputGroup.Text
                    onClick={async () => {
                      const minNumb = 0;
                      let numb = isNumeric(values.followUpDays) ? parseInt(values.followUpDays) : 0;

                      if(numb > minNumb){
                        let newNumb = (numb-1);
                        await setFieldValue('followUpDays', newNumb.toString())

                        try {
                          let prevDate = (values.jobDate && values.jobDate != '') ? moment(values.jobDate, dateFormat()) : moment();
                          let newDate = addDate(newNumb, prevDate);
                          setFieldValue('followUpDate', newDate.format(dateFormat()));
                        }catch(e){}
                      }
                    }}
                  >
                    <MinusIcon />
                  </InputGroup.Text>
                  <Form.Control
                    className='custom'
                    as={CurrencyInput}
                    allowDecimals={false}
                    allowNegativeValue={false}
                    decimalsLimit={0}
                    min={0}

                    disabled={false}
                    autoComplete='off'
                    placeholder={''}
                    value={values.followUpDays}
                    onValueChange={(value: any) => {
                      setFieldValue('followUpDays', value)
                      
                      try {
                        let prevDate = (values.jobDate && values.jobDate != '') ? moment(values.jobDate, dateFormat()) : moment();
                        let newDate = addDate(value, prevDate);
                        setFieldValue('followUpDate', newDate.format(dateFormat()));
                      }catch(e){}
                    }}
                    isInvalid={false}
                  />
                  <InputGroup.Text
                    onClick={async () => {
                      let numb = isNumeric(values.followUpDays) ? parseInt(values.followUpDays) : 0;
                      let newNumb = (numb+1);
                      await setFieldValue('followUpDays', newNumb.toString())
                      
                      try {
                        let prevDate = (values.jobDate && values.jobDate != '') ? moment(values.jobDate, dateFormat()) : moment();
                        let newDate = addDate(newNumb, prevDate);
                        setFieldValue('followUpDate', newDate.format(dateFormat()));
                      }catch(e){}
                    }}
                  >
                    <PlusIcon />
                  </InputGroup.Text>
                </StyledInputGroup>
              </Form.Group>
            </Col>
            <Col xs={12}><Divider /></Col>
          </Row>
          
          <Row className='align-items-center w-100 g-0 mt-12'>
            <Col xs={true}>{fields.followUpDate.label}</Col>
            <Col xs={'auto'}>
              <Form.Group>
                <InputGroup>
                  <Form.Control
                    style={{ width: '150px' }}
                    as = {InputMask}
                    mask={'99-99-9999'}
                    maskChar={null}
                    placeholder={dateFormat()}
                    value={values.followUpDate}
                    onChange={(e) => {
                      setFieldValue('followUpDate', e.target.value);
                      
                      try {
                        if(isValidDate(e.target.value)){
                          let prevDate = (values.jobDate && values.jobDate != '') ? moment(values.jobDate, dateFormat()) : moment();
                          let days = getDaysBetween(moment(e.target.value, dateFormat()), prevDate);
                          setFieldValue('followUpDays', days);
                        }
                      }catch(e){}
                    }}
                    isInvalid={!!(errors && errors.followUpDate)}
                  />
                  <InputGroup.Text>
                    <DatePicker
                      portal={true}
                      onOpenPickNewDate={false}
                      shadow={false}
                      disabled={false}
                      calendarPosition={'bottom-right'} 
                      format={dateFormat()}
                      value={values.followUpDate}
                      onChange={(value: DateObject) => {
                        setFieldValue('followUpDate', value.format(dateFormat()));
                        
                        let prevDate = (values.jobDate && values.jobDate != '') ? moment(values.jobDate, dateFormat()) : moment();
                        let days = getDaysBetween(moment(value.format(dateFormat()), dateFormat()), prevDate);
                        setFieldValue('followUpDays', days);
                      }}
                      render={(value, openCalendar) => {
                        return <Button variant={'custom-none-primary'} onClick={openCalendar}><CalendarIcon /></Button>
                      }}
                    />
                  </InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback type="invalid">{errors && errors.followUpDate as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12}><Divider /></Col>
          </Row>

          <Row className='align-items-center w-100 g-0 mt-12'>
            <Col xs={true}>{fields.followUpTimeSpecific.label}</Col>
            <Col xs={'auto'}>
              <Form.Group>
                <InputGroup>
                  <Form.Control
                    style={{ width: '150px' }}
                    as = {InputMask}
                    mask={'99:99'}
                    maskChar={null}
                    placeholder={timeFormat()}
                    value={values.followUpTimeSpecific}
                    onChange={(e) => {
                      setFieldValue('followUpTimeSpecific', e.target.value);
                    }}
                    isInvalid={!!(errors && errors.followUpTimeSpecific)}
                  />
                  <InputGroup.Text>
                    <DatePicker
                      portal={true}
                      onOpenPickNewDate={false}
                      shadow={false}
                      disabled={false}
                      calendarPosition={'bottom-right'}
                      format={timeFormat()}
                      value={getDateObjectTime(values.followUpTimeSpecific)}
                      onChange={(value: DateObject) => {
                        setFieldValue('followUpTimeSpecific', value.format(timeFormat()));
                      }}
                      disableDayPicker
                      render={(value, openCalendar) => {
                        return <Button variant={'custom-none-primary'} onClick={openCalendar}><ClockIcon /></Button>
                      }}
                      plugins={[
                        <TimePicker hideSeconds />
                      ]}
                    />
                  </InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback type="invalid">{errors && errors.followUpTimeSpecific as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12}><Divider /></Col>
          </Row>

          <Row className='align-items-center w-100 g-0 mt-12'>
            <Col xs={true}>{fields.followUpAssignToSameDriverVehicle.label}</Col>
            <Col xs={'auto'}>
              <Checkbox
                checked={values.followUpAssignToSameDriverVehicle}
                onChange={(e) => {
                  setFieldValue('followUpAssignToSameDriverVehicle', e.target.checked)
                }}
              />
            </Col>
          </Row>
        </Col>
      </Spin>
    </Row>}
  </>
}

export default CollectBin;
