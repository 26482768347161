import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface InitState {
  isLoading: boolean,
  show: boolean,
  title: string,
  body: string,
  size?: 'sm' | 'lg' | 'xl',
  cancelText: string,
  cancelVariant: string,
  cancelCallback: any,
  confirmText: string,
  confirmVariant: string,
  confirmCallback: any,
}


function NewReducer() {
  const name = 'confirmSlice';

  
  let callback: (() => void) | null = null;


  const initialState: InitState = {
    isLoading: false,
    show: false,
    title: 'Remove',
    body: 'Are you sure?',
    size: 'sm',
    cancelText: 'Cancel',
    cancelVariant: 'custom-outline',
    cancelCallback: null,
    confirmText: 'Confirm',
    confirmVariant: 'custom-danger',
    confirmCallback: null,
  };


  const reducers = {
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ title: string, body: string, size: 'sm' | 'lg' | 'xl', cancelText: string, cancelVariant: string, cancelCallback: any, confirmText: string, confirmVariant: string, confirmCallback: any }>) => {
        state.isLoading = false;
        state.title = action.payload.title;
        state.body = action.payload.body;
        state.size = action.payload.size;
        state.cancelText = action.payload.cancelText;
        state.cancelVariant = action.payload.cancelVariant;
        state.cancelCallback = action.payload.cancelCallback;
        state.confirmText = action.payload.confirmText;
        state.confirmVariant = action.payload.confirmVariant;
        state.confirmCallback = action.payload.confirmCallback;
        state.show = true;
    },
    hide: (state: InitState) => {
        callback = null;
        state.isLoading = false;
        state.show = false;
    },
  };


  const apis = {
    show: (callbackFunc: any, options: any = null) => async (dispatch: any) => {
        callback = callbackFunc || null;
        let opt = options ? options : {
            title: initialState.title,
            body: initialState.body,
            size: initialState.size,
            cancelText: initialState.cancelText,
            cancelVariant: initialState.cancelVariant,
            cancelCallback: initialState.cancelCallback,
            confirmText: initialState.confirmText,
            confirmVariant: initialState.confirmVariant,
            confirmCallback: initialState.confirmCallback,
        }
        dispatch(actions.setShow(opt));
    },
    confirm: () => async (dispatch: any) => {
      if(typeof callback === 'function'){
        callback();
      } else {
        dispatch(apis.apply());
      }
    },
    apply: () => async (dispatch: any, getState: any) => {
      const state = getState().confirmSlice;
      
      if(typeof state.confirmCallback === 'function'){
        state.confirmCallback();
      }
    },
    setHide: (callCallback: boolean = false) => async (dispatch: any, getState: any) => {
      const state = getState().confirmSlice;
      dispatch(actions.hide());

      if(callCallback){
        if(typeof state.cancelCallback === 'function'){
          state.cancelCallback();
        }
      }
    }
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();