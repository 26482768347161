/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../../../utils/axios';
import { ReadAPIParams, success, successAPI, errorAPI, placeholderRows, isNumeric } from '../../../../utils/utils';
import Yup from '../../../../utils/yup';
// import {  } from '../../../../utils/enums';


export const JobTemplateType = {
  Default: 0,
  Custom: 1,
}
export const JobStepType = {
  Pull: 0,
  Put: 1,
  Exchange: 2,
  OnTheSpot: 3,
  Out: 4,
  Shift: 5,
  ThrowAtCustomer: 6,
  Task: 7,
  Custom: 8,
}

export const CustomOptions = {
  No: 0,
  Yes: 1,
  Optional: 2,
}

export interface LocationStruct {
  CustomerSite: number,
  Yard: number,
  Landfill: number,
};
export interface LocationBoolStruct {
  CustomerSite: boolean,
  Yard: boolean,
  Landfill: boolean,
};
export const LocationEnum = {
  CustomerSite: 0,
  Yard: 1,
  Landfill: 2,
};

export interface BinActivityStruct {
  PickLoadedBin: number,
  DropLoadedBin: number,
  ExchangeBin: number,
  NoActivity: number,
  WasteDisposal: number,
  LoadWaste: number,
  PickEmptyBin: number,
  DropEmptyBin: number,
};
export interface BinActivityBoolStruct {
  PickLoadedBin: boolean,
  DropLoadedBin: boolean,
  ExchangeBin: boolean,
  NoActivity: boolean,
  WasteDisposal: boolean,
  LoadWaste: boolean,
  PickEmptyBin: boolean,
  DropEmptyBin: boolean,
};
export const BinActivityEnum: BinActivityStruct = {
  PickLoadedBin: 0,
  DropLoadedBin: 1,
  ExchangeBin: 2,
  NoActivity: 3,
  WasteDisposal: 4,
  LoadWaste: 5,
  PickEmptyBin: 6,
  DropEmptyBin: 7,
};

export interface DriverAppOptionsStruct {
  RequiredBinID: number,
  TakePhoto: number,
  Signature: number,
  InputBinWeight: number,
  ReviewWasteType: number,
  ChangeBin: number,
  ChangeWaste: number,
  NewBinID: number,
};
export interface DriverAppOptionsBoolStruct {
  RequiredBinID: boolean,
  TakePhoto: boolean,
  Signature: boolean,
  InputBinWeight: boolean,
  ReviewWasteType: boolean,
  ChangeBin: boolean,
  ChangeWaste: boolean,
  NewBinID: boolean,
};
export const DriverAppOptionsEnum = {
  RequiredBinID: 0,
  TakePhoto: 1,
  Signature: 2,
  InputBinWeight: 3,
  ReviewWasteType: 4,
  ChangeBin: 5,
  ChangeWaste: 6,
  NewBinID: 7,
};
export const DriverAppOptionsLabelEnum = {
  RequiredBinID: 'The driver is required to input bin ID',
  TakePhoto: 'The driver is required to take',
  TakePhotoSuffix: 'photo',
  Signature: 'The driver is required to take signature',
  InputBinWeight: 'The driver is required to input bin weight',
  ReviewWasteType: 'The driver is required to review waste type',
  ChangeBin: 'The driver can change bin type in driver app',
  ChangeWaste: 'The driver can change waste type in driver app',
  NewBinID: 'The driver can add new Bin ID in driver app',
};


export const fields = {
  isActive: {
    id: 'isActive',
    label: 'Status',
    labelTable: 'Status',
    placeholder: '',
  },

  jobTemplateType: {
    id: 'jobTemplateType',
    label: 'Types',
    labelTable: 'Types',
    placeholder: '',
  },
  binActivity: {
    id: 'binActivity',
    label: 'Bin activity',
    labelTable: 'Bin activity',
    placeholder: '',
  },
  defaultLocationId: {
    id: 'defaultLocationId',
    label: 'Default location',
    labelTable: 'Default location',
    placeholder: '',
  },

  jobTemplateName: {
    id: 'jobTemplateName',
    label: 'Job Type Name',
    labelTable: 'Names',
    placeholder: 'Enter job type name',
  },
  jobTemplateDescription: {
    id: 'jobTemplateDescription',
    label: 'Job Type Description',
    labelTable: 'Descriptions',
    placeholder: placeholderRows(5, 'Enter job type description', ''),
    rows: 5,
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    isActive: Yup.bool().oneOf([true, false]),
    isBillable: Yup.bool().oneOf([true, false]),

    jobTemplateName: Yup.string().required().label(fields.jobTemplateName.label),
    jobTemplateDescription: Yup.string().label(fields.jobTemplateDescription.label),

    templateSteps: Yup.array().of(Yup.object().shape({
      stepName: Yup.string().nullable(),
      stepTitle: Yup.string().nullable(),
      stepDescription: Yup.string().nullable(),

      emptyBinCountAfterAction: Yup.number().nullable(),
      loadedBinCountAfterAction: Yup.number().nullable(),
      stepOrder: Yup.number().nullable(),

      locationType: Yup.number().nullable(),
      hasDefaultSite: Yup.bool().oneOf([true, false]),
      binActivity: Yup.number().min(0).nonNullable().required().label(fields.binActivity.label),
      
      defaultLocationId: Yup.number().when(['hasDefaultSite', 'locationType', 'templateStepSites'], ([hasDefaultSite, locationType, templateStepSites]) => {
        if(templateStepSites && templateStepSites.length > 0){
          if(((locationType === LocationEnum.Yard) || (locationType === LocationEnum.Landfill)) && hasDefaultSite){
            return Yup.number().min(0).nonNullable().required().label(fields.defaultLocationId.label)
          } else {
            return Yup.number().nullable().label(fields.defaultLocationId.label)
          }
        } else {
          return Yup.number().nullable().label(fields.defaultLocationId.label)
        }
      }),

      isEditableBinNumber: Yup.bool().oneOf([true, false]),
      isRequireBinNumber: Yup.bool().oneOf([true, false]),
      isRequireBinWeight: Yup.bool().oneOf([true, false]),
      isRequireReviewWasteType: Yup.bool().oneOf([true, false]),
      canDriverChangeBinType: Yup.bool().oneOf([true, false]),
      canDriverChangeWasteType: Yup.bool().oneOf([true, false]),
      mustTakePhoto: Yup.number().nullable(),
      numberofPhotosRequired: Yup.number().nullable(),
      mustTakeSignature: Yup.number().nullable(),

      templateStepSites: Yup.array().when(['hasDefaultSite', 'locationType'], ([hasDefaultSite, locationType]) => {
        if(((locationType === LocationEnum.Yard) || (locationType === LocationEnum.Landfill)) && hasDefaultSite){
          return Yup.array().of(Yup.object().shape({
            isDefault: Yup.bool().oneOf([true, false]),
            locationName: Yup.string().nullable().required(),
            address: Yup.string().nullable().required(),
            latitude: Yup.number().nullable(),
            longitude: Yup.number().nullable(),
          }))
        } else {
          return Yup.array().of(Yup.object().shape({
            isDefault: Yup.bool().oneOf([true, false]),
            locationName: Yup.string().nullable(),
            address: Yup.string().nullable(),
            latitude: Yup.number().nullable(),
            longitude: Yup.number().nullable(),
          }))
        }
      }),
    })),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null, defaultLocations: Array<any> = []) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let isActive = ((form.isActive == false) || (form.isActive == true)) ? form.isActive : false;
    let isBillable = ((form.isBillable == false) || (form.isBillable == true)) ? form.isBillable : false;
    
    let jobTemplateName = (form.jobTemplateName && form.jobTemplateName !== '') ? form.jobTemplateName : '';
    let jobTemplateDescription = (form.jobTemplateDescription && form.jobTemplateDescription !== '') ? form.jobTemplateDescription : '';
    
    let jobTemplateType = (form && isNumeric(form.jobTemplateType)) ? form.jobTemplateType : JobTemplateType.Custom;
    let stepType = (form && isNumeric(form.stepType)) ? form.stepType : JobStepType.Custom;
    
    let defaultLocationsArr: Array<StepSiteStruct> = [];
    if(defaultLocations && defaultLocations.length > 0){
      defaultLocationsArr = defaultLocations.map((location: any, l: number) => {
        return {
          defaultLocationId: location.defaultLocationId,
          isDefault: location.isDefault,
          locationName: location.locationName,
          addressField: location.address,
          address: location.address,
          latitude: location.latitude,
          longitude: location.longitude,
        };
      });
    }

    let defaultLocationId = (form && isNumeric(form.defaultLocationId)) ? form.defaultLocationId : null;

    let steps: Array<StepStruct> = [];
    if(form.templateSteps && form.templateSteps.length > 0){
      steps = form.templateSteps.sort((a: any,b: any) => a.stepOrder - b.stepOrder).map((step: any, i: number) => {
        let defaultLocationsArrCloned = _.cloneDeep(defaultLocationsArr);

        if(defaultLocationsArrCloned && defaultLocationsArrCloned.length > 0){
          defaultLocationsArrCloned.forEach((location: any, l: number) => {
            let isDefault = (location.defaultLocationId === defaultLocationId) ? true : false;
            location.isDefault = isDefault;
          });
        }

        let locationType = step.locationType;
        if(jobTemplateType === JobTemplateType.Default){
          if(step.hasCustomerSite === false){
            locationType = LocationEnum.Yard
          }
        }
        

        return {
          stepName: step.stepName,
          stepTitle: step.stepTitle,
          stepDescription: step.stepDescription,

          emptyBinCountAfterAction: step.emptyBinCountAfterAction,
          loadedBinCountAfterAction: step.loadedBinCountAfterAction,
          stepOrder: step.stepOrder,
          stepType: stepType,

          locationType: locationType,
          hasDefaultSite: step.hasDefaultSite,
          binActivity: step.binActivity,
          defaultLocationId: step.defaultLocationId,
          
          isEditableBinNumber: step.isEditableBinNumber,
          isRequireBinNumber: step.isRequireBinNumberToStart,
          isRequireBinWeight: step.isRequireBinWeight,
          isRequireReviewWasteType: step.isRequireReviewWasteType,
          canDriverChangeBinType: step.canDriverChangeBinType,
          canDriverChangeWasteType: step.canDriverChangeWasteType,
          mustTakePhoto: step.mustTakePhoto,
          numberofPhotosRequired: step.numberofPhotosRequired,
          mustTakeSignature: step.mustTakeSignature,

          templateStepSites: defaultLocationsArrCloned,
        }
      });
    }


    data['isActive'] = isActive;
    data['isBillable'] = isBillable;

    data['jobTemplateName'] = jobTemplateName;
    data['jobTemplateDescription'] = jobTemplateDescription;
    
    data['jobTemplateType'] = jobTemplateType;
    data['stepType'] = stepType;
    data['templateSteps'] = steps;
  }
  
  return data;
};
export const prepareData = (values: any = null, id: any = null, isCopy: boolean = false) => {
  let data: any = {};

  let form = _.cloneDeep(values);

  if(form){
    if(id){
      data['jobTemplateId'] = id;
    }

    if(isCopy){
      delete data['jobTemplateId'];
    }
    
    let jobTemplateType = (form && isNumeric(form.jobTemplateType)) ? form.jobTemplateType : JobTemplateType.Custom;
    let stepType = (form && isNumeric(form.stepType)) ? form.stepType : JobStepType.Custom;

    let templateSteps: Array<any> = [];
    if(form.templateSteps && form.templateSteps.length > 0){
      templateSteps = form.templateSteps.map((step: any, i: number) => {
        let stepName = getStepName(i, form.templateSteps);
        let stepTitle = getBinActivityName(i, step);
        let stepDescription = getStepDescription(i, step);
        let stepOrder = (i+1);

        return {
          stepName: stepName,
          stepTitle: stepTitle,
          stepDescription: stepDescription,

          emptyBinCountAfterAction: 0,
          loadedBinCountAfterAction: 0,
          stepOrder: stepOrder,
          stepType: stepType,

          locationType: step.locationType,
          hasDefaultSite: step.hasDefaultSite,
          binActivity: step.binActivity,
          defaultLocationId: step.defaultLocationId,
          
          isEditableBinNumber: step.isEditableBinNumber,
          isRequireBinNumberToStart: step.isRequireBinNumber,
          isRequireBinNumberToEnd: step.isRequireBinNumber,
          isRequireBinWeight: step.isRequireBinWeight,
          isRequireReviewWasteType: step.isRequireReviewWasteType,
          canDriverChangeBinType: step.canDriverChangeBinType,
          canDriverChangeWasteType: step.canDriverChangeWasteType,
          mustTakePhoto: step.mustTakePhoto,
          numberofPhotosRequired: step.numberofPhotosRequired,
          mustTakeSignature: step.mustTakeSignature,
        }
      });
    }

    if(templateSteps && templateSteps.length > 0){
      templateSteps.forEach((step: any, i: number) => {
        let binCount = getBinCount(i, step, templateSteps);
        step.emptyBinCountAfterAction = binCount?.emptyBinCountAfterAction;
        step.loadedBinCountAfterAction = binCount?.loadedBinCountAfterAction;
      });
    }


    data['isActive'] = form.isActive;
    data['isBillable'] = form.isBillable;

    data['jobTemplateName'] = form.jobTemplateName;
    data['jobTemplateDescription'] = form.jobTemplateDescription;

    data['jobTemplateType'] = jobTemplateType;
    data['stepType'] = stepType;
    data['templateSteps'] = templateSteps;
  }

  return data;
};

export const prepareDefaultLocations = (values: any = null, defaultLocations: Array<StepSiteStruct> = []): any => {
  let form = _.cloneDeep(values);
  let locations = _.cloneDeep(defaultLocations);
  
  if(form && form.templateSteps && form.templateSteps.length > 0){
    for(let i = 0; i < form.templateSteps.length; i++){
      form.templateSteps[i].templateStepSites = locations;
    }
  }

  return form;
}

export const getVehicleBinStatus = (currentStepIndex: number, currentStep: any, steps: Array<any>): any => {
  if(currentStep){
    if (currentStep.binActivity === BinActivityEnum.PickEmptyBin) {
      return {
        vehicleStatus: 'Loaded',
        binStatus: 'Empty',
        info: 'You can stack empty bins'
      };

    } else if (currentStep.binActivity === BinActivityEnum.PickLoadedBin) {
      return {
        vehicleStatus: 'Loaded',
        binStatus: 'Loaded',
        info: null
      };

    } else if (currentStep.binActivity === BinActivityEnum.LoadWaste) {
      return {
        vehicleStatus: 'Loaded',
        binStatus: 'Loaded',
        info: null
      };
      
    } else if (currentStep.binActivity === BinActivityEnum.WasteDisposal) {
      return {
        vehicleStatus: 'Loaded',
        binStatus: 'Empty',
        info: null
      };
      
    } else if (currentStep.binActivity === BinActivityEnum.DropEmptyBin) {
      return {
        vehicleStatus: 'Empty',
        binStatus: null,
        info: null
      };
      
    } else if (currentStep.binActivity === BinActivityEnum.DropLoadedBin) {
      return {
        vehicleStatus: 'Empty',
        binStatus: null,
        info: null
      };
      
    } else if (currentStep.binActivity === BinActivityEnum.ExchangeBin) {
      return {
        vehicleStatus: 'Loaded',
        binStatus: 'Loaded',
        info: null
      };
      
    } else if (currentStep.binActivity === BinActivityEnum.NoActivity) {
      if(currentStepIndex === 0){
        return null;

      } else {
        let prevIndex = -1;
        let prevStep = null;
        
        for(let i = (currentStepIndex-1); i >= 0; i--){
          if(steps[i] && steps[i].binActivity !== BinActivityEnum.NoActivity){
            prevIndex = i;
            prevStep = steps[i];
            break;
          }
        }

        return getVehicleBinStatus(prevIndex, prevStep, steps);
      }
      
    } else {
      return null;
    }
  } else {
    return null;
  }
}
export const disableLocations = (currentStepIndex: number, currentStep: any, steps: Array<any>): LocationBoolStruct => {
  // let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  // if(step === 0){
  //   return {
  //     CustomerSite: false,
  //     Yard: false,
  //     Landfill: true,
  //   };

  // } else {
  //   if(prevStepValue){
  //     if(prevStepValue.locationType === LocationEnum.CustomerSite){
  //       return {
  //         CustomerSite: true,
  //         Yard: false,
  //         Landfill: false,
  //       };
        
  //     } else if(prevStepValue.locationType === LocationEnum.Yard){
  //       return {
  //         CustomerSite: false,
  //         Yard: true,
  //         Landfill: false,
  //       };
        
  //     } else if(prevStepValue.locationType === LocationEnum.Landfill){
  //       return {
  //         CustomerSite: false,
  //         Yard: false,
  //         Landfill: true,
  //       };
    
  //     }
  //   }
  // }

  return {
    CustomerSite: false,
    Yard: false,
    Landfill: false,
  };
}

export const getBinActivityCurrentStep1 = (currentStepIndex: number, currentStep: any, steps: Array<any>): BinActivityBoolStruct => {
  // let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  if(currentStep){
    if(currentStep.locationType === LocationEnum.CustomerSite){
      return {
        PickEmptyBin: false,
        PickLoadedBin: false,
        LoadWaste: false,
        WasteDisposal: true,
        DropEmptyBin: true,
        DropLoadedBin: true,
        ExchangeBin: true,
        NoActivity: false,
      };
      
    } else if(currentStep.locationType === LocationEnum.Yard){
      return {
        PickEmptyBin: false,
        PickLoadedBin: false,
        LoadWaste: false,
        WasteDisposal: true,
        DropEmptyBin: true,
        DropLoadedBin: true,
        ExchangeBin: true,
        NoActivity: false,
      };
      
    } else if(currentStep.locationType === LocationEnum.Landfill){
      return {
        PickEmptyBin: true,
        PickLoadedBin: true,
        LoadWaste: true,
        WasteDisposal: true,
        DropEmptyBin: true,
        DropLoadedBin: true,
        ExchangeBin: true,
        NoActivity: false,
      };
    }
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}
export const canLoadWaste = (currentStepIndex: number, currentStep: any, steps: Array<any>): boolean => {
  let firstStep = (steps && steps.length > 0) ? steps[0] : null;
  let firstStepCanLoadWaste = (firstStep && firstStep.binActivity && firstStep.binActivity === BinActivityEnum.LoadWaste) ? true : false;

  return firstStepCanLoadWaste ? false : true;
}
export const getBinActivityPrevCustomerSite = (currentStepIndex: number, currentStep: any, steps: Array<any>): BinActivityBoolStruct => {
  let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  if (prevStep.binActivity === BinActivityEnum.PickEmptyBin) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: false,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: false,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.binActivity === BinActivityEnum.PickLoadedBin) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: false,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: false,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.binActivity === BinActivityEnum.LoadWaste) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: canLoadWaste(currentStepIndex, currentStep, steps),
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: !canLoadWaste(currentStepIndex, currentStep, steps),
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: canLoadWaste(currentStepIndex, currentStep, steps),
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: !canLoadWaste(currentStepIndex, currentStep, steps),
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
    
  } else if (prevStep.binActivity === BinActivityEnum.WasteDisposal) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: false,
      WasteDisposal: true,
      DropEmptyBin: false,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.DropEmptyBin) {
    return {
      PickEmptyBin: false,
      PickLoadedBin: false,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.DropLoadedBin) {
    return {
      PickEmptyBin: false,
      PickLoadedBin: false,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.ExchangeBin) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: false,
      ExchangeBin: true,
      NoActivity: true,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.NoActivity) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: false,
          PickLoadedBin: false,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: false,
          PickLoadedBin: false,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}
export const getBinActivityPrevYard = (currentStepIndex: number, currentStep: any, steps: Array<any>): BinActivityBoolStruct => {
  let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  if (prevStep.binActivity === BinActivityEnum.PickEmptyBin) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: false,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.binActivity === BinActivityEnum.PickLoadedBin) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.binActivity === BinActivityEnum.LoadWaste) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: canLoadWaste(currentStepIndex, currentStep, steps),
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: !canLoadWaste(currentStepIndex, currentStep, steps),
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: canLoadWaste(currentStepIndex, currentStep, steps),
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: !canLoadWaste(currentStepIndex, currentStep, steps),
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
    
  } else if (prevStep.binActivity === BinActivityEnum.WasteDisposal) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: false,
      WasteDisposal: true,
      DropEmptyBin: false,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.DropEmptyBin) {
    return {
      PickEmptyBin: false,
      PickLoadedBin: false,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.DropLoadedBin) {
    return {
      PickEmptyBin: false,
      PickLoadedBin: false,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.ExchangeBin) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: false,
      ExchangeBin: true,
      NoActivity: true,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.NoActivity) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: false,
          PickLoadedBin: false,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: false,
          PickLoadedBin: false,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}
export const getBinActivityPrevLandfill = (currentStepIndex: number, currentStep: any, steps: Array<any>): BinActivityBoolStruct => {
  let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  if (prevStep.binActivity === BinActivityEnum.PickEmptyBin) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.binActivity === BinActivityEnum.PickLoadedBin) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.binActivity === BinActivityEnum.LoadWaste) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
    
  } else if (prevStep.binActivity === BinActivityEnum.WasteDisposal) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
    
  } else if (prevStep.binActivity === BinActivityEnum.DropEmptyBin) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.DropLoadedBin) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.ExchangeBin) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.NoActivity) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}
export const disableBinActivityes = (currentStepIndex: number, currentStep: any, steps: Array<any>): BinActivityBoolStruct => {
  let prevIndex = ((currentStepIndex-1) > -1) ? currentStepIndex-1 : -1;
  let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0 && steps[prevIndex]) ? steps[prevIndex] : null : null;

  if(currentStepIndex === 0){
    return getBinActivityCurrentStep1(currentStepIndex, currentStep, steps);

  } else {
    if((prevIndex > 0) && prevStep.binActivity === BinActivityEnum.NoActivity){
      let prevPrevIndex = -1;
      let prevPrevStep = null;

      for(let i = prevIndex; i >= 0; i--){
        if(steps[i].binActivity !== BinActivityEnum.NoActivity){
          prevPrevIndex = i+1;
          prevPrevStep = steps[prevPrevIndex];
          break;
        }
      }

      if(prevPrevStep){
        if(prevPrevStep.locationType === LocationEnum.CustomerSite){
          return getBinActivityPrevCustomerSite(prevPrevIndex, prevPrevStep, steps);
  
        } else if(prevPrevStep.locationType === LocationEnum.Yard){
          return getBinActivityPrevYard(prevPrevIndex, prevPrevStep, steps);
          
        } else if(prevPrevStep.locationType === LocationEnum.Landfill){
          return getBinActivityPrevLandfill(prevPrevIndex, prevPrevStep, steps);
        }
      }
      
    } else {
      if(prevStep){
        if(prevStep.locationType === LocationEnum.CustomerSite){
          return getBinActivityPrevCustomerSite(currentStepIndex, currentStep, steps);
  
        } else if(prevStep.locationType === LocationEnum.Yard){
          return getBinActivityPrevYard(currentStepIndex, currentStep, steps);
          
        } else if(prevStep.locationType === LocationEnum.Landfill){
          return getBinActivityPrevLandfill(currentStepIndex, currentStep, steps);
        }
      }
    }
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}

export const getBinCount = (currentStepIndex: number, currentStep: any, steps: Array<any>): any => {
  let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  let emptyBinCountAfterAction = (prevStep && isNumeric(prevStep.emptyBinCountAfterAction)) ? prevStep.emptyBinCountAfterAction : 0;
	let loadedBinCountAfterAction = (prevStep && isNumeric(prevStep.loadedBinCountAfterAction)) ? prevStep.loadedBinCountAfterAction : 0;

  if(currentStep){
    if(currentStep.binActivity === BinActivityEnum.PickEmptyBin){
      emptyBinCountAfterAction = emptyBinCountAfterAction + 1;

    } else if(currentStep.binActivity === BinActivityEnum.PickLoadedBin){
      loadedBinCountAfterAction = loadedBinCountAfterAction + 1;

    } else if(currentStep.binActivity === BinActivityEnum.LoadWaste){
      if(emptyBinCountAfterAction > 0){
        emptyBinCountAfterAction = emptyBinCountAfterAction - 1;
        loadedBinCountAfterAction = loadedBinCountAfterAction + 1;
      }

    } else if(currentStep.binActivity === BinActivityEnum.WasteDisposal){
      if(loadedBinCountAfterAction > 0){
        loadedBinCountAfterAction = loadedBinCountAfterAction - 1;
        emptyBinCountAfterAction = emptyBinCountAfterAction + 1;
      }

    } else if(currentStep.binActivity === BinActivityEnum.DropEmptyBin){
      emptyBinCountAfterAction = emptyBinCountAfterAction - 1;

    } else if(currentStep.binActivity === BinActivityEnum.DropLoadedBin){
      loadedBinCountAfterAction = loadedBinCountAfterAction - 1;

    } else if(currentStep.binActivity === BinActivityEnum.ExchangeBin){
      emptyBinCountAfterAction = emptyBinCountAfterAction - 1;
      loadedBinCountAfterAction = loadedBinCountAfterAction + 1;

    } else if(currentStep.binActivity === BinActivityEnum.NoActivity){
      
    }
  }

  return {
    emptyBinCountAfterAction: emptyBinCountAfterAction,
    loadedBinCountAfterAction: loadedBinCountAfterAction,
  };
}
export const getStepName = (currentStepIndex: number, steps: Array<any>): any => {
  let stepName = '';
  
  if(currentStepIndex === 0){
    stepName = 'Start';
  } else if(currentStepIndex === (steps.length-1)){
    stepName = 'Complete';
  } else {
    stepName = 'In Progress';
  }

  return stepName;
}
export const getLocationTypeName = (currentStepIndex: number, currentStep: any): any => {
  let location: any = '';

  if(currentStep.locationType === LocationEnum.CustomerSite){
    location = 'Customer Location'
  } else if(currentStep.locationType === LocationEnum.Yard){
    location = 'Yard'
  } else if(currentStep.locationType === LocationEnum.Landfill){
    location = 'Landfill'
  }

  return location;
}
export const getBinActivityName = (currentStepIndex: number, currentStep: any): any => {
  let activity: any = '';

  if(currentStep.binActivity === BinActivityEnum.PickLoadedBin){
    activity = 'Pick Loaded Bin'
  } else if(currentStep.binActivity === BinActivityEnum.DropLoadedBin){
    activity = 'Drop Loaded Bin'
  } else if(currentStep.binActivity === BinActivityEnum.ExchangeBin){
    activity = 'Exchange Bin'
  } else if(currentStep.binActivity === BinActivityEnum.NoActivity){
    activity = 'No Activity'
  } else if(currentStep.binActivity === BinActivityEnum.WasteDisposal){
    activity = 'Waste Disposal'
  } else if(currentStep.binActivity === BinActivityEnum.LoadWaste){
    activity = 'Load Waste'
  } else if(currentStep.binActivity === BinActivityEnum.PickEmptyBin){
    activity = 'Pick Empty Bin'
  } else if(currentStep.binActivity === BinActivityEnum.DropEmptyBin){
    activity = 'Drop Empty Bin'
  }

  return activity;
}
export const getStepDescription = (currentStepIndex: number, currentStep: any): any => {
  let location: any = getLocationTypeName(currentStepIndex, currentStep);
  let activity: any = getBinActivityName(currentStepIndex, currentStep);
  
  if(activity != '' && location != ''){
    return [activity, 'at', location].join(' ');
  } else if(activity != ''){
    return activity;
  } else if(location != ''){
    return location;
  } else {
    return '';
  }
}
export const getDriverAppOptionsStepInfo = (currentStepIndex: number, currentStep: any, steps: Array<any>): DriverAppOptionsBoolStruct => {
  let backData: DriverAppOptionsBoolStruct = {
    RequiredBinID: false,
    TakePhoto: false,
    Signature: false,
    InputBinWeight: false,
    ReviewWasteType: false,
    ChangeBin: false,
    ChangeWaste: false,
    NewBinID: false,
  }

  if(currentStep){
    if(currentStep.locationType === LocationEnum.Yard){
      if(currentStep.binActivity === BinActivityEnum.PickEmptyBin){
        backData.ChangeBin = true;
        backData.ChangeWaste = true;
        backData.NewBinID = true;
        backData.RequiredBinID = true;

      } else if(currentStep.binActivity === BinActivityEnum.PickLoadedBin){
        backData.ChangeBin = true;
        backData.ChangeWaste = true;
        backData.NewBinID = true;
        backData.RequiredBinID = true;
        backData.InputBinWeight = true;

      } else if(currentStep.binActivity === BinActivityEnum.LoadWaste){
        backData.ChangeBin = true;
        backData.ChangeWaste = true;
        backData.InputBinWeight = true;

      } else if(currentStep.binActivity === BinActivityEnum.WasteDisposal){
        backData.InputBinWeight = true;
        backData.ReviewWasteType = true;
        backData.TakePhoto = true;

      } else if(currentStep.binActivity === BinActivityEnum.DropEmptyBin){
        backData.TakePhoto = true;

      } else if(currentStep.binActivity === BinActivityEnum.DropLoadedBin){
        backData.InputBinWeight = true;
        backData.ReviewWasteType = true;
        backData.TakePhoto = true;

      } else if(currentStep.binActivity === BinActivityEnum.ExchangeBin){
        backData.ChangeBin = true;
        backData.ChangeWaste = true;
        backData.NewBinID = true;
        backData.RequiredBinID = true;

      } else if(currentStep.binActivity === BinActivityEnum.NoActivity){
        backData.TakePhoto = true;
        backData.Signature = true;
      }

    } else if(currentStep.locationType === LocationEnum.CustomerSite){
      if(currentStep.binActivity === BinActivityEnum.PickEmptyBin){
        backData.ChangeBin = true;
        backData.ChangeWaste = true;
        backData.NewBinID = true;
        backData.RequiredBinID = true;
        backData.TakePhoto = true;
        backData.Signature = true;

      } else if(currentStep.binActivity === BinActivityEnum.PickLoadedBin){
        backData.ChangeBin = true;
        backData.ChangeWaste = true;
        backData.NewBinID = true;
        backData.RequiredBinID = true;
        backData.TakePhoto = true;
        backData.Signature = true;

      } else if(currentStep.binActivity === BinActivityEnum.LoadWaste){
        backData.InputBinWeight = true;
        backData.TakePhoto = true;
        backData.Signature = true;

      } else if(currentStep.binActivity === BinActivityEnum.WasteDisposal){
        backData.InputBinWeight = true;
        backData.TakePhoto = true;
        backData.Signature = true;

      } else if(currentStep.binActivity === BinActivityEnum.DropEmptyBin){
        backData.TakePhoto = true;
        backData.Signature = true;

      } else if(currentStep.binActivity === BinActivityEnum.DropLoadedBin){
        backData.TakePhoto = true;
        backData.Signature = true;

      } else if(currentStep.binActivity === BinActivityEnum.ExchangeBin){
        backData.ChangeBin = true;
        backData.ChangeWaste = true;
        backData.NewBinID = true;
        backData.RequiredBinID = true;
        backData.TakePhoto = true;
        backData.Signature = true;

      } else if(currentStep.binActivity === BinActivityEnum.NoActivity){
        backData.TakePhoto = true;
        backData.Signature = true;
      }

    } else if(currentStep.locationType === LocationEnum.Landfill){
      if(currentStep.binActivity === BinActivityEnum.WasteDisposal){
        backData.InputBinWeight = true;
        backData.TakePhoto = true;

      } else if(currentStep.binActivity === BinActivityEnum.NoActivity){
        backData.TakePhoto = true;
      }

    }
  }

  return backData;
}


export interface StepSiteStruct {
  defaultLocationId: number|null,
  isDefault: boolean,
  locationName: string,
  addressField: string,
  address: string,
  latitude: number|null,
  longitude: number|null,
};
export const initStepSite: StepSiteStruct = {
  defaultLocationId: null,
  isDefault: false,
  locationName: '',
  addressField: '',
  address: '',
  latitude: null,
  longitude: null,
};


export interface StepStruct {
  stepName: string,
  stepTitle: string,
  stepDescription: string,

  emptyBinCountAfterAction: number,
  loadedBinCountAfterAction: number,
  stepOrder: number,
  stepType: number,

  locationType: number,
  hasDefaultSite: boolean,
  binActivity: number|null,
  defaultLocationId: number|null,

  isEditableBinNumber: boolean,
  isRequireBinNumber: boolean,
  isRequireBinWeight: boolean,
  isRequireReviewWasteType: boolean,
  canDriverChangeBinType: boolean,
  canDriverChangeWasteType: boolean,
  mustTakePhoto: number|null,
  numberofPhotosRequired: number|null,
  mustTakeSignature: number|null,

  templateStepSites: Array<StepSiteStruct>,
};
export const initStep: StepStruct = {
  stepName: '',
  stepTitle: '',
  stepDescription: '',

  emptyBinCountAfterAction: 0,
  loadedBinCountAfterAction: 0,
  stepOrder: 0,
  stepType: JobStepType.Custom,

  locationType: LocationEnum.CustomerSite,
  hasDefaultSite: true,
  binActivity: null,
  defaultLocationId: null,

  isEditableBinNumber: true,
  isRequireBinNumber: true,
  isRequireBinWeight: true,
  isRequireReviewWasteType: true,
  canDriverChangeBinType: true,
  canDriverChangeWasteType: true,
  mustTakePhoto: CustomOptions.Yes,
  numberofPhotosRequired: 1,
  mustTakeSignature: CustomOptions.Yes,

  templateStepSites: [],
};


export interface initialValuesStruct {
  isActive: boolean,
  isBillable: boolean,

  jobTemplateName: string,
  jobTemplateDescription: string,

  jobTemplateType: number,
  stepType: number,

  templateSteps: Array<StepStruct>,
};
export const initialValues: initialValuesStruct = {
  isActive: true,
  isBillable: true,

  jobTemplateName: '',
  jobTemplateDescription: '',

  jobTemplateType: JobTemplateType.Custom,
  stepType: JobStepType.Custom,

  templateSteps: [initStep],
};


interface InitState {
  isLoading: boolean,
  id: any|null,
  details: any,
  tab: string,

  isLoadingCreateUpdateDelete: boolean,
  isLoadingChangeStatus: boolean,

  isLoadingDefaultLocations: boolean,
  defaultLocations: Array<any>,

  show: boolean,
  index: number|null,
}

function NewReducer() {
  const name = 'manageJobTypesSlice';


  const initialState: InitState = {
    isLoading: false,
    id: null,
    details: initialValues,
    tab: 'tab1',

    isLoadingCreateUpdateDelete: false,
    isLoadingChangeStatus: false,

    isLoadingDefaultLocations: false,
    defaultLocations: [],

    show: false,
    index: null,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTab: (state: InitState, action: PayloadAction<string>) => {
      state.tab = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, index: number|null }>) => {
      state.index = action.payload.index;
      state.show = action.payload.show;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.list = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.list = data;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startActivate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishActivate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startChangeStatus: (state: InitState) => {
      state.isLoadingChangeStatus = true;
    },
    finishChangeStatus: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingChangeStatus = false;
    },

    startReadDefaultLocation: (state: InitState) => {
      state.isLoadingDefaultLocations = true;
    },
    finishReadDefaultLocation: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];

      let defaultLocations: any = [];
      if(data && data.length > 0){
        defaultLocations = data.map((item: any, i: number) => {
          item['addressField'] = item.address;
          return item;
        });
      }

      state.defaultLocations = defaultLocations;
      state.isLoadingDefaultLocations = false;
    },

    startCreateDefaultLocation: (state: InitState) => {
      state.isLoadingDefaultLocations = true;
    },
    finishCreateDefaultLocation: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingDefaultLocations = false;
    },

    startUpdateDefaultLocation: (state: InitState) => {
      state.isLoadingDefaultLocations = true;
    },
    finishUpdateDefaultLocation: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingDefaultLocations = false;
    },

    startDeleteDefaultLocation: (state: InitState) => {
      state.isLoadingDefaultLocations = true;
    },
    finishDeleteDefaultLocation: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingDefaultLocations = false;
    },

    addDefaultLocation: (state: InitState, action: PayloadAction<StepSiteStruct>) => {
      let item = action.payload;
      item['addressField'] = item.address;

      let items = state.defaultLocations;
      let index = items.findIndex((x: StepSiteStruct) => x.defaultLocationId === item.defaultLocationId);

      if(index === -1){
        items.push(item);
      }

      state.defaultLocations = items;
    },
    editDefaultLocation: (state: InitState, action: PayloadAction<StepSiteStruct>) => {
      let item = action.payload;
      item['addressField'] = item.address;

      let items = state.defaultLocations;
      let index = items.findIndex((x: StepSiteStruct) => x.defaultLocationId === item.defaultLocationId);

      if(index > -1){
        items[index] = { ...items[index], ...item };
      }

      state.defaultLocations = items;
    },
    removeDefaultLocation: (state: InitState, action: PayloadAction<StepSiteStruct>) => {
      let item = action.payload;
      let items = state.defaultLocations;
      let index = items.findIndex((x: StepSiteStruct) => x.defaultLocationId === item.defaultLocationId);

      if(index > -1){
        items.splice(index, 1);
      }

      state.defaultLocations = items;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('JobTemplate', { params: params }).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('JobTemplate/' + id).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('JobTemplate', params).then((result: any) => {
            let data = result.data;
            
            // successAPI(data);
            success('Saved successfully', 'The data you entered has been successfully saved.');

            callback(true, data);
            dispatch(actions.finishCreate(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('JobTemplate', params).then((result: any) => {
            let data = result.data;
            
            // successAPI(data);
            success('Updated successfully', 'Your data has been successfully updated.');

            callback(true, data);
            dispatch(actions.finishUpdate(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('JobTemplate', { data: params }).then((result: any) => {
            let data = result.data;
                
            // successAPI(data);
            success('Deleted successfully', 'Job Type has been successfully deleted.');

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },

    callActivateApi: (isActive: boolean, ids: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startActivate());

        let params = {
          isActive: isActive,
          ids: ids,
        };

        await axios.put('JobTemplate/deactivate', params).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishActivate(obj));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishActivate(null));
        });
    },

    callChangeStatusApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startChangeStatus());

      await axios.put('JobTemplate/activate', params).then((result: any) => {
          let data = result.data;
          
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishChangeStatus(data));
      }).catch((error: any) => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishChangeStatus(null));
      });
    },

    callReadDefaultLocationApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startReadDefaultLocation());
  
        await axios.get('default-location', { params: params }).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishReadDefaultLocation(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishReadDefaultLocation(null));
        });
    },

    callCreateDefaultLocationApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreateDefaultLocation());
  
        await axios.post('default-location', params).then((result: any) => {
            let data = result.data;
            
            // successAPI(data);
            success('Saved successfully', 'The data you entered has been successfully saved.');

            callback(true, data);
            dispatch(actions.finishCreateDefaultLocation(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreateDefaultLocation(null));
        });
    },

    callUpdateDefaultLocationApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdateDefaultLocation());
  
        await axios.put('default-location', params).then((result: any) => {
            let data = result.data;
            
            // successAPI(data);
            success('Updated successfully', 'Your data has been successfully updated.');

            callback(true, data);
            dispatch(actions.finishUpdateDefaultLocation(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdateDefaultLocation(null));
        });
    },

    callDeleteDefaultLocationApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDeleteDefaultLocation());
  
        await axios.delete('default-location', { data: params }).then((result: any) => {
            let data = result.data;
                
            // successAPI(data);
            success('Deleted successfully', 'Job Type has been successfully deleted.');

            callback(true, data);
            dispatch(actions.finishDeleteDefaultLocation(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDeleteDefaultLocation(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();